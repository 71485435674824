import React from 'react';
import { NavLink } from 'react-router-dom';
import scss from 'components/header/NavModal.module.scss';
import Backdrop from 'components/shared/Backdrop';
import IconButton from 'components/shared/IconButton';

const NavModal = ({isShowing, hide}) => (
  <>
    <Backdrop show={isShowing} hide={hide} />
    <div className={scss.ModalWrapper}>
      <IconButton className={scss.CloseButton} onClick={hide}>X</IconButton>
      <NavLink className={scss.NavLink} activeClassName={scss.SelectedLink} to="/discover">
        find pool parties
      </NavLink><hr />
      <NavLink className={scss.NavLink} activeClassName={scss.SelectedLink} to="/create-dao">
        start new party
      </NavLink><hr />
      <NavLink className={scss.NavLink} activeClassName={scss.SelectedLink} to="/faq">
        faq
      </NavLink><hr />
      <NavLink className={scss.NavLink} activeClassName={scss.SelectedLink} to="/profile">
        my profile
      </NavLink><hr />
      <a className={scss.NavLink} target="_blank" rel="noopener noreferrer" href="https://discord.gg/VNb2EZT">
        join discord
      </a>
    </div>
  </>
);

export default NavModal;
