import React, { useContext} from 'react';
import Emoji from 'components/shared/Emoji';
import scss from 'components/header/TopNav.module.scss';

const TransactionCompleteModal = ({ message, title, closeModal }) => (
  <div className={scss.ModalContainer}>
    <button onClick={closeModal} className={scss.CloseButton}>X</button>
    <h2 className={scss.ModalTitle}>{title} <Emoji symbol='💰' label='money' /></h2>
    <p className={scss.ModalText}>{message}</p>
    <button className={scss.ModalButton} onClick={closeModal}>Cool</button>
  </div>
);

export default TransactionCompleteModal;
