import React from 'react';
import classes from 'components/shared/Backdrop.module.scss';

const Backdrop = ({show, hide}) => {
  let content = null;
  if (show) {
    content = <div className={classes.Backdrop} onClick={hide}></div>;
  }
  return content;
};

export default Backdrop;
