import React, { useState } from 'react';
import TopNav from 'components/header/TopNav';
import NavModal from 'components/header/NavModal';
import scss from 'components/header/Header.module.scss';
import Emoji from 'components/shared/Emoji';


const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const closeIfOpen = () => {
    if (isMenuOpen) setMenuOpen(false);
  }

  return (
    <>
      <header className={scss.Header} onClick={closeIfOpen}>
        <div className={scss.Disclaimer}>
          product in beta - use at your own risk  
          <Emoji className={scss.HeaderEmojiButton} symbol=" 💀" label="warning" />
        </div>
        <TopNav isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
      </header>
      {isMenuOpen ? <NavModal isShowing={isMenuOpen} hide={() => setMenuOpen(false)} /> : null}
    </>
  );
};

export default Header;
