import React, { useContext, useEffect, useState } from "react";
import scss from "../CreateDAO.module.scss";
import { FormContext } from "../CreateDAO";
import Input from "components/shared/InputFields/Input";
import * as Yup from 'yup';
import web3 from "web3";

export default function DescriptionPage() {
  const { formik, setFormIndex, setValidationSchema } = useContext(FormContext);

  const [errorMessage, setErrorMessage] = useState('');
  const [isHardMode, setIsHardMode] = useState(false);

  const isWalletAddress = address => web3.utils.isAddress(address.trim());
  const formSchema = isCustomCurrency => {
    let schemaShape;
    if (!isCustomCurrency) {
      schemaShape = {
        title: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
        description: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .required('Required'),
        goal: Yup.number()
        .required('Required'),
        currency: Yup.string()
        .required('Required'),
        minDeposit: Yup.number()
        .required('Required'),
        votingPeriod: Yup.string().matches(/^\d*$/, "Voting Period must be a number"),
        gracePeriod: Yup.string().matches(/^\d*$/, "Grace Period must be a number"),
        dilutionBound: Yup.string().matches(/^\d*$/, "Dilution Bound must be a number"),
        proposalDeposit: Yup.string().matches(/^\d*$/, "Proposal Deposit must be a number")
      }
    }
    else {
      schemaShape = {
        title: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
        description: Yup.string()
        .min(2, 'Too Short!')
        .max(500, 'Too Long!')
        .required('Required'),
        goal: Yup.number()
        .required('Required'),
        currency: Yup.string()
        .required('Required'),
        customToken: Yup.string()
        .required('Required')
        .test(
          'isEthAddress',
          'Invalid wallet address',
          function(value) {
            if (!value) return true;
            return isWalletAddress(value)
          }
        ),
        minDeposit: Yup.number()
        .required('Required'),
        votingPeriod: Yup.string().matches(/^\d*$/, "Voting Period must be a number"),
        gracePeriod: Yup.string().matches(/^\d*$/, "Grace Period must be a number"),
        dilutionBound: Yup.string().matches(/^\d*$/, "Dilution Bound must be a number"),
        proposalDeposit: Yup.string().matches(/^\d*$/, "Proposal Deposit must be a number")
      }
    }
    return Yup.object().shape(schemaShape);
  }

  useEffect(() => {
    setValidationSchema(formSchema(false));
  }, []);

  const clearHardValues = () => {
    formik.setFieldValue('votingPeriod', '');
    formik.setFieldValue('gracePeriod', '');
    formik.setFieldValue('dilutionBound', '');
    formik.setFieldValue('proposalDeposit', '');
  }

  const isCustomCurrency = formik.values.currency === 'custom';
  useEffect(() => {
    setValidationSchema(formSchema(isCustomCurrency));
  }, [isCustomCurrency])

  useEffect(() => {
    clearHardValues();
  }, [isHardMode]);

  // TODO when adding aave tokens, add to this list
  const currencyOptions = [
    {value: '', displayValue: ''},
    {value: "DAI", displayValue: "DAI"},
    {value: "WETH", displayValue: "WETH"},
    {value: "USDC", displayValue: "USDC"},
    {value: "wBTC", displayValue: "wBTC"},
    {value: "SUSD", displayValue: "SUSD"},
    {value: "TUSD", displayValue: "TUSD"},
    {value: "USDT", displayValue: "USDT"},
    {value: "PAN", displayValue: "PAN"},
    {value: "custom", displayValue: "Custom"}
  ];

  return (
    <React.Fragment>
      <h3 className={scss.PageSubHeader}>LVL 2. Enter Details</h3>

      <div className={scss.CreateInputFields}>
        <Input
          type={"text"}
          handleChange={formik.handleChange}
          name={"title"}
          labelText={"Pick a pool name"}
          placeholder={"Call your party something cool"}
          value={formik.values.title}
        />
        <Input
          type={"textarea"}
          handleChange={formik.handleChange}
          name={"description"}
          placeholder={"Why are we pooling this $$$"}
          labelText={"Tell peeps about the goal"}
          value={formik.values.description}
        />
        <Input
          type={"number"}
          handleChange={formik.handleChange}
          name={"goal"}
          placeholder={"Don't set it too high!"}
          labelText={"Set the saving goal"}
          value={formik.values.goal}
        />
        <div className={scss.InputRow}>
          <Input
            type={"number"}
            handleChange={formik.handleChange}
            name={"minDeposit"}
            labelText={"Minimum savings deposit"}
            placeholder={"Recommend at least 1 for stable coins and 0.000001 for others"}
            value={formik.values.minDeposit}
            additionalClasses={scss.HalfWidth}
          />
          <Input
            type={"select"}
            options={currencyOptions}
            handleChange={formik.handleChange}
            name={"currency"}
            labelText={"Pick a currency"}
            value={formik.values.currency}
            additionalClasses={scss.HalfWidth}
          />
        </div>
        {formik.values.currency === 'custom' ?
          <Input
            type={"text"}
            handleChange={formik.handleChange}
            name={"customToken"}
            labelText={"Enter custom token address"}
            placeholder={"E.g. 0x6b175474e89094c44da98b954eedeac495271d0f"}
            value={formik.values.customToken}
          />
        : null}

        <button type="button"
          onClick={() => setIsHardMode(true)}
          className={isHardMode ? scss.HiddenFields : scss.CreateTextButton}>
          hard mode
        </button>

        <div className={isHardMode ? '' : scss.HiddenFields}>
          <div className={scss.InputRow}>
            <Input
              type={"number"}
              handleChange={formik.handleChange}
              name={"votingPeriod"}
              labelText={"Voting Period (hours)"}
              placeholder={"Deafult: 72 hours"}
              value={formik.values.votingPeriod}
              additionalClasses={scss.HalfWidth}
            />
            <Input
              type={"number"}
              handleChange={formik.handleChange}
              name={"gracePeriod"}
              labelText={"Grace Period (hours)"}
              placeholder={"Default: 36 hours"}
              value={formik.values.gracePeriod}
              additionalClasses={scss.HalfWidth}
            />
          </div>
          <Input
            type={"number"}
            handleChange={formik.handleChange}
            name={"dilutionBound"}
            labelText={"Dilution Bound (%)"}
            placeholder={"% that can quit before tanking proposals"}
            value={formik.values.dilutionBound}
          />
          <Input
            type={"number"}
            handleChange={formik.handleChange}
            name={"proposalDeposit"}
            labelText={"Proposal deposit"}
            placeholder={"Req for making proposals"}
            value={formik.values.proposalDeposit}
          />
          <button type="button"
            onClick={() => setIsHardMode(false)}
            className={scss.CreateTextButton}>
            easy mode
          </button>
        </div>

        <div className={scss.ErrorMessage}>{errorMessage}</div>

        <div className={scss.ButtonRow}>
          <button
            type='button'
            className={scss.SecondaryButton}
            onClick={() => setFormIndex(0)}>
            Back
          </button>
          <button
            type='button'
            className={scss.MainButton}
            onClick={() => {
              formSchema(isCustomCurrency)
              .validate(formik.values)
              .then(value => {
                setFormIndex(2);
                })
              .catch(e => {
                if (e.path === 'customToken' && (e.type === 'typeError' || e.type === 'isEthAddress'))
                  setErrorMessage(e.message);
                else
                  setErrorMessage("Fill out all fields to continue");
              });
            }}>
            More Tokens
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
