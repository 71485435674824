import React from 'react'
import './Legal.scss';

const Privacy = () => {
  window.scrollTo(0, 0);
return (
  <div className="community-board-body">
    <div>
      <h4 className="PageTitle"> Pool-Party Privacy Policy</h4>
      <p className="Update">Last Revised on: March 19, 2020</p>
    </div>
    <div className="community-board-explanation-text">
      <p>This privacy policy (“Policy”) describes how Peeps Democracy, Inc. (“Peeps,” “Company”, “we”, “our”, or “us”) collects, uses, shares, and stores personal information of users of our website, www.peepsdao.com and its subdomains (the “Site”). This Policy applies to the Site, applications, products and services (collectively, “Services”) on or in which it is posted, linked, or referenced. </p>
      <p>By using the Services, you accept the terms of this Policy and our Terms of Use (“Terms”) and consent to our collection, use, disclosure, and retention of your information as described in this Policy.  If you have not done so already, please also review our Terms. The Terms and contain provisions that limit our liability to you and require you to resolve any dispute with us on an individual basis and not as part of any class or representative action. IF YOU DO NOT AGREE WITH ANY PART OF THIS PRIVACY POLICY OR OUR TERMS, THEN PLEASE DO NOT USE ANY OF THE SERVICES.</p>
      <p>Please note that this Policy does not apply to information collected through third-party websites or services that you may access through the Services or that you submit to us through email, text message or other electronic message or offline.</p>
      <p>If you are visiting this site from the European Union (EU), see our Notice to EU Data Subjects below for our legal bases for processing and transfer of your data. </p>

      <p><strong>WHAT WE COLLECT</strong></p>
      <p>We get information about you in a range of ways.</p>
      <p className="italics">Information You Give Us.</p>
      <p >Information we may collect from you includes:</p>
      <ol>
        <li>Identity information, such as your first name, last name, employer and occupation, username or similar identifier;</li>
        <li>Contact information, such as your postal address, email address and Ethereum address;</li>
        <li>Profile information, such as your username and password, public Ethereum address, interests, preferences, feedback and survey responses;</li>
        <li>Feedback and correspondence, such as information you provide in your responses to surveys, when you participate in market research activities, report a problem with Service, receive customer support or otherwise correspond with us;</li>
        <li>Financial information, such as your Ethereum wallet address;</li>
        <li>Transaction information, such details about purchases, donations and contributions you make through the Service and billing details;</li>
        <li>Usage information, such as information about how you use the Service and interact with us or the Site;</li>
        <li>Marketing information, such as your preferences for receiving marketing communications and details about how you engage with them; and</li>
        <li>Information related to DAOs, Communities, Movements, votes, proposals, comments and other activities you pursue or facilitate via the Services.</li>
      </ol>
      <p className="italics">Information We Get From Others.</p>
      <p>We may get information about you from other third party sources and we may add this to information we get from your use of the Services. Such information may include:
        <ul>
          <li>
            Social Media Widgets: Our Site may include social media features, such as the Facebook “like” button and widgets, such as the “share this” button. These features may collect your personal information and track your use of the Site. These social media features are either hosted by a third party or hosted directly on our Site. Your interactions with these features are governed by the privacy policy of the company providing such functionality.
          </li>
          <li>
            Contribution Information: Our Site uses smart contracts to process all donations and payments to the applicants of DAO-approved proposals. With respect to any DAO that is an organized political committee or nonprofit under U.S. federal law and is required to file reports with the Federal Election Commission or the IRS, we are required to collect certain information from our users in order for the smart contracts to process their donations or proposals and provide end those political committees or nonprofits with information required by federal law.
          </li>
        </ul>
      </p>
      <p className="italics">Information Automatically Collected.</p>
      <p>We may automatically record certain information about how you use our Site (we refer to this information as “Log Data“). Log Data may include information such as a user’s Internet Protocol (IP) address, device and browser type, operating system, the pages or features of our Site to which a user browsed and the time spent on those pages or features, the frequency with which the Site is used by a user, search terms, the links on our Site that a user clicked on or used, and other statistics. We use this information to administer the Service and we analyze (and may engage third parties to analyze) this information to improve and enhance the Service by expanding its features and functionality and tailoring it to our users’ needs and preferences.</p>
      <p>We may use cookies or similar technologies to analyze trends, administer the website, track users’ movements around the website, and to gather demographic information about our user base as a whole. Users can control the use of cookies at the individual browser level.</p>
      <p>We also use Google Analytics to help us offer you an optimized user experience.  You can find more information about Google Analytics’ use of your personal data here: https://policies.google.com/privacy?hl=en#infocollect </p>
      <p className="italics">Information we will never collect.</p>
      <p>We will never ask you to share any private keys or wallet seeds that you may use to interact with the Site. Never trust anyone or any site that asks you to enter your private keys or wallet seed.</p>
      <p><strong>USE OF PERSONAL INFORMATION</strong></p>
      <p className="italics">To provide our service</p>
      <p>We will use your personal information in the following ways:</p>
      <ol>
        <li>To enable you to access and use the Services</li>
        <li>To provide and deliver recommendations and services that you may request or opt-in to recieve.</li>
        <li>To process and complete transactions, and send you related information, including donations confirmations or confirmations that proposals have been submitted for a vote or processed.</li>
        <li>To send information, including confirmations, technical notices, vote notices, updates, security alerts, and support and administrative messages.</li>
      </ol>
      <p className="italics">To comply with law</p>
      <p>We use your personal information as we believe necessary or appropriate to comply with applicable laws (including anti-money laundering (AML) laws, know-your-customer (KYC) requirements and federal campaign finance compliance regulations), lawful requests and legal process, such as to respond to subpoenas or requests from U.S. government authorities.</p>
      <p className="italics">To communicate with you</p>
      <p>We use your personal information to communicate about opportunities to participate in the DAOs, Movements or Communities you join, upcoming events, open votes, or funds allocations and other news about products and services offered by us and our selected partners.</p>
      <p className="italics">To optimize our platform</p>
      <p>In order to optimize your user experience, we may use your personal information to operate, maintain, and improve our Services. We may also use your information to respond to your comments and questions regarding the Services, and to provide you and other users with general customer service.</p>
      <p className="italics">With your consent</p>
      <p>We may use or share your personal information with your consent, such as when you consent to let us post your testimonials or endorsements on our Site, you instruct us to take a specific action with respect to your personal information, or you opt into third party marketing communications.</p>
      <p className="italics">For compliance, fraud prevention, and safety</p>
      <p>We may use your personal information to protect, investigate, and deter against fraudulent, harmful, unauthorized, or illegal activity.</p>
      <p><strong>SHARING OF PERSONAL INFORMATION</strong></p>
      <p>We do not share or sell the personal information that you provide us with other organizations without your express consent, except as described in this Privacy Policy. We also make best efforts not to share your personal information across the DAOs on our platform. We disclose personal information to third parties under the following circumstances:</p>
      <ol>
        <li>Affiliates. We may disclose your personal information to our subsidiaries and corporate affiliates for purposes consistent with this Privacy Policy.</li>
        <li>Business Transfers. We may share personal information when we do a business deal, or negotiate a business deal, involving the sale or transfer of all or a part of our business or assets. These deals can include any merger, financing, acquisition, or bankruptcy transaction or proceeding.</li>
        <li>Compliance with Laws and Law Enforcement; Protection and Safety. We may share personal information for legal, protection, and safety purposes. We may share information to comply with laws, including KYC and AML requirements and federal campaign finance laws and regulations. Any contributions you make using the Services to a federally registered political action committee or candidate must be reported to the Federal Election Commission (along with your name, address and employer and occupation) by the political committee that receives your contributions.
        We may share information to respond to lawful requests and legal processes. We may share information to protect the rights and property of the Company, our agents, customers, and others. This includes enforcing our agreements, policies, and terms of use. We may share information in an emergency. This includes protecting the safety of our employees and agents, our customers, or any person.</li>
        <li>Professional Advisors and Service Providers. We may share information with those who need it to do work for us. These recipients may include third party companies, such as MetaMask, and individuals to administer and provide the Service on our behalf (such as bill and credit card payment processing, customer support, hosting, email delivery and database management services), as well as lawyers, bankers, auditors, and insurers.</li>
        <li> Community Organizations & DAO Members. We may share certain information, such as your Identity, Contact and other information submitted to a DAO with those members and creators of DAOs on the Site that you join. We will not share any information with an organization hosting a DAO or Community on the Site unless you have elected to join such DAO or Community or otherwise consent to us sharing this information.</li>
        <li>Other Users. Any information included in your public profile on a DAO on the Site, which may include your username and public Ethereum address, shares, will be made available to any User of the Site who visits the membership tab on a specific DAO.</li>
        <li>Other. You may permit us to share your personal information with other companies or entities of your choosing. Those uses will be subject to the privacy policies of the recipient entity or entities. We may also share aggregated and/or anonymized data with others for their own uses.</li>
      </ol>
      <p><strong>HOW INFORMATION IS SECURED</strong></p>
      <p>We retain information we collect as long as it is necessary and relevant to fulfill the purposes outlined in this privacy policy. In addition, we retain personal information to comply with applicable law where required, prevent fraud, resolve disputes, troubleshoot problems, assist with any investigation by a U.S. law enforcement agency, enforce our Terms of Use, and other actions permitted by law. To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which we process your personal information and whether we can achieve those purposes through other means, and the applicable legal requirements.</p>
      <p>In some circumstances we may anonymize your personal information (so that it can no longer be associated with you) in which case we may use this information indefinitely without further notice to you.</p>
      <p>We employ industry standard security measures designed to protect the security of all information submitted through the Services. However, the security of information transmitted through the internet can never be guaranteed. We are not responsible for any interception or interruption of any communications through the internet or for changes to or losses of data. Users of the Services are responsible for maintaining the security of any password, biometrics, user ID or other form of authentication involved in obtaining access to password protected or secure areas of any of our digital services. In order to protect you and your data, we may suspend your use of any of the Services, without notice, pending an investigation, if any breach of security is suspected.</p>
      <p><strong>INFORMATION CHOICES AND CHANGES</strong></p>
      <p className="italics">Accessing, Updating, Correcting, and Deleting your Information</p>
      <p>You may access information that you have voluntarily provided through your account on the Services, and to review, correct, or delete it by sending a request to legal@peepsdao.com. You can request to change contact choices, opt-out of our sharing with others, and update your personal information and preferences. Note that any information stored on the blockchain, such as your public Ethereum address and your record of transactions with a DAO are recorded on the public Ethereum mainnet (or a public Ethereum testnet) in perpetuity.</p>
      <p className="italics">Tracking Technologies Generally</p>
      <p>Regular cookies may generally be disabled or removed by tools available as part of most commercial browsers, and in some instances blocked in the future by selecting certain settings. For more information, please see the section entitled “Cookies Policy” below.</p>
      <p className="italics">Google Analytics</p>
      <p>You may exercise choices regarding the use of cookies from Google Analytics by going to https://tools.google.com/dlpage/gaoptout and downloading the Google Analytics Opt-out Browser Add-on.</p>
      <p><strong>CONTACT INFORMATION.</strong></p>
      <p>We welcome your comments or questions about this Policy, and you may contact us at: legal@peepsdao.com.</p>
      <p><strong>CHANGES TO THIS PRIVACY POLICY.</strong></p>
      <p>We may change this privacy policy at any time. We encourage you to periodically review this page for the latest information on our privacy practices. If we make any changes, we will change the Last Updated date above.
        Any modifications to this Privacy Policy will be effective upon our posting of the new terms and/or upon implementation of the changes to the Site (or as otherwise indicated at the time of posting). In all cases, your continued use of the Site or Services after the posting of any modified Privacy Policy indicates your acceptance of the terms of the modified Privacy Policy.</p>
      <p><strong>ELIGIBILITY.</strong></p>
      <p>If you are under the age of majority in your jurisdiction of residence, you may use the Services only with the consent of or under the supervision of your parent or legal guardian. Consistent with the requirements of the Children's Online Privacy Protection Act (COPPA), if we learn that we have received any information directly from a child under age 13 without first receiving his or her parent's verified consent, we will use that information only to respond directly to that child (or his or her parent or legal guardian) to inform the child that he or she cannot use the Site and subsequently we will delete that information.</p>
      <p><strong>NOTICE TO CALIFORNIA RESIDENTS</strong></p>
      <p>Under California Civil Code Section 1789.3, California users are entitled to the following consumer rights notice: California residents may reach the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by mail at 1625 North Market Blvd., Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210.</p>

      <p><strong>NOTICE TO EU DATA SUBJECTS</strong></p>
      <p className="italics">Personal Information</p>
      <p>With respect to EU data subjects, “personal information,” as used in this Privacy Policy, is equivalent to
        “personal data” as defined in the European Union General Data Protection Regulation (GDPR).</p>
      <p className="italics">Sensitive Data</p>
      <p>Some of the information you provide us may constitute sensitive data as defined in the GDPR (also referred to as special categories of personal data), including identification of your race or ethnicity on government-issued identification documents.</p>

      <p><strong>Legal Bases for Processing</strong></p>
      <p>We only use your personal information as permitted by law. We are required to inform you of the legal bases of our processing of your personal information, which are described in the table below. If you have questions about the legal bases under which we process your personal information, contact us at legal@peepsdao.com.</p>
      <table className="table">
        <thead>
          <tr>
            <th>Processing Purpose</th>
            <th>Legal Basis</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p>To communicate with you</p>
              <p>To optimize our platform</p>
              <p>For compliance, fraud prevention, and safety</p>
              <p>To provide our service</p>
            </td>
            <td>To provide our services these processing activities constitute our legitimate interests. We make sure we consider and balance any potential impacts on you (both positive and negative) and your rights before we process your personal information for our legitimate interests. We do not use your personal information for activities where our interests are overridden by any adverse impact on you (unless we have your consent or are otherwise required or permitted to by law).</td>
          </tr>
          <tr>
            <td>To comply with law</td>
            <td>We use your personal information to comply with applicable laws and our legal obligations, including anti-money laundering (AML) laws and know-your-customer (KYC) requirements and campaign finance laws and regulations.</td>
          </tr>
          <tr>
            <td>With your consent</td>
            <td>Where our use of your personal information is based upon your consent, you have the right to withdraw it anytime in the manner indicated in the Service or by contacting us at legal@peepsdao.com.</td>
          </tr>
        </tbody>
      </table>

      <p className="italics">Use for New Purposes</p>
      <p>We may use your personal information for reasons not described in this Privacy Policy, where we are permitted by law to do so and where the reason is compatible with the purpose for which we collected it. If we need to use your personal information for an unrelated purpose, we will notify you and explain the applicable legal basis for that use. If we have relied upon your consent for a particular use of your personal information, we will seek your consent for any unrelated purpose.</p>

      <p className="italics">Your Rights</p>
      <p>Under the GDPR or other applicable privacy laws, you may have certain rights regarding your personal information. You may ask us to take the following actions in relation to your personal information that we hold:</p>
      <ol>
        <li>Opt-out. Stop sending you direct marketing communications which you have previously consented to receive. We may continue to send you Service-related and other non-marketing communications.</li>
        <li>Access. Provide you with information about our processing of your personal information and give you access to your personal information.</li>
        <li>Correct. Update or correct inaccuracies in your personal information.</li>
        <li>Delete. Delete your personal information.</li>
        <li>Transfer. Transfer a machine-readable copy of your personal information to you or a third party of your choice.</li>
        <li>Restrict. Restrict the processing of your personal information.</li>
        <li>Object. Object to our reliance on our legitimate interests as the basis of our processing of your personal information that impacts your rights.
        You can submit these requests by email to legal@peepsdao.com. We may request specific information from you to help us confirm your identity and process your request. Applicable law may require or permit us to decline your request. If we decline your request, we will tell you why, subject to legal restrictions. If you would like to submit a complaint about our use of your personal information or response to your requests regarding your personal information, you may contact us at legal@peepsdao.com or submit a complaint to the data protection regulator in your jurisdiction. You can find your data protection regulator here.</li>
    </ol>
      <p className="italics">Cross-Border Data Transfer</p>
      <p>Please be aware that your personal data will be transferred to, processed, and stored in the United States. Data protection laws in the U.S. may be different from those in your country of residence. You consent to the transfer of your information, including personal information, to the U.S. as set forth in this Privacy Policy by visiting our site or using our service.
        Whenever we transfer your personal information out of the EEA to the U.S. or countries not deemed by the European Commission to provide an adequate level of personal information protection, the transfer will be based on a data transfer mechanism recognized by the European Commission as providing adequate protection for personal information.
        Please contact us if you want further information on the specific mechanism used by us when transferring your personal information out of the EEA.</p>
      <br />
      <p><strong>COOKIES POLICY</strong></p>
      <p>We understand that your privacy is important to you and are committed to being transparent about the technologies we use. In the spirit of transparency, this policy provides detailed information about how and when we use cookies on our Site.</p>
      <p className="italics">Do we use Cookies?</p>
      <p>Yes. We and our marketing partners, affiliates, and analytics or service providers use cookies, web beacons, or pixels and other technologies to ensure everyone who uses the Site has the best possible experience.</p>
      <p className="italics">What is a Cookie?</p>
      <p>A cookie (“Cookie”) is a small text file that is placed on your hard drive by a web page server. Cookies contain information that can later be read by a web server in the domain that issued the cookie to you. Some of the cookies will only be used if you use certain features or select certain preferences, and some cookies will always be used. You can find out more about each cookie by viewing our current cookie list below. We update this list periodically, so there may be additional cookies that are not yet listed. Web beacons, tags and scripts may be used in the Site or in emails to help us to deliver cookies, count visits, understand usage and movement effectiveness and determine whether an email has been opened and acted upon. We may receive reports based on the use of these technologies by our service/analytics providers on an individual and aggregated basis.</p>
      <p className="italics">Why do we use Cookies?</p>
      <p>We generally use Cookies for the following purposes:</p>
      <ol>
        <li>To recognize new or past customers.</li>
        <li>To store your password if you are registered and save a password on our Site.</li>
        <li>To improve our Site and to better understand your visits on our platforms and Site.</li>
        <li>To integrate with third party social media websites.</li>
        <li>To serve you with interest-based or targeted advertising.</li>
        <li>To observe your behaviors and browsing activities over time across multiple websites or other platforms.</li>
        <li>To better understand the interests of our customers and our website visitors.</li>
      </ol>
      <p>Some Cookies are necessary for certain uses of the Site, and without such Cookies, we would not be able to provide many services that you need to properly use the Site. These Cookies, for example, allow us to operate our Site so you may access it as you have requested and let us recognize that you have created an account and have logged into that account to access Site content. They also include Cookies that enable us to remember your previous actions within the same browsing session and secure our Sites.</p>
      <p>We also use functional Cookies and Cookies from third parties for analysis and marketing purposes.  Functional Cookies enable certain parts of the site to work properly and your user preferences to remain known, and you to login using social network user credentials.  Analysis Cookies, among other things, collect information on how visitors use our Site, the content and products that users view most frequently, and the effectiveness of our third party advertising. Advertising Cookies assist in delivering ads to relevant audiences and having our ads appear at the top of search results. Cookies are either “session” Cookies which are deleted when you end your browser session, or “persistent,” which remain until their deletion by you (discussed below) or the party who served the cookie.  Full details on all of the Cookies used on the Site are available at our Cookie Disclosure table below.</p>
      <p className="italics">How to disable Cookies.</p>
      <p>You can generally activate or later deactivate the use of cookies through a functionality built into your web browser. To learn more about how to control cookie settings through your browser:</p>
      <ol>
        <li>Click <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">here</a> to learn more about the “Private Browsing” setting and managing cookie settings in Firefox;</li>
        <li>Click <a href="https://support.google.com/chrome/answer/95647?hl=en">here</a> to learn more about “Incognito” and managing cookie settings in Chrome;</li>
        <li>Click <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">here</a> to learn more about “InPrivate” and managing cookie settings in Internet Explorer; or</li>
        <li>Click <a href="https://support.apple.com/kb/ph21411?locale=en_US">here</a> to learn more about “Private Browsing” and managing cookie settings in Safari.</li>
      </ol>
      <p>If you want to learn more about cookies, or how to control, disable or delete them, please visit http://www.aboutcookies.org for detailed guidance. In addition, certain third party advertising networks, including Google, permit users to opt out of or customize preferences associated with your internet browsing. To learn more about this feature from Google, click here.
      To control flash cookies, which we may use on our Site from time to time, you can go to this link because Flash cookies cannot be controlled through your browser settings. Please note that if you decline the use of Cookies, some functions of the website may be unavailable and we will not be able to present personally tailored content and advertisements to you.
      We may link the information collected by Cookies with other information we collect from you pursuant to this Privacy Policy and use the combined information as set forth herein.  Similarly, the third parties who serve cookies on our Site may link your name or email address to other information they collect, which may include past purchases made offline or online, or your online usage information. If you are located in the European Economic Area, you have certain rights that are described above under the header “Notice to EU Data Subjects”, including the right to inspect and correct or delete the data that we have about you.</p>
      <p className="italics">Cookies Disclosure</p>
      <table className="table">
        <tr>
          <th>Name of Cookie/Identifier</th>
          <th>What does the cookie generally do (e.g., website function and administration, analytics, marketing)?</th>
          <th>Is it a 1st or 3rd party cookie and what is the name of the party providing it?</th>
          <th>What type of cookie is it (persistent or session)?</th>
          <th>What is the duration of the cookie on the website (if not cleared by the user)?</th>
        </tr>
        <tbody>
          <tr>
            <td>Google Analytics</td>
            <td>Analytics</td>
            <td>3rd - Google</td>
            <td>Persistent</td>
            <td> 2 years</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  );
}

export default Privacy;
