import React, { useEffect, useContext, useState } from 'react';
import { UserContext } from "contexts/UserStore";
import TokenService from "../../../utils/IdleTokenService";
import McDaoService from 'utils/McDaoService';
import IdleTokenService from 'utils/IdleTokenService';
import {fromBase} from "../../../utils/Helpers";

import CardNav from 'views/movement/components/CardNav';
import scss from 'views/movement/components/Movement.module.scss';


const Pool = ({context}) => {
  const {
    dao,
    fetchGraphData,
    fetchMembers,
    setProcessingBackgroundState,
    setModalMessage,
    setModalTitle
  } = useContext(context);
  const [member, setMemberData] = useState({});
  const [tokenPrice, setTokenPrice] = useState({});
  const [iTokenValue, setiTokenValue] = useState({});
  const [iSavings, setiSavings] = useState({});
  const [earnings, setEarnings] = useState({});
  const [apr, setApr] = useState({});
  const [checkedTokenPrice, setCheckedTokenPrice] = useState(false);
  const [checkedEarnings, setCheckedEarnings] = useState(false);
  const user = useContext(UserContext);
  const [checkedIsMember, setCheckedIsMember] = useState(false);
  const [idleTokenService, setIdleTokenService] = useState();
  const [mcDao, setMcDao] = useState();
  const [tokensList, setTokensList] = useState();

  useEffect(() => {
    if (dao && dao.idleToken && dao.idleToken.tokenAddress) { // prevents crash when dao not loaded
      setIdleTokenService(new TokenService(dao.idleToken.tokenAddress));

      if (dao && dao.contract_address && user.id) {
        setMcDao(new McDaoService(dao.contract_address, dao.idleToken.tokenAddress, user.id));
      }
    }

  }, [dao, user]);

  useEffect(() => {
    if (user && user.id && dao && dao.members && !checkedIsMember) { // prevents crash when user not logged in or dao not loaded
      updateMemberInfo();
      setCheckedIsMember(true);
    }
  });

  const updateMemberInfo = () => {
    setMemberData({
      user,
      ...dao.members.find(
        item =>
          item.memberAddress.toLowerCase() ===
          user.id.toLowerCase()
      )
    });
  }

  useEffect(() => {
    if (member && mcDao) {
      getIdlePrice();
      checkGuildBalance();
      getiSavings();
      getApr();
      getEarnings();
    }
    setCheckedTokenPrice(true);
  });

  const getIdlePrice = async () => {
    const token = new TokenService(dao.idleToken.tokenAddress)
    const idlePrice = await token.getIdlePrice()
    if(idlePrice){
      setTokenPrice(idlePrice)
    }
  }

  const getApr = async () => {
    const token = new IdleTokenService(dao.idleToken.tokenAddress)
    const Apr = await token.getAvgAPR()
    if(Apr){
      setApr(Apr)
    }
  }

  const checkGuildBalance = async () => {
    const mcDao = new McDaoService(dao.contract_address, dao.depositToken.tokenAddress, member);
    const guildAddr = await mcDao.getGuild();
    const guildITokenBalance = await mcDao.getUserTokenBalance(guildAddr, dao.idleToken.tokenAddress)
    if (dao.version == "2xNOFIParty" || dao.version == "2xWETHParty") {
      const guild = await mcDao.getGuild();
      const iTokenValue = await mcDao.getUserTokenBalance(guild, dao.depositToken.tokenAddress);
        if(iTokenValue) {
        setiTokenValue(iTokenValue);
      }
    } else {
      const iTokenValue = await mcDao.getIdleValue(guildITokenBalance);
      if(iTokenValue) {
        setiTokenValue(iTokenValue);
      }
    }
  }

  const getiSavings = async () => {
    const mcDao = new McDaoService(dao.contract_address, dao.depositToken.tokenAddress, UserContext.id);
    if (member.iTB > 0) {
      if (dao.version == "2xNOFIParty" || dao.version == "2xWETHParty"){ 
        const iSavings = member.iVal;
        if(iSavings) {
        setiSavings(iSavings);
        }
      } else {
        console.log("dao version", dao.version)
        const iSavings = await mcDao.getIdleValue(member.iTB);
        console.log("member iTB", iSavings)
        if(iSavings) {
          setiSavings(iSavings);
        }
      }
    }
  }

  const getEarnings = async() => {
    const mcDao = new McDaoService(dao.contract_address, dao.depositToken.tokenAddress, UserContext.id);
    if (iSavings && !checkedEarnings) {
      if (dao.version == "2xNOFIParty" || dao.version == "2xWETHParty"){ 
        console.log("dao version", dao.version)
        const guild = await mcDao.getGuild();
        const totalEarningsTokens = await mcDao.getUserTokenBalance(guild, dao.depositToken.tokenAddress);
        const totalShares = dao.totalShares; 
        const totalLoot = dao.totalLoot; 
        const totalSharesAndLoot = totalShares + totalLoot;

        const myShares = member.shares;
        const myLoot = member.loot;
        const mySharesAndLoot = myShares + myLoot;
  
        const fairShare = totalEarningsTokens * (mySharesAndLoot / totalSharesAndLoot);
        const memberBasis = (Math.abs(dao.totalDeposits) * mySharesAndLoot / totalSharesAndLoot) + parseInt(member.iTW);
        const earnings = Math.floor(fairShare - memberBasis);
        const showEarnings = earnings > 0 ? earnings : 0;
        setEarnings(showEarnings)
      }
       else {
        const iSavings = await mcDao.getIdleValue(member.iTB);
        console.log("member savings", iSavings)
        const membBal = member.iVal;
        const earnings = iSavings - membBal;
        setEarnings(earnings);
        console.log("earnings", earnings)
          
      }
       setCheckedEarnings(true) 
    }

  }

  // Math for info calcs
  const Apr = apr && !isNaN(apr) ? Number(apr / 10**18).toFixed(3): 0;
  const memberStake = parseInt(member.shares) + parseInt(member.loot);
  const daoSharesLoot = parseInt(dao.totalShares) + parseInt(dao.totalLoot);
  // Current Value
  const memberSavings = iSavings && !isNaN(iSavings) ? iSavings : 0;
  // Historical Price
  const poolPercent = (memberStake / daoSharesLoot) * 100;

  const handleWithdrawal = async () => {
    const userEarnings = earnings * 0.99;

    setProcessingBackgroundState('awaiting_withdraw_balance');
    const promise = mcDao.withdrawEarnings(member.memberAddress, userEarnings.toString());
    promise.then(resp => {
      if (resp.events) {
        setProcessingBackgroundState('modal_open');
        setModalMessage(`Your tokens from the "${dao.name}" party will now be in your wallet`);
        setModalTitle(`Get that skrilla`);
        fetchGraphData();
        fetchMembers();
      }
      else {
        setProcessingBackgroundState('');
      }
    }).catch(e => {
      console.error(e)
      setProcessingBackgroundState('');
    });
  }

  return (
    <section className={scss.DAOCard}>
      <CardNav context={context} selected='pool' />

      <div className={scss.PoolSnapshot}>
        <h2 className={scss.SnapshotHeader}>Pool Info</h2>
        <div className={scss.SnapshotStat}>
          {Number(iTokenValue) && dao.depositToken ? Number(iTokenValue / 10**dao.depositToken.decimals).toFixed(4) : '...'}
          <span className={scss.SnapshotLabel}>Savings Pooled</span>
        </div>
        <div className={scss.SnapshotStat}>
        {dao && dao.partyGoal ? Number(fromBase(dao.partyGoal, dao.depositToken.decimals)).toFixed(4) : '...'}
          <span className={scss.SnapshotLabel}>Goal</span>
        </div>
        <div className={scss.SnapshotStat}>
         {dao && Apr ? `${Apr}%` : '...%'}
          <span className={scss.SnapshotLabel}>Interest Rate</span>
        </div>
        <div className={`${scss.SnapshotStat} ${scss.MarginTop}`}>
          {Number(iTokenValue) && dao && dao.partyGoal ? Number((iTokenValue / dao.partyGoal) * 100).toFixed(3) : '...'}%
          <span className={scss.SnapshotLabel}>Progress</span>
        </div>
        <div className={`${scss.SnapshotStat} ${scss.MarginTop}`}>
          {dao && dao.members ? dao.members.length : '...'}
          <span className={scss.SnapshotLabel}>Peeps Partying</span>
        </div>
      </div>

      <div className={scss.PoolSnapshot}>
        <h2 className={scss.SnapshotHeader}>My Info</h2>
        <div className={scss.SnapshotStat}>
          {memberSavings && dao.depositToken ? Number(memberSavings / 10**dao.depositToken.decimals).toFixed(6) : '0'}
          <span className={scss.SnapshotLabel}>My Savings</span>
        </div>
        <div className={scss.SnapshotStat}>
          {Number(poolPercent) ? Number(poolPercent).toFixed(2) : '0'} %
          <span className={scss.SnapshotLabel}>% of Pool</span>
        </div>
        <div className={scss.SnapshotStat}>
        {earnings ? Number(earnings / 10**dao.depositToken.decimals).toFixed(6) : '0'}
          <span className={scss.SnapshotLabel}>Earnings</span>
        </div>
      </div>

      <button className={`${scss.MainButton} ${scss.MarginTopBottom}`} onClick={handleWithdrawal}>
        Withdraw Earnings
      </button>
    </section>
  );
}

export default Pool;
