import React, { useState, useEffect, useContext  } from 'react';
import { withRouter, Link } from 'react-router-dom';

import { LoadingContext } from 'App';
import { UserContext } from 'contexts/UserStore';

import scss from 'components/header/TopNav.module.scss';
import TransactionCompleteModal from 'components/header/TransactionCompleteModal';
import Emoji from 'components/shared/Emoji';
import IconButton from 'components/shared/IconButton';
import Brand from 'assets/Pool-Party-Logo.png';
import ProcessingIcon from 'assets/DualRingDarkOnLight.svg';
import ProcessingIconHover from 'assets/DualRingLightOnDark.svg';


const TopNav = ({isMenuOpen, setMenuOpen}) => {
  const userContext = useContext(UserContext);
  const {
    processingBackgroundState,
    setProcessingBackgroundState,
    modalMessage,
    modalTitle
  } = useContext(LoadingContext);

  const signIn = async () => {
    if (typeof window.ethereum === 'undefined') {
      // Non-DApp browsers won't work.
      alert(`Please use a web3 enabled browser to log in.`);
    }
    userContext.fetchUserData();
  }

  const signOut = () => {

  }

  const alertMsg = () => {
    alert(`Coming soon to pool-parties near you...`);
  }

  const handleModalClose = () => {
    if (processingBackgroundState === 'modal_open') setProcessingBackgroundState('');
  }

  return (
    <div className={scss.TopNav}>
      <Link className={scss.LeftNav} to="/">
        <img className={scss.BrandImg} src={Brand} alt=" " />
        <h1 className={scss.PoolPartyText}>Pool-Party</h1>
      </Link>
      <div className={scss.RightNav}>
      <div className={scss.TokenNav}>
        <button className={scss.Token} onClick={alertMsg}>$POOL</button>
      </div>
        {userContext.id ?
          <div className={scss.AuthProcessingContainer}>
            <button className={scss.Auth} onClick={signOut}>
              {processingBackgroundState && processingBackgroundState !== 'modal_open' ?
                <>
                  <img className={scss.ProcessingIcon} src={ProcessingIcon} alt="Loading" />
                  <img className={scss.ProcessingIconHover} src={ProcessingIconHover} alt="Loading" />
                  Pending {userContext.id}
                </> : userContext.id
              }
            </button>
            {processingBackgroundState === 'modal_open' ?
              <TransactionCompleteModal
                message={modalMessage}
                title={modalTitle}
                closeModal={handleModalClose} />
            : null}
          </div>
          :
          <button className={scss.Auth} onClick={signIn}>
            sign in w/ web3
          </button>
        }
        <IconButton onClick={() => setMenuOpen(!isMenuOpen)}>
          <Emoji className={scss.HeaderEmojiButton} symbol="🕹️" label="menu" />
        </IconButton>
      </div>
    </div>
  );
};

export default withRouter(TopNav);
