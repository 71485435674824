import React, { useContext } from "react";
import scss from "../CreateDAO.module.scss";
import { FormContext } from "../CreateDAO";

import Tickets from 'assets/create/Tickets.png';
import MutualAidNetworks from 'assets/create/MutualAidNetworks.png';
import PoliticalAction from 'assets/create/PoliticalAction.png';
import PublicGoods from 'assets/create/PublicGoods.png';
import SharedProperty from 'assets/create/SharedProperty.png';
import TechStuff from 'assets/create/TechStuff.png';
import Art from 'assets/create/Art.png';
import VehiclesAndTravel from 'assets/create/VehiclesAndTravel.png';
import Other from 'assets/create/Other.png';


export default function ChooseCategory() {

  const { setFormIndex, setCategory } = useContext(FormContext);

  const handleClick = category => {
    setCategory(category);
    setFormIndex(1);
  }

  return (
    <React.Fragment>
      <h3 className={scss.PageSubHeader}>LVL 1. Pick a category</h3>

      <div className={scss.CategoryList}>
        <button type="button" className={scss.CategoryButton} onClick={() => handleClick('Tickets')}>
          <img className={scss.CategoryImg} src={Tickets} alt="" />
          Prize Pools
        </button>
        <button type="button" className={scss.CategoryButton} onClick={() => handleClick('MutualAidNetworks')}>
          <img className={scss.CategoryImg} src={MutualAidNetworks} alt="" />
          Mutual Aid Networks
        </button>
        <button type="button" className={scss.CategoryButton} onClick={() => handleClick('PoliticalAction')}>
          <img className={scss.CategoryImg} src={PoliticalAction} alt="" />
          Political Action
        </button>

        <button type="button" className={scss.CategoryButton} onClick={() => handleClick('PublicGoods')}>
          <img className={scss.CategoryImg} src={PublicGoods} alt="" />
          Public Goods
        </button>
        <button type="button" className={scss.CategoryButton} onClick={() => handleClick('SharedProperty')}>
          <img className={scss.CategoryImg} src={SharedProperty} alt="" />
          Shared Property
        </button>
        <button type="button" className={scss.CategoryButton} onClick={() => handleClick('TechStuff')}>
          <img className={scss.CategoryImg} src={TechStuff} alt="" />
          DeFi Club
        </button>

        <button type="button" className={scss.CategoryButton} onClick={() => handleClick('Art')}>
          <img className={scss.CategoryImg} src={Art} alt="" />
          NFTs & Art
        </button>
        <button type="button" className={scss.CategoryButton} onClick={() => handleClick('VehiclesAndTravel')}>
          <img className={scss.CategoryImg} src={VehiclesAndTravel} alt="" />
          Vehicles & Travel
        </button>
        <button type="button" className={scss.CategoryButton} onClick={() => handleClick('Other')}>
          <img className={scss.CategoryImg} src={Other} alt="" />
          Other
        </button>
      </div>
    </React.Fragment>
  );
}
