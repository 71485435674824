import React, { useState, useEffect } from "react";
import scss from './VotingBar.module.scss'


function VotingBar({yesVotes, noVotes}) {
  const [meterWidth, setMeterWidth] = useState(0)


  useEffect(() => {
    // updateProposalStatus(proposal,dao)
    const yes = parseInt(yesVotes) || 0
    const no = parseInt(noVotes) || 0

    setMeterWidth( (yes / (no + yes)) * 100 )
  }, [yesVotes, noVotes])

  return (
    <div className={scss.VotesMeterContainer}>
      <div
        className={scss.VotesMeter}
        style={{ width: meterWidth + "%" }}
      ></div>
    </div>
  );
}

export default VotingBar;
