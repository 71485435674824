import React, { useState, useContext } from "react";
import scss from "./CreateDAO.module.scss";
import * as Yup from "yup";
import { useFormik } from "formik";
import ChooseCategory from "./FormPages/ChooseCategory";
import DescriptionPage from "./FormPages/DescriptionPage";
import AdditionalTokensPage from "./FormPages/AdditionalTokensPage";
import InvitePage from "./FormPages/InvitePage";
import { UserContext } from "contexts/UserStore";
import FactoryService from "utils/FactoryService";
import TokenService from "utils/TokenService";
import { getTokenList } from 'utils/TokenHelper'
import { LoadingContext } from "App";
import config from "config";
import { post } from "utils/Requests";
import { toBase } from "utils/Helpers";


export const FormContext = React.createContext();

export default function CreateDAO(props) {
  const userContext = useContext(UserContext);
  const { setProcessing } = useContext(LoadingContext);
  const pages = [
    <ChooseCategory />,
    <DescriptionPage />,
    <AdditionalTokensPage />,
    <InvitePage />
  ];
  const [formIndex, setFormIndex] = useState(0);
  const [category, setCategory] = useState('');

  const initialSchema = Yup.object().shape({
    title: Yup.string().required("Required")
  });
  const [validationSchema, setValidationSchema] = useState(initialSchema);
  const formik = useFormik({
    initialValues: {},
    isInitialValid: false,
    validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  const getApprovedTokenAddresses = (currency, customAddress) => {
    const tokenList = getTokenList();
    const uppercaseCurrency = currency.toUpperCase();
    if (uppercaseCurrency === 'WETH') {
      return [
        tokenList[`WETH_ADDRESS`]
      ];
    }
    else if (uppercaseCurrency === 'CUSTOM') {
      return [
        customAddress
      ];
    }
    else if (uppercaseCurrency === 'PAN') {
      return [
        tokenList[`PAN_ADDRESS`]
      ];
    }
    else {
      return [
        tokenList[`${uppercaseCurrency}_ADDRESS`],
        tokenList[`I${uppercaseCurrency}_ADDRESS`] || tokenList[`A${uppercaseCurrency}_ADDRESS`],
        tokenList[`COMP_ADDRESS`],
        tokenList[`IDLE_ADDRESS`]
      ];
    }
  };

  const getPeepsAddresses = values => (
    Object.values(
      Object.keys(values)
        .filter(key => key.startsWith('peep'))
        .reduce((obj, key) => {
          obj[key] = values[key];
          return obj;
        }, {})
    )
  );

  const getAdditionalTokens = (values, mainTokens) => (
    Object.values(
      Object.keys(values)
        .filter(key => key.startsWith('ADDITIONAL_TOKEN_') && values[key].length > 0)
        .reduce((obj, key) => {
          if (key === 'ADDITIONAL_TOKEN_CUSTOM') obj[key] = values.additionalCustomToken;
          else if (!mainTokens.includes(values[key][0])) obj[key] = values[key][0];
          return obj;
        }, {})
    )
  );

  const getHoursInDuration = (hoursToTranslate, durationInSeconds) => {
    let durationUnits = hoursToTranslate * 3600; // get seconds to translate
    durationUnits = durationUnits / durationInSeconds; // divide seconds by number of seconds in duration
    return durationUnits;
  };

  const getDefaultProposalDeposit = currency => {
    const uppercaseCurrency = currency.toUpperCase();
    switch (uppercaseCurrency) {
      case "WBTC":
      case "YFI":
      case "WETH":
        return .001;
      case "USDC":
      case "SUSD":
      case "TUSD":
      case "USDT":
      case "BUSD":
      case "DAI":
      case "PAN":
        return 5;
      default:
        return .01;
    }
  }

  const handleSubmit = async values => {
    if (!userContext.id){
      alert("Sign in with Web3 to create a new Party")
    }
    
    setProcessing(true);
    const factory = new FactoryService(userContext.id, values.currency);
    const _approvedTokens = getApprovedTokenAddresses(values.currency, values.customToken);
    const additionalTokens = getAdditionalTokens(values, _approvedTokens);
    _approvedTokens.push(...additionalTokens);
    const token = new TokenService(_approvedTokens[0]);
    const decimals = await token.getDecimals(token);

    const peepsAddresses = getPeepsAddresses(values);

    const _daoFees = config.DAO_WALLET_ADDRESS;
    const _partyGoal = toBase(values.goal, decimals);
    const _founders = [userContext.id, ...peepsAddresses];
    const _periodDuration = 60; // seconds in duration
    const _votingPeriodLength = getHoursInDuration(values.votingPeriod || 72, _periodDuration); // default 72 hours = 4320 periods
    const _gracePeriodLength = getHoursInDuration(values.gracePeriod || 36, _periodDuration); // default 36 hours = 2160 periods
    const _proposalDeposit = toBase(
      values.proposalDeposit || getDefaultProposalDeposit(values.currency),
      decimals
    ).toString(); // deposit required to create a proposal

    const _depositRate = toBase(values.minDeposit, decimals); // donation required to join dao + denominator for share issuance
    const _dilutionBound = (values.dilutionBound || 10) + 100;
    //create new Peeps DAO
    factory.startParty(
      _founders,
      _approvedTokens,
      _daoFees,
      _periodDuration,
      _votingPeriodLength,
      _gracePeriodLength,
      _proposalDeposit,
      _depositRate,
      _partyGoal,
      _dilutionBound
    ).then(async resp => {
      // syncing dao data with web2 (mongodb)
      if (resp.events) {
        // storing dao data in db
        post("/api/dao", {
          contract_address: resp.events.PartyStarted.returnValues.pty,
          name: values.title,
          desc: values.description,
          creator: userContext.id,
          creator_name: values.creator,
          hidden: false,
          tags: [],
          tokens: _approvedTokens,
          category: category
        }).then(res => {
          setProcessing(false);
          props.history.push(`/movement/${res.data.contract_address}`);
        });
        _founders.forEach(_founder => {
          post("/api/member/join", {
            id: _founder,
            contract_address: resp.events.PartyStarted.returnValues.pty,
            token: _approvedTokens[0]
          });
        });
      }

      if (resp.error) {
        console.error(resp.error);
      }

      setProcessing(false);
    });
  };

  const formPage = pages[formIndex];

  return (
    <FormContext.Provider value={{ formik, setFormIndex, setValidationSchema, setCategory }}>
      <div className={scss.CreateWrapper}>
        <h1 className={scss.PageHeader}>Create a new pool to save with your peeps</h1>

        <form className={scss.CreateForm} onSubmit={formik.handleSubmit}>
          {formPage}
        </form>
      </div>
    </FormContext.Provider>
  );
}
