import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { isWalletAddress } from 'utils/Helpers';
import Input from 'components/shared/InputFields/Input';
import scss from 'views/movement/components/Movement.module.scss';

const CreateSettingsProposal = ({formik, setValidationSchema}) => {
  useEffect(() => {
    setValidationSchema(Yup.object().shape({
      proposal_name: Yup.string(),
      tributeOffered: Yup.number(),
      paymentRequested: Yup.number(),
      tributeToken: Yup.string().test(
        'isEthAddress',
        'Invalid Buy-in Token address',
        function(value) {
          if (!value) return true;
          return isWalletAddress(value)
        }
      ),
      details: Yup.string(),
      link: Yup.string()
    }));
  }, []);

  return (
    <>
      <Input
        type={"text"}
        handleChange={formik.handleChange}
        labelText={"Name"}
        name={"proposal_name"}
        value={formik.values.proposal_name}
        additionalClasses={scss.DarkLabel}
      />
      <Input
        type={"number"}
        handleChange={formik.handleChange}
        placeholder="Suggest a new party goal"
        labelText={"New Goal"}
        name={"tributeOffered"}
        value={formik.values.tributeOffered}
        additionalClasses={scss.DarkLabel}
      />
      <Input
       type={"number"}
       handleChange={formik.handleChange}
       labelText={"New Deposit Rate"}
       placeholder="Suggest a new buy-in per share"
       name={"paymentRequested"}
       value={formik.values.paymentRequested}
       additionalClasses={scss.DarkLabel}
      />
      <Input
        type={"string"}
        handleChange={formik.handleChange}
        labelText={"Add Token"}
        placeholder="Add an additional token to the Party"
        name={"tributeToken"}
        value={formik.values.tributeToken}
        additionalClasses={scss.DarkLabel}
      />
      <Input
        type={"textarea"}
        name={"details"}
        labelText="Details"
        value={formik.values.details}
        placeholder="32 character limit - keep it short"
        handleChange={formik.handleChange}
        additionalClasses={scss.DarkLabel}
      />
      <Input
        type={"text"}
        handleChange={formik.handleChange}
        labelText={"Link to Additional Info"}
        placeholder="Maybe where you'll tell us more"
        name={"link"}
        value={formik.values.link}
        additionalClasses={scss.DarkLabel}
      />
    </>
  );
}

export default CreateSettingsProposal;
