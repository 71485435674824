import TokenService from "./TokenService";
import Web3Service from "./Web3Service";
import McDaoService from "./McDaoService";
import IdleTokenService from "./IdleTokenService";
import { getTokenList } from 'utils/TokenHelper'
import { BigNumber } from "bignumber.js";
import web3 from "web3";


const web3Service = new Web3Service();

export const truncateAddr = (addr) => {
  return addr.slice(0, 6) + '...' + addr.slice(-4);
};

export const fromBase = (amount, decimals) => {
 return (parseInt(amount) / Math.pow(10, decimals)).toString();
};

export const toBase = (amount, decimals) => {

  const decFactor = new BigNumber(decimals);
  console.log("decimals", decFactor);
  const exp = new BigNumber(10).pow(decFactor);
  console.log("exp", exp);
  const amt = new BigNumber(amount)

  console.log("toBase return value", amt.times(exp).toFixed())
  return amt.times(exp).toFixed();

};

export const getDaoDataFromChain = async (addr) => {
  const mcDao = new McDaoService(addr);
  const id = addr;
  const depositToken = await mcDao.depositToken();
  const tokenList = getTokenList();
  let data;

  if (depositToken.toLowerCase() === tokenList['WETH_ADDRESS'].toLowerCase() || depositToken.toLowerCase() ===  tokenList['PAN_ADDRESS'].toLowerCase()) {

    const currentPeriod = await mcDao.getCurrentPeriod();
    const totalShares = await mcDao.getTotalShares();
    const guildAddr = await mcDao.getGuild();
    const gracePeriodLength = await mcDao.getGracePeriodLength();
    const votingPeriodLength = await mcDao.getVotingPeriodLength();
    const periodDuration = await mcDao.getPeriodDuration();
    const proposalDepositReward = await mcDao.getDepositReward();
    const idleToken = await mcDao.idleToken();
    const depositRate = await mcDao.getDepositRate();
    const totalDeposits = await mcDao.getTotalDeposits();
    const goal = await mcDao.getGoal();
    const tokenService = new TokenService(depositToken);
    const tokenSymbol = await tokenService.getSymbol();
    const decimals = await tokenService.getDecimals();

    const guildValue = await mcDao.getUserTokenBalance(guildAddr, depositToken);

    data = {
      id: addr,
      currentPeriod: parseInt(currentPeriod),
      totalShares: parseInt(totalShares),
      guildAddr,
      depositToken,
      idleToken,
      tokenSymbol,
      gracePeriodLength: parseInt(gracePeriodLength),
      votingPeriodLength: parseInt(votingPeriodLength),
      periodDuration: parseInt(periodDuration),
      proposalDepositReward: fromBase(proposalDepositReward, decimals),
      depositRate: fromBase(depositRate, decimals),
      goal: fromBase(goal, decimals),
      decimals: decimals,
      guildValue: fromBase(guildValue, decimals),
      totalDeposits: fromBase(totalDeposits, decimals),
    }
  }
  else if (depositToken.toLowerCase() != tokenList['WETH_ADDRESS'].toLowerCase() || tokenList['PAN_ADDRESS'].toLowerCase()){
    const mcDao = new McDaoService(addr, depositToken);
    const guildAddr = await mcDao.getGuild();
    const idleToken = await mcDao.idleToken();
    const idleAvgCost = await mcDao.getIdleAvgCost();
    const guildRawValue = await mcDao.getUserTokenBalance(guildAddr.toLowerCase(), idleToken.toLowerCase());
    const guildValue = await mcDao.getIdleValue(guildRawValue);
    const currentPeriod = await mcDao.getCurrentPeriod();
    const totalShares = await mcDao.getTotalShares();
    //const gracePeriodLength = await mcDao.getGracePeriodLength();
    const votingPeriodLength = await mcDao.getVotingPeriodLength();
    const periodDuration = await mcDao.getPeriodDuration();
    const proposalDepositReward = await mcDao.getDepositReward();
    const depositRate = await mcDao.getDepositRate();
    const totalDeposits = await mcDao.getTotalDeposits();
    const goal = await mcDao.getGoal();
    const tokenService = new TokenService(depositToken);
    const tokenSymbol = await tokenService.getSymbol();
    const decimals = await tokenService.getDecimals();
    const idleTokenService = new IdleTokenService(idleToken);
    const avgApr = await idleTokenService.getAvgAPR();

    data = {
      id: addr,
      currentPeriod: parseInt(currentPeriod),
      totalShares: parseInt(totalShares),
      guildAddr,
      depositToken,
      idleToken,
      tokenSymbol,
      //gracePeriodLength: parseInt(gracePeriodLength),
      votingPeriodLength: parseInt(votingPeriodLength),
      periodDuration: parseInt(periodDuration),
      proposalDepositReward: fromBase(proposalDepositReward, decimals),
      guildValue: fromBase(guildValue, decimals),
      shareValue: fromBase(guildValue, decimals) / totalShares,
      depositRate: fromBase(depositRate, decimals),
      goal: fromBase(goal, decimals),
      decimals: decimals,
      totalDeposits: fromBase(totalDeposits, decimals),
      avgApr: fromBase(avgApr, 18),
      idleAvgCost: idleAvgCost
    }
  }
  return data
}

export const isWalletAddress = address => web3.utils.isAddress(address.trim());
