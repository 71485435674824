import config from "config";

export const getNetwork = () => {
  return config.SDK_ENV;
}

export const getTokenList = (network = getNetwork()) => {
  const tokenList = [];
  if (network === 'kovan') {
    tokenList['USDC_ADDRESS'] = '0xb7a4f3e9097c08da09517b5ab877f7a917224ede';
    tokenList['IDAI_ADDRESS'] = '0x295CA5bC5153698162dDbcE5dF50E436a58BA21e';
    tokenList['IUSDC_ADDRESS'] = '0x0de23D3bc385a74E2196cfE827C8a640B8774B9f';
    tokenList['COMP_ADDRESS'] = '0x0de23D3bc385a74E2196cfE827C8a640B8774B9f';
    tokenList['LINK_ADDRESS'] = '0xAD5ce863aE3E4E9394Ab43d4ba0D80f419F61789';
    tokenList['BAT_ADDRESS'] = '0x2d12186Fbb9f9a8C28B3FfdD4c42920f8539D738';
    tokenList['ALINK_ADDRESS'] = '0xEC23855Ff01012E1823807CE19a790CeBc4A64dA';
    tokenList['ABAT_ADDRESS'] = '0x5ad67de6Fb697e92a7dE99d991F7CdB77EdF5F74';
    tokenList['WETH_ADDRESS']  = '0xd0A1E359811322d97991E03f863a0C30C2cF029C';
    tokenList['DAI_ADDRESS'] = '0x4F96Fe3b7A6Cf9725f59d353F723c1bDb64CA6Aa';
    tokenList['IDLE_ADDRESS'] = '0xd4001C66777B435E83fd7e756cB1284A588579cf';
    tokenList['PAN_ADDRESS'] = '0xcbC9f67BC7a4848223daC8105df7ba2597E47d96';

  }

  if (network === 'homestead') {
    tokenList['USDC_ADDRESS'] = '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48';
    tokenList['DAI_ADDRESS'] = '0x6b175474e89094c44da98b954eedeac495271d0f';
    tokenList['WBTC_ADDRESS'] = '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599';
    tokenList['TUSD_ADDRESS'] = '0x0000000000085d4780B73119b644AE5ecd22b376';
    tokenList['SUSD_ADDRESS'] = '0x57ab1ec28d129707052df4df418d58a2d46d5f51';
    tokenList['USDT_ADDRESS'] = '0xdac17f958d2ee523a2206206994597c13d831ec7';
    tokenList['IDAI_ADDRESS'] = '0x3fE7940616e5Bc47b0775a0dccf6237893353bB4';
    tokenList['IUSDC_ADDRESS'] = '0x5274891bEC421B39D23760c04A6755eCB444797C';
    tokenList['IWBTC_ADDRESS'] = '0x8C81121B15197fA0eEaEE1DC75533419DcfD3151';
    tokenList['ITUSD_ADDRESS'] = '0xc278041fDD8249FE4c1Aad1193876857EEa3D68c';
    tokenList['ISUSD_ADDRESS'] = '0xf52cdcd458bf455aed77751743180ec4a595fd3f';
    tokenList['IUSDT_ADDRESS'] = '0xF34842d05A1c888Ca02769A633DF37177415C2f8';
    tokenList['COMP_ADDRESS']  = '0xc00e94cb662c3520282e6f5717214004a7f26888';
    tokenList['BUSD_ADDRESS'] = '0x4fabb145d64652a948d72533023f6e7a623c7c53';
    tokenList['YFI_ADDRESS'] = '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e';
    tokenList['BAT_ADDRESS'] = '0x0d8775f648430679a709e98d2b0cb6250d2887ef';
    tokenList['REN_ADDRESS'] = '0x408e41876cccdc0f92210600ef50372656052a38';
    tokenList['ENJ_ADDRESS'] = '0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c';
    tokenList['KNC_ADDRESS'] = '0xdd974d5c2e2928dea5f71b9825b8b646686bd200';
    tokenList['LINK_ADDRESS'] = '0x514910771af9ca656af840dff83e8264ecf986ca';
    tokenList['MANA_ADDRESS'] = '0x0f5d2fb29fb7d3cfee444a200298f468908cc942';
    tokenList['MKR_ADDRESS'] = '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2';
    tokenList['REP_ADDRESS'] = '0x221657776846890989a759ba2973e427dff5c9bb';
    tokenList['SNX_ADDRESS'] = '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f';
    tokenList['UNI_ADDRESS'] = '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984';
    tokenList['ZRX_ADDRESS'] = '0xe41d2489571d322189246dafa5ebde1f4699f498';
    tokenList['ABUSD_ADDRESS'] = '0x6Ee0f7BB50a54AB5253dA0667B0Dc2ee526C30a8';
    tokenList['AYFI_ADDRESS'] = '0x12e51E77DAAA58aA0E9247db7510Ea4B46F9bEAd';
    tokenList['ABAT_ADDRESS'] = '0xe1ba0fb44ccb0d11b80f92f4f8ed94ca3ff51d00';
    tokenList['AREN_ADDRESS'] = '0x69948cc03f478b95283f7dbf1ce764d0fc7ec54c';
    tokenList['AENJ_ADDRESS'] = '0x712db54daa836b53ef1ecbb9c6ba3b9efb073f40';
    tokenList['AKNC_ADDRESS'] = '0x9D91BE44C06d373a8a226E1f3b146956083803eB';
    tokenList['ALINK_ADDRESS'] = '0xA64BD6C70Cb9051F6A9ba1F163Fdc07E0DfB5F84';
    tokenList['AMANA_ADDRESS'] = '0x6FCE4A401B6B80ACe52baAefE4421Bd188e76F6f';
    tokenList['AMKR_ADDRESS'] = '0x7deB5e830be29F91E298ba5FF1356BB7f8146998';
    tokenList['AREP_ADDRESS'] = '0x71010a9d003445ac60c4e6a7017c1e89a477b438';
    tokenList['ASNX_ADDRESS'] = '0x328C4c80BC7aCa0834Db37e6600A6c49E12Da4DE';
    tokenList['AUNI_ADDRESS'] = '0xB124541127A0A657f056D9Dd06188c4F1b0e5aab';
    tokenList['AZRX_ADDRESS'] = '0x6Fb0855c404E09c47C3fBCA25f08d4E41f9F062f';
    tokenList['WETH_ADDRESS'] = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2';
    tokenList['IDLE_ADDRESS'] = '0x875773784Af8135eA0ef43b5a374AaD105c5D39e';
    tokenList['PAN_ADDRESS'] = '0xd56dac73a4d6766464b38ec6d91eb45ce7457c44';
  }
  return tokenList;
}
