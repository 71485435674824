import React, { useContext } from 'react';
import Pool from 'views/movement/components/Pool';
import MovementHome from 'views/movement/components/MovementHome';
import Manage from 'views/movement/components/Manage';
import scss from 'views/movement/components/CardNav.module.scss';

const CardNav = ({context, selected}) => {
  const {displayPage} = useContext(context);
  const poolClasses = [scss.NavItem];
  const depositClasses = [scss.NavItem];
  const manageClasses = [scss.NavItem];
  if (selected === 'pool') poolClasses.push(scss.Selected);
  if (selected === 'deposit') depositClasses.push(scss.Selected);
  if (selected === 'manage') manageClasses.push(scss.Selected);

  const handleNavKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (event.target.id === 'pool') displayPage(Pool, {});
      if (event.target.id === 'desposit') displayPage(MovementHome, {});
      if (event.target.id === 'manage') displayPage(Manage, {});
    }
  }

  return (
    <div className={scss.NavContainer}>
      <h2
        id='pool'
        tabIndex='0'
        className={poolClasses.join(' ')}
        onClick={() => displayPage(Pool, {})}
        onKeyPress={handleNavKeyPress}>
        Pool
      </h2>
      <h2
        id='desposit'
        tabIndex='0'
        className={depositClasses.join(' ')}
        onClick={() => displayPage(MovementHome, {})}
        onKeyPress={handleNavKeyPress}>
        Deposit
      </h2>
      <h2
        id='manage'
        tabIndex='0'
        className={manageClasses.join(' ')}
        onClick={() => displayPage(Manage, {})}
        onKeyPress={handleNavKeyPress}>
        Manage
      </h2>
    </div>
  );
}

export default CardNav;
