import React, { useContext } from "react";
import scss from "../CreateDAO.module.scss";
import { FormContext } from "../CreateDAO";
import { getTokenList } from 'utils/TokenHelper'
import Input from "components/shared/InputFields/Input";

export default function DescriptionPage() {
  const { formik, setFormIndex } = useContext(FormContext);

  const tokenList = getTokenList();
  const tokenOptions = [];
  for (const [key, value] of Object.entries(tokenList)) {
    const tokenName = key.split('_')[0];
    // TODO when adding aave tokens, take away startsWith A conditional
    if (tokenName === 'IDLE' || (!tokenName.startsWith('I') && !tokenName.startsWith('A') && tokenName !== formik.values.currency)) {
      tokenOptions.push(
        <div className={scss.TokenOption} key={tokenName}>
        <input
        className={scss.TokenCheckbox}
        type='checkbox'
        id={tokenName}
        name={`ADDITIONAL_TOKEN_${tokenName}`}
        onChange={formik.handleChange}
        checked={formik.values[tokenName] && formik.values[tokenName].length > 0}
        value={value} />
        <label className={scss.TokenCheckboxLabel} htmlFor={tokenName}>
        <div className={scss.TokenName}>{tokenName}</div>
        <div className={scss.TokenAddress}>{value}</div>
        </label>
        </div>
      );
    }
  }
  tokenOptions.push(
    <div className={scss.CustomTokenOption} key='custom'>
      <input
        className={scss.TokenCheckbox}
        type='checkbox'
        id='ADDITIONAL_TOKEN_CUSTOM'
        name='ADDITIONAL_TOKEN_CUSTOM'
        onChange={formik.handleChange}
        checked={formik.values.ADDITIONAL_TOKEN_CUSTOM && formik.values.ADDITIONAL_TOKEN_CUSTOM.length > 0}
        value='ADDITIONAL_TOKEN_CUSTOM' />
      <label className={scss.TokenCheckboxLabel} htmlFor='ADDITIONAL_TOKEN_CUSTOM'>
        <div className={scss.TokenName}>Custom token address</div>
        <div className={scss.TokenAddress}>
          <Input
            type={"text"}
            handleChange={formik.handleChange}
            name={"additionalCustomToken"}
            labelText={""}
            placeholder={"E.g. 0x6b175474e89094c44da98b954eedeac495271d0f"}
            value={formik.values.additionalCustomToken}
          />
        </div>
      </label>
    </div>
  );

  return (
    <React.Fragment>
      <h3 className={scss.PageSubHeader}>LVL 3. Whitelist More Tokens</h3>

      <div className={scss.CreateTokenList}>
        <div className={scss.CreateTokenList}>
          {tokenOptions}
        </div>

        <div className={scss.ButtonRow}>
          <button
            type='button'
            className={scss.SecondaryButton}
            onClick={() => setFormIndex(1)}>
            Back
          </button>
          <button
            type='button'
            className={scss.MainButton}
            onClick={() => setFormIndex(3)}>
            Invite Peeps
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
