import { useState, useEffect } from "react";
import {
  getProposalStatus,
  getStatusMessage
} from "../../utils/ProposalHelper";

const useSetProposalStatus = (proposal, dao) => {
  const [proposalStatus, setProposalStatus] = useState();
  const [proposalStatusMessage, setProposalStatusMessage] = useState();

  const updateProposalStatus = (proposal, dao) => {
    console.log("use", proposal, dao);
    const status = getProposalStatus(proposal, dao);
    setProposalStatus(status);

    setProposalStatusMessage(getStatusMessage(status, proposal, dao));
  };
  useEffect(() => {
    updateProposalStatus(proposal, dao);
  }, [proposal, dao]);

  return { proposalStatus, proposalStatusMessage, updateProposalStatus };
};
export default useSetProposalStatus;
