import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from "contexts/UserStore";
import { post } from "../../../utils/Requests";
import { fromBase, toBase } from "../../../utils/Helpers";

import McDaoService from "../../../utils/McDaoService";
import web3 from 'web3';

import scss from 'views/movement/components/Movement.module.scss';
import { errorToast } from "../../../utils/NotificationService";


const MembersView = ({context}) => {
  const {members,
    dao,
    fetchGraphData,
    fetchMembers,
    proposals,
    setProposalData,
    setProcessingBackgroundState,
    setModalMessage,
    setModalTitle
  } = useContext(context);
  const userContext = useContext(UserContext);

  const [membersList, setMembersList] = useState([]);

  useEffect(() => {
    updateMembersList();
  }, [members]);

  const updateMembersList = () => {
    const existingMembers = members.filter(member => member.exists);
    existingMembers.sort(
      (member1, member2) => member2.shares - member1.shares
    );
    const newMembersList = existingMembers.map((member, index) => {
      const { username, id, isMember } = member;

      const handleQuitClicked = async () => {
        const mcDao = new McDaoService(dao.contract_address, dao.depositToken.tokenAddress, userContext.id);

        if (member.shares || member.loot > 0) {
          setProcessingBackgroundState('awaiting_quit_processing');

          await mcDao.rageQuit().then(async resp => {
              if (resp.events) {
                setProcessingBackgroundState('modal_open');
                setModalMessage(`So sad to see you leave the "${dao.name}" party`);
                setModalTitle(`Goodbye!`);
                await fetchGraphData();
                fetchMembers();
              } else {
                setProcessingBackgroundState('');
                errorToast("There has been an error processing your request");
                console.error(resp.errors);
              }
            });
         }
      }


      const handleKickClicked = async() => {
        const mcDao = new McDaoService(dao.contract_address, dao.depositToken.tokenAddress, userContext.id);

        const depositAmt = dao.proposalDepositReward;
        setProcessingBackgroundState('awaiting_kick_processing');
        await mcDao.approveDeposit(dao.contract_address, depositAmt);
        const memberToKick = member.id;
        const token = dao.depositToken.tokenAddress;
        const details = web3.utils.fromAscii("this member needs to go");

        await mcDao.submitProposal(
          memberToKick,
          0,
          0,
          0,
          0,
          4,
          token,
          token,
          details
          ).then(async resp => {
            if (resp.events) {
              // creating a web2
              const res = await post("/api/proposal/create", {
                id:
                resp.events.SubmitProposal.address +
                  "-" +
                  resp.events.SubmitProposal.returnValues.proposalId,
                proposal_id: resp.events.SubmitProposal.returnValues.proposalId,
                contract_address: resp.events.SubmitProposal.address,
                creator_id: resp.events.SubmitProposal.returnValues.applicant,
                proposal_name: 'Guild Kick',
                link: "none",
                token: dao.tokenSymbol,
                status: "awaiting_sponsor",
              });

              await setProposalData([
                ...proposals,
                { ...res.data, ...resp.events.submitProposal.returnValues }
              ]);

              setProcessingBackgroundState('modal_open');
              setModalMessage(`You just submitted a kick proposal for the "${dao.name}" party`);
              setModalTitle(`Cannonball!`);
              fetchGraphData();
            } else {
              setProcessingBackgroundState('');
              errorToast("There has been an error processing your request");
              console.error(resp.errors);
            }
          });
      }
      return (
        <div key={index} className={scss.MemberListItem}>
          <div className={scss.MemberName}>{username ? username : 'Partygoer'}</div>
          <div className={scss.MemberInfoRow}>
            <div className={scss.MemberAddress}>{id}</div>
            <div className={scss.MemberAmount}>{member.shares} share{member.shares === '1' ? '' : 's'}</div>
            <div className={scss.MemberAmount}>{member.loot} loot</div>
            {isMember ?
              <div className={scss.MemberApproved}>
                <span className={scss.MemberIdleTokenBalance}>
                  {dao && dao.depositToken && member.iTB ?
                    Number(fromBase(member.iVal, dao.depositToken.decimals)).toFixed(3)  :
                    '...'
                  }
                </span>
                {userContext.id && userContext.id.toLowerCase() === member.memberAddress.toLowerCase() ?
                  <button className={scss.MemberQuitButton} onClick={handleQuitClicked}>
                    leave party
                  </button>
                : <button className={scss.MemberQuitButton} onClick={handleKickClicked}>
                    kick out
                  </button>}
              </div>
            :
              <div className={scss.MemberApproved}>Request join</div>
            }
          </div>
        </div>
      );
    });

    setMembersList(newMembersList);
  }

  return (
    <>
      <div className={scss.MemberTitleRow}>
        <div className={scss.MemberNameTitle}>Member</div>
        <div className={scss.MemberSharesTitle}>Voting Shares</div>
        <div className={scss.MemberLootTitle}>No-Vote Shares</div>
        <div className={scss.MemberDepositsValueTitle}>Deposits</div>
      </div>
      <div className={scss.MembersList}>{membersList}</div>
    </>
  );
}

export default MembersView;
