import { ethers } from "ethers";
import config from '../config'
import axios from 'axios'

export const getUserAccount = async (provider) => {
  let eth;
  let account;

  if (!provider && window.ethereum) provider = window.ethereum;
  if (provider) {
    eth = new ethers.providers.Web3Provider(provider);

    if(await checkNetwork(eth)) {
      const accounts = await eth.listAccounts();
      account = accounts[0];
    }
  }

  if (account) return account;
  else return undefined;
}

export const getMongoUser = async (account) => {
  let user;
  if(account) {
    user = {id: account }
    try{
      const userData = await axios.get("/api/user/", { params: { id: account } });
      if(userData.data){
        user = userData.data;
      }
    }
    catch(e) {
      alert(e)
    }

  }
  if(user) return user;
  else return {}
}

export const getAccountInfo = async (provider) => {

  const account = await getUserAccount(provider);
  if(account) {
    const user = await getMongoUser(account);
    return user;
  }
  else {
    return undefined;
  }

}

// @DEV change to "mainnet for launch"
const checkNetwork = async eth => {
  const network = await eth.getNetwork();
  if (network.name !== config.SDK_ENV.toLowerCase()) {
    if (config.SDK_ENV.toLowerCase() == 'homestead'){
      alert(`Make sure your network is set to mainnet.`);
    } else {
      alert(`Make sure your network is set to ${config.SDK_ENV.toLowerCase()}.`);
    }
    return false;
  }
  return true;
}
