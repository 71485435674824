import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';

const Footer = () => {
  return (
    <footer className="Footer">
      <div className="Column">
        <div className="FooterRow">
          <a href="http://www.peepsdemocracy.com/" target="_blank" rel="noopener noreferrer">about peeps</a>
        </div>
        <div className="FooterRow">
          <a href="https://medium.com/peeps-democracy" target="_blank" rel="noopener noreferrer">our blog</a>
        </div>
      </div>
      <div className="Column">
        <div className="FooterRow">
          <Link to="/terms">terms of use</Link>
        </div>
        <div className="FooterRow">
          <Link to="/privacy">privacy policy</Link>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
