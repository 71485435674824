import React from 'react';
import scss from './Loading.module.scss';
import IconProcessing from './IconProcessing';

const Loading = () => (
  <div className={scss.Loading}>
    <IconProcessing />
  </div>
);

export default Loading;
