import Web3Service from '../utils/Web3Service';
import Erc20Abi from '../contracts/erc20a.json';
import {fromBase, toBase} from './Helpers.js'
import web3 from 'web3'


export default class WethService {
  contractAddr;
  web3Service;
  contract;
  abi;

  constructor(daoToken, userAccount) {
    this.web3Service = new Web3Service();
    this.abi = Erc20Abi;
    this.contractAddr = daoToken;
    this.account = userAccount

    this.initContract();
  }

  async initContract() {
    this.contract = await this.web3Service.initContract(
      this.abi,
      this.contractAddr,
    );
    return this.contract;
  }

  async userHasSufficientTokens(userId, amount) {
    // converts user amount to wei for use in comparison

    const ethAmount = amount;

    const tokenBalance = await this.balanceOf(userId);
    const decimals = await this.getDecimals();

    console.log('balance', parseFloat(fromBase(tokenBalance, decimals)) + ' amount ' + parseFloat(fromBase(ethAmount, decimals)))
    return parseFloat(fromBase(tokenBalance, decimals)) > parseFloat(fromBase(ethAmount, decimals))
  }

  async userHasAllowance(userId, spender) {
    // converts user amount to wei for use in comparison

    const tokenBalance = await this.balanceOf(userId);
    const allowance = await this.allowance(userId, spender);
    const decimals = await this.getDecimals();

    return parseFloat(fromBase(allowance, decimals)) > parseFloat(fromBase(tokenBalance, decimals));
  }


  async getSymbol() {
    if (!this.contract) {
      await this.initContract();
    }

    const symbol = await this.contract.methods.symbol().call();
    return symbol;
  }

  async getDecimals() {
    if (!this.contract) {
      await this.initContract();
    }

    const decimals = await this.contract.methods.decimals().call();
    return decimals;
  }

  async totalSupply() {
    if (!this.contract) {
      await this.initContract();
    }
    const totalSupply = await this.contract.methods.totalSupply().call();
    return totalSupply;
  }

  async balanceOf(account, atBlock = 'latest') {
    if (!this.contract) {
      await this.initContract();
    }

    const balanceOf = await this.contract.methods
      .balanceOf(account)
      .call({}, atBlock);

    return balanceOf;
  }

  async allowance(accountAddr, contractAddr) {
    if (!this.contract) {
      await this.initContract();
    }
    const allowance = await this.contract.methods
      .allowance(accountAddr, contractAddr)
      .call();
    return allowance;
  }

  async approveDeposit(spender, amount, encodedPayload = false) {
    
    if (!this.contract) {
      await this.initContract();
    }

      let approveDeposit = await this.contract.methods.approve(spender, amount)
      .send({ from: this.account })
      .once('transactionHash', (txHash) => {
        console.log('transactionhash', txHash)
      })
      .then((resp) => {
        console.log(resp)
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return { error: 'rejected transaction' };
      });
      return approveDeposit;
  }

  async deposit(from, amount, encodedPayload) {
    if (!this.contract) {
      await this.initContract();
    }

    if (encodedPayload) {
      const data = this.contract.methods.deposit().encodeABI();
      return data;
    }

    let deposit = this.contract.methods
      .deposit()
      .send({ from, value: amount })
      .once('transactionHash', (txHash) => {})
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return { error: 'rejected transaction' };
      });

    return deposit;
  }

  async transfer(from, recipient, amount, encodedPayload) {
    if (!this.contract) {
      await this.initContract();
    }

    if (encodedPayload) {
      const data = this.contract.methods.transfer(recipient, amount).encodeABI();
      return data;
    }

    const trans = await this.contract.methods
      .transfer(recipient, amount)
      .send({ from: this.account })
      .once('transactionHash', (txHash) => {})
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return { error: 'rejected transaction' };
      });

    return trans;
  }
}
