import React, { useContext, useState, useEffect } from 'react';
import web3 from 'web3';
import TokenService from "utils/TokenService";
import { getTokenList } from 'utils/TokenHelper';
import { UserContext } from "contexts/UserStore";
import scss from 'views/movement/components/Movement.module.scss';
import { loginMetamaskError } from "../../../utils/NotificationService";
import McDaoService from "../../../utils/McDaoService";
import { post } from "../../../utils/Requests";
import { fromBase, toBase } from "../../../utils/Helpers";
import { updateMembers } from "../../../hooks/updateMembers";


const JoinCard = ({ context }) => {
  const {
    dao,
    displayPage,
    setProcessingBackgroundState,
    processingBackgroundState,
    setModalMessage,
    setModalTitle,
    setMembers,
    members,
    fetchGraphData,
    proposals,
    setProposalData
  } = useContext(context);
  const [donation, setDonation] = useState({});
  const [donationDisplayValue, setDonationDisplayValue] = useState('');
  const [iTokenValue, setiTokenValue] = useState({});
  const [allowance, setAllowance] = useState(false);
  const [isMember, setMembership] = useState(false);
  const [memberInfo, setMemberInfo] = useState({});
  const [error, setError] = useState();
  const userContext = useContext(UserContext);
  const apr = Number(dao.avgApr);

  useEffect(() => {
    if (dao) {
      if (dao.depositRate) {
        console.log("dao info", dao)
        setDonation(fromBase(dao.depositRate, dao.depositToken.decimals));
      }
      if (dao.depositToken && dao.depositToken.tokenAddress && dao.contract_address && userContext.id) {
        checkMember();
        hasAllowance();
        checkGuildBalance();
      }
    }
  }, [dao])


  const handleNumInput = (event) => {
    checkMember();
    const {value} = event.currentTarget;
    const floatValue = parseFloat(value);

    if (value || value === '') {
      setDonationDisplayValue(floatValue);
      setDonation(floatValue);
      console.log("floatValue", floatValue)
      hasAllowance();

    }
  }

  const handleMin = () => {
    checkMember();
    setDonationDisplayValue(fromBase(dao.depositRate, dao.depositToken.decimals));
    setDonation(fromBase(dao.depositRate, dao.depositToken.decimals));

  }

  const handleMax = async () => {
    checkMember();
    console.log("isMember", memberInfo.shares)

    const checkValue = (value) => {
      if (value) {
        return true
      }
    }

    const memberStake = checkValue(memberInfo.shares) ? memberInfo.shares : 0;
    const tokenService = new TokenService(dao.depositToken.tokenAddress);
    const userMaxTokens = await tokenService.balanceOf(userContext.id);
    const goal = dao.goal
    const rate = fromBase(dao.depositRate, dao.depositToken.decimals)
    const daoMaxTokens = (((goal / rate) /2) * rate) - (memberStake * rate)
    const maxTokens = Math.min(fromBase(userMaxTokens, dao.depositToken.decimals), daoMaxTokens);
    if (maxTokens < rate) {
      alert("Either you're low on tokens or you've already maxed out on deposits!")
    }
    setDonationDisplayValue(maxTokens);
    setDonation(maxTokens);
  }

  const hasAllowance = async () => {
    const tokenService = new TokenService(dao.depositToken.tokenAddress);
    const allowance = await tokenService.userHasAllowance(userContext.id, dao.contract_address)
    if (allowance) {
      setAllowance('true');
    }
   }

  const checkGuildBalance = async () => {
    const mcDao = new McDaoService(dao.contract_address, dao.depositToken.tokenAddress, userContext.id);
    const guildAddr = await mcDao.getGuild();
    const guildITokenBalance = await mcDao.getUserTokenBalance(guildAddr, dao.idleToken.tokenAddress)
    const iTokenValue = await mcDao.getIdleValue(guildITokenBalance);
    if(iTokenValue) {
      setiTokenValue(iTokenValue);
    }
  }

   const checkMember = async () => {
    const mcDao = new McDaoService(dao.contract_address, dao.depositToken.tokenAddress, userContext.id);
    const isMember = await mcDao.members(userContext.id);
    console.log("isMember", isMember);
    if(isMember.exists && !isMember.jailed){
      setMembership('true');
      setMemberInfo(isMember);
    }
   }

  const handleApproval = async () => {
    setProcessingBackgroundState('awaiting_approve_deposit');
    const mcDao = new McDaoService(dao.contract_address, dao.depositToken.tokenAddress, userContext.id);
    const bigAmt = 10000;
    const amount = toBase(bigAmt, dao.depositToken.decimals);
    const promise = mcDao.approveDeposit(dao.contract_address, amount);
    promise
      .then(resp => {
        setProcessingBackgroundState('');
        hasAllowance();
        return resp;
      });
  }

  const handleJoin = async () => {
    // TODO add alert/error here for when this if fails (donation too small)
    if (donation >= fromBase(dao.depositRate, dao.depositToken.decimals)) {
      console.log("asking to join")
      if(!userContext.id) {
        alert("Login to Metamask")
      }

      //initiate new web3 services
      const mcDao = new McDaoService(dao.contract_address, dao.depositToken.tokenAddress, userContext.id);
      const tokenService = new TokenService(dao.depositToken.tokenAddress);

      //get web3 parameters and inputs
      const applicant = userContext.id;
      const tributeOffered = toBase(donation, dao.depositToken.decimals);
      const sharesRequested = (tributeOffered / dao.depositRate);
      const lootRequested = 0;
      const paymentRequested = 0;
      const flagNumber = 6;
      const tributeToken = dao.depositToken.tokenAddress;
      const paymentToken = dao.depositToken.tokenAddress;
      const details = web3.utils.fromAscii("Can I join the party?");

      const tokenList = getTokenList();
      const isWeth = dao.idleToken.tokenAddress.toLowerCase() === tokenList['WETH_ADDRESS'].toLowerCase();
      let hasSufficientTokens;
      if (!isWeth) hasSufficientTokens = await tokenService.userHasSufficientTokens(userContext.id, donation);
      // TODO add error for when user does not have enough tokens
      if (isWeth || hasSufficientTokens) {
        setProcessingBackgroundState('awaiting_join_processing');

        await mcDao.submitProposal(
          applicant,
          tributeOffered,
          sharesRequested,
          lootRequested,
          paymentRequested,
          flagNumber,
          tributeToken,
          paymentToken,
          details
          ).then(async resp => {
            console.log('resp', resp.events)
            if (resp.events) {
              // creating a web2
              const res = await post("/api/proposal/create", {
                id:
                  resp.events.SubmitProposal.address +
                  "-" +
                  resp.events.SubmitProposal.returnValues.proposalId,
                proposal_id: resp.events.SubmitProposal.returnValues.proposalId,
                contract_address: resp.events.SubmitProposal.address,
                creator_id: resp.events.SubmitProposal.returnValues.applicant,
                proposal_name: "Add partygoer?",
                link: "none",
                token: dao.tokenSymbol,
                status: "awaiting_sponsor",
              });
              console.log("response 2", res.data)

              await setProposalData([
                ...proposals,
                { ...res.data, ...resp.events.SubmitProposal.returnValues }
              ]);
              setProcessingBackgroundState('modal_open');
              setModalMessage(`You just asked to join the "${dao.name}" party. We've generated a proposal for you to join and (if) the members vote you in, you'll join the party.`)
              setModalTitle(`A knock on the door`);
              fetchGraphData();
            } else {
              setProcessingBackgroundState('');
              alert("Something went wrong or you rejected the transaction...");
              console.log(resp.errors);
            }
          });
       }
    }
  }
  const handleDeposit = async () => {
    if (donation >= fromBase(dao.depositRate, dao.depositToken.decimals)) {
      if(!userContext.id) {
        loginMetamaskError('donate')
        return
      }

      //@Dev - should move this to a checkMax funciton.
      const memberStake = memberInfo.shares ? memberInfo.shares : 0;
      const tokenService = new TokenService(dao.depositToken.tokenAddress);
      const userMaxTokens = await tokenService.balanceOf(userContext.id);
      const goal = dao.goal
      const rate = fromBase(dao.depositRate, dao.depositToken.decimals)
      const daoMaxTokens = (((goal / rate) /2) * rate) - (memberStake * rate)
      const maxTokens = Math.min(fromBase(userMaxTokens, dao.depositToken.decimals), daoMaxTokens);
      const tokenList = getTokenList();
      const isWeth = dao.idleToken.tokenAddress.toLowerCase() === tokenList['WETH_ADDRESS'].toLowerCase();

      if(donation > maxTokens && !isWeth) {
        alert("You're trying to donate too much, you'll either need to make a proposal or top up on tokens");
      }

      if (donation > daoMaxTokens) {
        alert("You're trying to donate too much, you'll need to make a proposal instead");
      }

      //initiate new web3 services
      const mcDao = new McDaoService(dao.contract_address, dao.depositToken.tokenAddress, userContext.id);
      console.log("deposit token", dao.depositToken.symbol)
      //get web3 parameters and inputs
      const amount = toBase(donation, dao.depositToken.decimals);
      const shares = Math.floor(donation / fromBase(dao.depositRate, dao.depositToken.decimals));
      const isMember = await mcDao.members(userContext.id);
      let hasSufficientTokens;
      if (!isWeth) hasSufficientTokens = await tokenService.userHasSufficientTokens(userContext.id, donation);
      // TODO add error for when user does not have enough tokens
      if ((isWeth || hasSufficientTokens) && isMember.exists) {

        //authorize DAO as spender of donation amout
        setProcessingBackgroundState('awaiting_deposit_processing');

        const promise = mcDao.makeDeposit(amount);
        promise
        .then(async resp => {
          if (resp.events) {
            setProcessingBackgroundState('modal_open');
            setModalMessage(`You just made the "${dao.name}" party a bit better. You'll receive ${shares} voting ${shares === 1 ? 'share' : 'shares'} in exchange.`);
            setModalTitle(`Deposit`);
            fetchGraphData();
          }
          // todo set so that will grab new member data from axios
          if (resp.error) {
            setProcessingBackgroundState('');
            alert("Something went wrong or you rejected the transaction...");
          }
        });
      } else {
        return alert('sorry either not a member of this party or insufficent token balance to deposit')
      }
    }
    else setError(`Amount cannot be less than ${fromBase(dao.depositRate, dao.depositToken.decimals)} ${dao.tokenSymbol}`)
  }

  const modalForegroundClasses = [scss.ModalForeground];
  if (processingBackgroundState === 'awaiting_approve_deposit') {
    modalForegroundClasses.push(scss.ModalActive);
  }

  return (<>
    <div className={modalForegroundClasses.join(' ')}>
      <div className={scss.ModalHelpText}>Confirm transaction to continue</div>
    </div>
    <div className={scss.Container}>
      <input
        type='number'
        className={scss.Amount}
        onChange={handleNumInput}
        name='donation'
        placeholder='0.0'
        value={donationDisplayValue} />
      {error ?
        <div className={scss.Error}>
          {error}
        </div>
      :
        <div className={scss.MinAmountText}>
         {dao && dao.depositRate ?
           fromBase(dao.depositRate, dao.depositToken.decimals)
         : '...'} {dao.tokenSymbol} per share
        </div>
      }
      <div className={scss.CurrencyTypeText}>
      {dao.tokenSymbol}
      </div>

      <div className={scss.MinMaxButtonsWrapper}>
        <button onClick={handleMin} className={scss.MinMaxButton}>Input min</button>
        <button onClick={handleMax} className={scss.MinMaxButton}>Input max</button>
      </div>
      {!allowance ?
        <button onClick={handleApproval} className={scss.MainButton}>Approve ${dao.tokenSymbol}</button>
        :
        <div>
          {isMember ?
            <button onClick={handleDeposit} className={scss.MainButton}>Deposit</button>
            :
            <button onClick={handleJoin} className={scss.MainButton}>Ask to Join</button>
          }
        </div>
      }


      <div className={scss.PoolSnapshot}>
        <h2 className={scss.SnapshotHeader}>Pool Snapshot</h2>
        <div className={scss.SnapshotStat}>
          {dao && dao.guildValue ?
            Number(dao.guildValue).toFixed(4)
          : '...'} {dao.tokenSymbol}
          <span className={scss.SnapshotLabel}>Savings Pooled</span>
        </div>
        <div className={scss.SnapshotStat}>
          {dao && dao.partyGoal ?
            Number(fromBase(dao.partyGoal, dao.depositToken.decimals)).toFixed(2)
          : '...'} {dao.tokenSymbol}
          <span className={scss.SnapshotLabel}>Goal</span>
        </div>
        <div className={scss.SnapshotStat}>
          {apr ? apr.toFixed(3) : '...'}%
          <span className={scss.SnapshotLabel}>Interest Rate</span>
        </div>
      </div>
    </div>
  </>);
};

export default JoinCard;