import React from 'react';
import JoinCard from 'views/movement/components/JoinCard';
import CardNav from 'views/movement/components/CardNav';
import scss from 'views/movement/components/Movement.module.scss';

const MovementHome = ({context}) => (
  <section className={scss.DAOCard}>
    <CardNav context={context} selected='deposit' />
    <JoinCard context={context} />
  </section>
);

export default MovementHome;
