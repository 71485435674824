const dotenv = require('dotenv');
dotenv.config();

const dev = {
  GRAPH_NODE_URI: process.env.REACT_APP_DEV_GRAPH_NODE_URI, // replace with subgraph for your moloch
  INFURA_URI: process.env.REACT_APP_DEV_INFURA_URI, // replace with your infura key
  FACTORY_ADDRESS: process.env.REACT_APP_DEV_FACTORY_CONTRACT_ADDRESS, // factory address @Dev update to Factory2 asap 
  WETH_FACTORY_ADDRESS: process.env.REACT_APP_DEV_WETH2_FACTORY_CONTRACT_ADDRESS, // WETH factory address
  NOFI_FACTORY_ADDRESS: process.env.REACT_APP_DEV_NOFI_FACTORY_CONTRACT_ADDRESS,
  DAO_WALLET_ADDRESS: process.env.REACT_APP_DAO_WALLET_ADDRESS, // contract address for recieving admin fees
  USDC_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_USDC_CONTRACT_ADDRESS,
  DAI_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_DAI_CONTRACT_ADDRESS,
  WBTC_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_WBTC_CONTRACT_ADDRESS,
  TUSD_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_TUSD_CONTRACT_ADDRESS,
  SUSD_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_SUSD_CONTRACT_ADDRESS,
  USDT_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_USDT_CONTRACT_ADDRESS,
  IDAI_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_IDAI_CONTRACT_ADDRESS,
  IUSDC_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_IUSDC_CONTRACT_ADDRESS,
  IWBTC_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_IWBTC_CONTRACT_ADDRESS,
  ITUSD_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_ITUSD_CONTRACT_ADDRESS,
  ISUSD_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_ISUSD_CONTRACT_ADDRESS,
  IUSDT_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_IUSDT_CONTRACT_ADDRESS,
  COMP_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_COMP_CONTRACT_ADDRESS,
  SDK_ENV: 'homestead', // set to homestead for mainnet
  NETWORK_ID: '1', // set to 1 for mainnet and 42 kovan
  FORTMATIC_KEY: process.env.REACT_APP_DEV_FORTMATIC_KEY,
  PORTIS_KEY: process.env.REACT_APP_DEV_PORTIS_KEY,
  s3: {
    REGION: process.env.REACT_APP_DEV_S3_REGION,
    BUCKET: process.env.REACT_APP_DEV_S3_BUCKET,
  },
  cognito: {
    REGION: process.env.REACT_APP_DEV_COGNITO_REGION,
    USER_POOL_ID: process.env.REACT_APP_DEV_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_DEV_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_DEV_COGNITO_IDENTITY_POOL_ID,
  },
};

const prod = {
  GRAPH_NODE_URI: process.env.REACT_APP_DEV_GRAPH_NODE_URI, // replace with subgraph for  mainnet
  INFURA_URI: process.env.REACT_APP_DEV_INFURA_URI, // replace with your infura key for mainnet
  FACTORY_ADDRESS: process.env.REACT_APP_DEV_FACTORY_CONTRACT_ADDRESS, // factory address
  WETH_FACTORY_ADDRESS: process.env.REACT_APP_DEV_WETH2_FACTORY_CONTRACT_ADDRESS, // WETH factory address
  NOFI_FACTORY_ADDRESS: process.env.REACT_APP_DEV_NOFI_FACTORY_CONTRACT_ADDRESS,
  DAO_WALLET_ADDRESS: process.env.REACT_APP_DAO_WALLET_ADDRESS, // contract address for recieving admin fees
  USDC_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_USDC_CONTRACT_ADDRESS,
  DAI_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_DAI_CONTRACT_ADDRESS,
  WBTC_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_WBTC_CONTRACT_ADDRESS,
  TUSD_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_TUSD_CONTRACT_ADDRESS,
  SUSD_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_SUSD_CONTRACT_ADDRESS,
  USDT_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_USDT_CONTRACT_ADDRESS,
  IDAI_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_IDAI_CONTRACT_ADDRESS,
  IUSDC_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_IUSDC_CONTRACT_ADDRESS,
  IWBTC_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_IWBTC_CONTRACT_ADDRESS,
  ITUSD_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_ITUSD_CONTRACT_ADDRESS,
  ISUSD_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_ISUSD_CONTRACT_ADDRESS,
  IUSDT_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_IUSDT_CONTRACT_ADDRESS,
  COMP_CONTRACT_ADDRESS: process.env.REACT_APP_DEV_COMP_CONTRACT_ADDRESS,
  SDK_ENV: 'homestead',
  NETWORK_ID: '1',
  FORTMATIC_KEY: process.env.REACT_APP_DEV_FORTMATIC_KEY,
  PORTIS_KEY: process.env.REACT_APP_DEV_PORTIS_KEY,
  s3: {
    REGION: process.env.REACT_APP_S3_REGION,
    BUCKET: process.env.REACT_APP_S3_BUCKET,
  },
  cognito: {
    REGION: process.env.REACT_APP_COGNITO_REGION,
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  },
};

const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

export default {
  ...config,
};
