import React, { useState } from 'react';
import CardNav from 'views/movement/components/CardNav';
import MembersView from 'views/movement/components/MembersView';
import ProposalsView from 'views/movement/components/ProposalsView';
import BankView from 'views/movement/components/BankView';
import scss from 'views/movement/components/Movement.module.scss';


const Manage = ({context, openView}) => {
  const [selectedView, setSelectedView] = useState(openView||'members');

  const handleViewMembers = () => {
    setSelectedView('members');
  };

  const handleViewProposals = () => {
    setSelectedView('proposals');
  };

  const handleViewBank = () => {
    setSelectedView('bank');
  };

  const membersButtonClasses = [scss.ManageSubmenuButton];
  const proposalsButtonClasses = [scss.ManageSubmenuButton];
  const bankButtonClasses = [scss.ManageSubmenuButton];
  let viewToDisplay;
  if (selectedView === 'members') {
    membersButtonClasses.push(scss.SelectedButton);
    viewToDisplay = <MembersView context={context} />
  }
  else if (selectedView === 'proposals') {
    proposalsButtonClasses.push(scss.SelectedButton);
    viewToDisplay = <ProposalsView context={context} />
  }
  else {
    bankButtonClasses.push(scss.SelectedButton);
    viewToDisplay = <BankView context={context} />
  }

  return (
    <section className={scss.DAOCard}>
      <CardNav context={context} selected='manage' />

      <div className={scss.ManageSubmenu}>
        <hr className={scss.MenuBreak} />
        <button onClick={handleViewMembers} className={membersButtonClasses.join(' ')}>Members</button>
        <button onClick={handleViewProposals} className={proposalsButtonClasses.join(' ')}>Proposals</button>
        <button onClick={handleViewBank} className={bankButtonClasses.join(' ')}>Bank</button>
        <hr className={scss.MenuBreak} />
      </div>

      {viewToDisplay}
    </section>
  );
}

export default Manage;
