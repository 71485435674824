import React from 'react';
import ClampLines from 'react-clamp-lines';
import { getProposalStatus } from 'utils/ProposalHelper';
import ProposalDetails from 'views/movement/components/ProposalDetails';
import scss from 'views/movement/components/Movement.module.scss';
import web3 from "web3";


const ProposalSummaryCard = ({proposal, dao, displayPage}) => {
  const name = proposal.proposal_name;
  const status = getProposalStatus(proposal, dao);
  const description = web3.utils.toAscii(proposal.details);
  const votesFor = proposal.yesShares;
  const votesAgainst = proposal.noShares;

  const handleProposalClick = () => displayPage(ProposalDetails, { proposal, dao, displayPage });

  return (
    <button key={proposal._id} className={scss.ProposalCardWrapper} onClick={handleProposalClick}>
      <div className={scss.ProposalTitleRow}>
        <h3>{name}</h3>
        <h5>{!proposal.processed ? (<p>{status}</p>) : (<p>Passed: {proposal.didPass.toString()}</p>)}</h5>
      </div>
      <ClampLines
        id="proposal_description"
        text={description}
        buttons = {false}
        lines={3}
        className={scss.ProposalDescription} />
      <div className={scss.ProposalViewButton}>View</div>
      <div className={scss.ProposalVotes}>
        {votesFor} vote{votesFor !== '1' ? 's' : null} for
        | {votesAgainst} vote{votesAgainst !== '1' ? 's' : null} against
      </div>
    </button>
  );
}

export default ProposalSummaryCard;
