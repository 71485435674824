import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import scss from './NotificationService.module.scss'

const defaultOptions ={
  autoClose: false,
  pauseOnFocusLoss: true
}



export const genToast=(message, options)=> {
  const toastOptions = {...defaultOptions, ...options}

  toast(message, {
    className: scss.PeepsContainer,
    bodyClassName: scss.PeepBody,
    progressClassName: scss.ProgressBar,
    ...toastOptions
  })
}

export const errorToast =(message, options)=>{
  const toastOptions = {...defaultOptions, ...options}

  return toast(message, {
    ...toastOptions,
    className: scss.ErrorContainer,
    bodyClassName: scss.ErrorBody,
    progressClassName: scss.ProgressBar,
  })
}





export const successToast = (message, options)=>{
  const toastOptions = {...defaultOptions, ...options}

  return toast(message, {
    ...toastOptions,
    className: scss.SuccessContainer,
    bodyClassName: scss.SuccessBody,
    progressClassName: scss.ProgressBar,
  })
}

export const peepsToast = (message, options)=>{
  const toastOptions = {...defaultOptions, ...options}

  return toast(message, {
    ...toastOptions,
    className: scss.PeepsContainer,
    bodyClassName: scss.BaseBody,
    progressClassName: scss.ProgressBar,
  })
}

export const clearAllToasts=()=>{
  toast.dismiss()
}

export const loginMetamaskError = (message)=>{
  if(message) {
    errorToast(`Please log in with Metamask to ${message}`)
  }
  else {
    errorToast('Please log in with Metamask')
  }
}
