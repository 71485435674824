import React, { useEffect, useContext, useState, createRef } from "react";
import { useApolloClient, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import DAOCard from "../../components/shared/DAOCard";
import Loading from "components/shared/Loading";
import scss from "./MyProfile.module.scss";
import { get } from "../../utils/Requests";
import useMergeData from "../../hooks/useMergeData";
import { getDaoDataFromChain } from "../../utils/Helpers";
import { GET_DAOS, GET_MEMBER_MOLOCHES_PROFILE } from "../../utils/Queries";
import { clearAllToasts } from "../../utils/NotificationService";
import TokenService from "../../utils/IdleTokenService";
import { UserContext } from "contexts/UserStore";
import config from "../../config";


const Profile = (props) => {
  const [daoCards, setDaoCards] = useState();
  const [daoData, , mergeDaoData] = useMergeData((a,b) =>
    {return a.contract_address.toLowerCase() === b.moloch.id.toLowerCase()});
  const [daos, setDaos] = useState([]);
  const [myDaos, setMyDaos] = useState([]);
  const [iDAISavings, setDAISavings] = useState({});
  const [iUSDCSavings, setUSDCSavings] = useState({});
  const [iDAIBasis, setDAIBasis] = useState({});
  const [iUSDCBasis, setUSDCBasis] = useState({});
  const [iDAIPrice, setDAIPrice] = useState({});
  const [iUSDCPrice, setUSDCPrice] = useState({});
  const [myDaosChecked, setMyDaosChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const userContext = useContext(UserContext);
  const client = useApolloClient();
  const {error, data} = useQuery(GET_DAOS);
  const iDAIAddress = config.IDAI_CONTRACT_ADDRESS;
  const iUSDCAddress = config.IUSDC_CONTRACT_ADDRESS



  useEffect(() => {
    const fetchData = async () => {
      const web2Response = await get('/api/dao/all');
      const daoList = web2Response.data.map(async (dao, index)=> {
        try {
          const daoData = await getDaoDataFromChain(dao.contract_address);
          return {...daoData, ...dao};
        }
        catch (e) {
          console.log(e);
        }
      })

      return Promise.all(daoList);
  }

  fetchData()
  .then(daoList=>{
    setIsLoading(false);
    setDaos(daoList);
  })
  return ()=>{
    clearAllToasts();
  }


  }, [])

  if (userContext.id && !myDaosChecked){
    const fetchBalanceData = async()  => {
      const myDaos = await client.query({
        query: GET_MEMBER_MOLOCHES_PROFILE,
        variables:{memberAddress: userContext.id}
      });
      console.log("myDaos", myDaos)
      setMyDaos(myDaos.data);
    }
    fetchBalanceData();
    setMyDaosChecked(true);
  }

  console.log ('Dao Data', daoData)


  useEffect(() => {
    if(daoData) {
        const getSavings = () => {
          var iDAISavings = 0;
          var iUSDCSavings = 0;
          for (let i=0; i < daoData.length; i++ ) {
            if(daoData[i].idleToken === iDAIAddress) {
              iDAISavings += Number(daoData[i].iTB)
            }
            if(daoData[i].idleToken === iUSDCAddress) {
              iUSDCSavings += Number(daoData[i].iTB)
            }
          }
          console.log("myDAISavings", iDAISavings);
          console.log("myUSDCSavings", iUSDCSavings);
          setDAISavings(iDAISavings);
          setUSDCSavings(iUSDCSavings)
        }
        getSavings();
       if (iDAISavings > 0){
        const idleTokenService = new TokenService(iDAIAddress);
        const promise = idleTokenService.getIdlePrice();
        promise.then( async resp => {
          setDAIPrice(resp);
          console.log("DAIPrice", resp)
        });
       }
       if (iUSDCSavings > 0) {
        const idleTokenService = new TokenService(iUSDCAddress);
        const promise = idleTokenService.getIdlePrice();
        promise.then( async resp => {
          setUSDCPrice(resp);
          console.log("USDCPrice", resp)
        })
       }
       getBasis();
    }
  })

  const getBasis = () => {
    var iDAIBasis = 0;
    var iUSDCBasis = 0;
    for (let i=0; i < daoData.length; i++ ) {
      if(daoData[i].idleToken === iDAIAddress) {
        iDAIBasis += Number((daoData[i].iVal));
        console.log("my basis", iDAIBasis)
      }
      if(daoData[i].idleToken === iUSDCAddress) {
        iUSDCBasis += Number((daoData[i].iVal));      }
    }
    setDAIBasis(iDAIBasis);
    setUSDCBasis(iUSDCBasis);
  }

  const myDAISavings = (iDAISavings * iDAIPrice) / 10**18;
  const myUSDCSavings = (iUSDCSavings * iUSDCPrice) / 10**18;
  const myDAIEarnings = (myDAISavings - iDAIBasis) / 10**18;
  console.log("DAI Earnings", myDAIEarnings)
  const myUSDCEarnings = (myUSDCSavings - iUSDCBasis) / 10**18;



  useEffect(() => {
    let cardList;

    if (isLoading) cardList = <Loading />;
    else if (!daoData || !daoData.length) cardList = <h3 className={scss.PageSubHeader}>No Parties Joined Yet </h3>;
    else {
      cardList = daoData.map(item => {
        return (
          <div key={item.contract_address}>
            <Link className={scss.DAOLink} to={{ pathname: `/movement/${item.contract_address}` }}>
              <DAOCard
                title={item.name}
                description={item.desc}
                goal={item.goal}
                members={item.moloch.members.length}
                totalCurrency={item.guildValue || 0}
                savings={item.iTokenAmts}
                currencyType={item.tokenSymbol || 'no token'}
              />
            </Link>
          </div>
        );
      });
    }
    setDaoCards(cardList);
    return () => {
    };

  }, [daoData]);

  useEffect(() => {
    if(daos && myDaos.members) {
      mergeDaoData(daos, myDaos.members);
    }

    return () => {

    };
  // eslint-disable-next-line
  }, [data, daos]);


  const ref = createRef();

  return (
    <div className={scss.MainContent}>
      <div className={scss.HeaderSection}>
        <h1 className={scss.PageHeader}>My Profile</h1>
        <h3 className={scss.PageSubHeader}>{userContext.id}</h3>
      </div>
      <div className={scss.UserStatsContainer}>
        <div className={scss.UserStat}>
          {myDAISavings ? Number(myDAISavings / 10**18).toFixed(4) : '0'} DAI<br />
          {myUSDCSavings ? Number(myUSDCSavings / 10**18).toFixed(4) : '0'} USDC<br />
          <span className={scss.StatLabel}>Total Saved</span>
        </div>
        <div className={scss.UserStat}>
          {myDAISavings ? Number(myDAIEarnings).toFixed(4) : '0'} DAI<br />
          {myUSDCSavings ? Number(myUSDCEarnings).toFixed(4) : '0'} USDC<br />
          <span className={scss.StatLabel}>Total Current Earnings</span>
        </div>
      </div>

      <div className={scss.DAOsContainer} ref={ref}>
        {error ? error : null}
        {daoCards}
      </div>
    </div>
  );
}

export default Profile;
