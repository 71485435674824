import FactoryAbi from '../contracts/PartyStarter.json';
import WEthFactoryAbi from '../contracts/WETHPartyStarter.json';
import NOFIFactoryAbi from '../contracts/NOFIPartyStarter.json';

import config from '../config';
import Web3 from 'web3'

export default class FactoryService {

    constructor(userAccount, factory) {
      switch (factory) {
        case 'WETH':
          // TODO FOR BILL: set this.factoryAddr and this.FactoryAbi to point to new address/ABI here
          this.factoryAddr = config.WETH_FACTORY_ADDRESS;
          this.FactoryAbi = WEthFactoryAbi;
          break;  
        case 'PAN':
          // TODO FOR BILL: set this.factoryAddr and this.FactoryAbi to point to new address/ABI here
          this.factoryAddr = config.NOFI_FACTORY_ADDRESS;
          this.FactoryAbi = NOFIFactoryAbi;
          break;  
        // case 'AAVE_A': // list all of the aave tokens like this (just replace these 'AAVE_A', etc strings with the TOKEN name and a line for each token)
        // case 'AAVE_B':
        // case 'AAVE_C, ETC...':
        //   // TODO FOR BILL: set this.factoryAddr and this.FactoryAbi to point to new address/ABI here
        //   // this.factoryAddr = config.AAVE_FACTORY_ADDRESS;
        //   // this.FactoryAbi = AaveFactoryAbi;
        //   break;
        default:
          this.factoryAddr = config.FACTORY_ADDRESS;
          this.FactoryAbi = FactoryAbi;
      }

      this.web3 = new Web3(new Web3.providers.HttpProvider(config.INFURA_URI));
      if(window.ethereum){
          window.ethereum.enable()
          this.web3 = new Web3(window.ethereum);

          this.account = userAccount

          this.initContract();
      }
    };

    async initContract() {

      this.contract = new this.web3.eth.Contract(this.FactoryAbi, this.factoryAddr)
      console.log("init factory", this.factoryAddr)

    }


    async getAllEvents() {
      if (!this.contract) {
        await this.initContract();
      }
      let events = await this.contract.getPastEvents('allEvents', {
        fromBlock: 0,
        toBlock: 'latest',
      });
      return events;
    }

    async getParties() {
      if (!this.contract) {
        await this.initContract();
      }
      let poolparties = await this.contract.party().call();
      return poolparties;
    }

 /************************************CreateDAO*****************************/

    async startParty(
      _founders,
      _approvedTokens,
      _daoFees,
      _periodDuration,
      _votingPeriodLength,
      _gracePeriodLength,
      _proposalDepositReward,
      _depositRate,
      _partyGoal,
      _dilutionBound,
      encodedPayload = false
    ) {
      console.log('initializing contract')
console.log(  'args',
  { _founders,
    _approvedTokens,
    _daoFees,
    _periodDuration,
    _votingPeriodLength,
    _gracePeriodLength,
    _proposalDepositReward,
    _depositRate,
    _partyGoal,
    _dilutionBound,
  encodedPayload})

      if (!this.contract) {
        await this.initContract();
      }
      if (encodedPayload) {
        const data = this.contract.methods
          .startParty (
            _founders,
            _approvedTokens,
            _daoFees,
            _periodDuration,
            _votingPeriodLength,
            _gracePeriodLength,
            _proposalDepositReward,
            _depositRate,
            _partyGoal,
            _dilutionBound)
          .encodeABI();
        return data;
      }

      let party = this.contract.methods
        .startParty(
            _founders,
            _approvedTokens,
            _daoFees,
            _periodDuration,
            _votingPeriodLength,
            _gracePeriodLength,
            _proposalDepositReward,
            _depositRate,
            _partyGoal,
            _dilutionBound)
        .send({ from: this.account })
        .once('transactionHash', (txHash) => {
          console.log('transactionhash', txHash)
        })
        .then((resp) => {
          console.log(resp)
          return resp;
        })
        .catch((err) => {
          console.log(err);
          return { error: 'rejected transaction' };
        });
      return party;
    }
}
