export const updateProposals = (proposals, update) => {
  // proposals is an array of proposals, update is a proposal with updated information
  const tempProposals = proposals;

  let index = tempProposals.findIndex(m => m.proposalIndex === update.proposalIndex);
  if (index === -1) index = tempProposals.findIndex(m => {
    return m.proposalId === update.proposalId;
  });
  if (index >= 0) {

    tempProposals[index] = { ...tempProposals[index], ...update };

    return tempProposals;
  } else {
    // TODO make sure that the updated array is sorted properly
    return [ ...proposals, update];
  }
};
