import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from 'contexts/UserStore';
import scss from 'views/movement/components/Movement.module.scss';

import {fromBase, toBase} from "../../../utils/Helpers";
import McDaoService from "../../../utils/McDaoService";
import TokenService from "../../../utils/IdleTokenService";
import web3 from "web3";

import axios from 'axios';


const BankView = ({context}) => {
  const {
    dao,
    members,
    setProcessingBackgroundState,
    setModalMessage,
    setModalTitle,
    fetchGraphData,
    fetchMembers
  } = useContext(context);
  const user = useContext(UserContext);
  const [iPrice, setiPrice] = useState({});
  const [displayView, setDisplayView] = useState('pool');
  const [tokensList, setTokensList] = useState([]);
  const [tokenAddresses, setTokenAddresses] = useState();
  const [tokenPrices, setTokenPrices] = useState({});


  useEffect(() => {
    if (dao && dao.idleToken && dao.idleToken.tokenAddress) {
      getIdlePrice();
    }
  }, [dao]);

  const getIdlePrice = async () => {
    const token = new TokenService(dao.idleToken.tokenAddress);
    const idlePrice = await token.getIdlePrice();
    if (idlePrice) {
      setiPrice(idlePrice);
    }
  }

  useEffect(() => {
    if (tokenAddresses && tokenAddresses.length) {
      const apiUrl = 'https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses='+ [...tokenAddresses] + "&vs_currencies=usd";
      axios.get(apiUrl).then((prices) => {
        const allPrices = prices.data;
        setTokenPrices(allPrices);
      });
    }
  }, [tokenAddresses]);


  const handleWithdrawal = async(tokenAddress) => {
    const mcDao = new McDaoService(dao.contract_address, dao.depositToken.tokenAddress, user.id);
    const address = tokenAddress.toString();
    const token = address.toLowerCase();
    const amount = await mcDao.getUserTokenBalance(user.id, token);

     if (amount > 0) {
       setProcessingBackgroundState('awaiting_withdraw_balance');

       await mcDao.withdrawBalance(
         token,
         amount
       ).then(async resp => {
         if (resp.events) {
           setProcessingBackgroundState('modal_open');
           setModalMessage(`Your tokens from the "${dao.name}" party will now be in your wallet`);
           setModalTitle(`Get that skrilla`);
           fetchGraphData();
           fetchMembers();
         } else {
           setProcessingBackgroundState('');
           console.error(resp.errors);
         }
       });
     }
  }

  const poolButtonClasses = [scss.SelectorButton];
  const meButtonClasses = [scss.SelectorButton];
  if (displayView === 'pool') poolButtonClasses.push(scss.ActiveSelectorButton);
  else meButtonClasses.push(scss.ActiveSelectorButton);


  useEffect(() => {
    if (dao && dao.tokenBalances && displayView === 'pool') {

      let tokensList = dao.tokenBalances.filter(token => token.guildBank);
      if (!tokenAddresses) setTokenAddresses(tokensList.map(token => token.token.tokenAddress));

      tokensList = tokensList.map(token => {
        const address = token.token.tokenAddress;
        let tokenPrice = tokenPrices[address] && tokenPrices[address]['usd'] ? tokenPrices[address]['usd'] : null;
        if (dao.idleToken && dao.idleToken.tokenAddress === address) tokenPrice = iPrice / 10**18;

        return (
          <div className={scss.TokenRow} key={address}>
            <div className={scss.TokenName}>{token.token.symbol}</div><br />
            <div className={scss.TokenAddress}>{address}</div>
            <div className={scss.TokenAmt}>{Number(fromBase(token.tokenBalance, token.token.decimals)).toFixed(8)}</div>
            <div className={scss.TokenValue}>{tokenPrice ? `$${Number(tokenPrice).toFixed(4)}` : 'N/A'}</div>
            <div className={scss.TokenTotalValue}>{tokenPrice ? `$${Number(token.tokenBalance * tokenPrice / 10**18).toFixed(4)}` : 'N/A'}</div>
          </div>
        );
      });
      setTokensList(tokensList);
    }

    if (user && user.id && members && displayView === 'me') {
      console.log("members", members)
      const memberData = members.find(member => member.memberAddress.toLowerCase() === user.id.toLowerCase());
      let tokensList;
      console.log("memberData", memberData)
      if (memberData && memberData.tokenBalances) {
        tokensList = memberData.tokenBalances.filter(token => token);
      
      }
      else {
        tokensList = dao.tokenBalances.filter(token => token.guildBank);
      }
      if (!tokenAddresses) setTokenAddresses(tokensList.map(token => token.token.tokenAddress));
      console.log('tokenlist', tokensList)
      tokensList = tokensList.map(token => {
        const address = token.token.tokenAddress;
        let tokenPrice = tokenPrices[address] && tokenPrices[address]['usd'] ? tokenPrices[address]['usd'] : null;
        if (dao.idleToken && dao.idleToken.tokenAddress === address) tokenPrice = iPrice / 10**18;
       
        return (
          <div className={scss.TokenRow} key={address}>
            <div className={scss.TokenName}>{token.token.symbol}</div><br />
            <div className={scss.TokenAddress}>{address}</div>
            <div className={scss.TokenAmt}>{memberData ? Number(fromBase(token.tokenBalance, token.token.decimals)).toFixed(8) : '0'}</div>
            <div className={scss.TokenValue}>{tokenPrice ? `$${Number(tokenPrice).toFixed(4)}` : 'N/A'}</div>
            <div className={scss.TokenTotalValue}>{tokenPrice ? `$${Number(token.tokenBalance * tokenPrice / 10**18).toFixed(8)}` : 'N/A'}</div>
            {displayView === 'me' && memberData ? <>
              <br/>
              <div className={scss.Withdraw}>
                  <button className={scss.WithdrawalButton} onClick={() => handleWithdrawal(address)}>
                    withdraw
                  </button>
              </div>
           </>: null}

          </div>
        )
      });
      setTokensList(tokensList);
    }
  }, [dao, user, displayView, tokenPrices]);


  return (<>
    <div className={scss.BankTypeSelector}>
      <div className={poolButtonClasses.join(' ')} onClick={() => setDisplayView('pool')}>Pool</div>
      <div className={meButtonClasses.join(' ')} onClick={() => setDisplayView('me')}>Me</div>
    </div>
    <div className={scss.TokenTitleRow}>
      <div className={scss.TokenNameTitle}>Token</div>
      <div className={scss.TokenAmtTitle}>Amt</div>
      <div className={scss.TokenValueTitle}>Price</div>
      <div className={scss.TokenTotalValueTitle}>Total Value</div>
    </div>
    {tokensList}
  </>);
}

export default BankView;
