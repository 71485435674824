import React, { useContext, useEffect, useState } from 'react';
import scss from 'views/movement/components/Movement.module.scss';

import { useFormik } from 'formik';
import { UserContext } from 'contexts/UserStore';
import { post } from 'utils/Requests';

import Input from 'components/shared/InputFields/Input';
import IconButton from 'components/shared/IconButton';
import * as Yup from 'yup';

import Manage from 'views/movement/components/Manage';
import ChooseTypeOfProposal from 'views/movement/components/ChooseTypeOfProposal';
import CreateMemberProposal from 'views/movement/components/CreateMemberProposal';
import CreateSpendProposal from 'views/movement/components/CreateSpendProposal';
import CreateSettingsProposal from 'views/movement/components/CreateSettingsProposal';
import CreateActionsProposal from 'views/movement/components/CreateActionsProposal';

import McDaoService from 'utils/McDaoService';
import TokenService from 'utils/TokenService';
import { sendEmail } from 'utils/EmailService';
import {toBase} from 'utils/Helpers';

import web3 from 'web3';
import { errorToast, clearAllToasts } from '../../../utils/NotificationService';
import ProposalsView from './ProposalsView';

const CreateProposal = ({ context }) => {
  const userContext = useContext(UserContext);
  const {
    dao,
    displayPage,
    proposals,
    setProposalData,
    setProcessingBackgroundState,
    processingBackgroundState,
    setModalMessage,
    setModalTitle,
    fetchGraphData
  } = useContext(context);
  const [tokensList, setTokensList] = useState();
  const [allowance, setAllowance] = useState(false);


  const SCREEN_INDEX = {
    CHOOSE_TYPE: 0,
    MEMBER: 1,
    SPEND: 2,
    SETTINGS: 3,
    ACTIONS: 4
  };
  const [screenIndex, setScreenIndex] = useState(SCREEN_INDEX.CHOOSE_TYPE);

  useEffect(() => {
    if (dao && dao.tokenBalances && dao.tokenBalances.length > 0) {
      const filteredTokensList = dao.tokenBalances.filter(token => token.guildBank);
      const currencyOptions = [{value: '', displayValue: ''}];
      currencyOptions.push(...filteredTokensList.map(tokenItem =>
        ({value: tokenItem.token.tokenAddress, displayValue: tokenItem.token.symbol})));
      setTokensList(currencyOptions);
    }
  }, [dao]);

  const getValidationShape = screenIndex => {
    switch (screenIndex) {
      case SCREEN_INDEX.MEMBER:
      case SCREEN_INDEX.SPEND:
      case SCREEN_INDEX.SETTINGS:
        return {
          proposal_name: Yup.string().required("Required"),
          details: Yup.string().required("Required"),
          link: Yup.string()
        };
      case SCREEN_INDEX.ACTIONS:
        return {
          email: Yup.string().email().required("Required")
        };
      default:
        return {};
    }
  }
  const [validationSchema, setValidationSchema] = useState(Yup.object().shape({}));
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    clearAllToasts();
  }, [screenIndex]);

  const formik = useFormik({
    initialValues: {},
    isInitialValid: false,
    validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  const formPages = [
    <ChooseTypeOfProposal
      setScreenIndex={setScreenIndex}
      SCREEN_INDEX={SCREEN_INDEX} />,
    <CreateMemberProposal
      formik={formik}
      tokenAddress={dao.depositToken.tokenAddress}
      setValidationSchema={setValidationSchema}
      currencyOptions={tokensList} />,
    <CreateSpendProposal
      formik={formik}
      tokenAddress={dao.depositToken.tokenAddress}
      setValidationSchema={setValidationSchema}
      currencyOptions={tokensList} />,
    <CreateSettingsProposal
      formik={formik}
      setValidationSchema={setValidationSchema} />,
    <CreateActionsProposal
      formik={formik}
      setValidationSchema={setValidationSchema} />
  ];

   const handleApproval = async values => {
    setProcessingBackgroundState('awaiting_approve_deposit');
    const tokenService = new McDaoService(dao.contract_address, values.tributeToken, userContext.id);
    const bigAmt = 1000000;
    const amount = toBase(bigAmt, dao.depositToken.decimals);
    const promise = tokenService.approveDeposit(dao.contract_address, amount);
    promise
      .then(resp => {
        setProcessingBackgroundState('');
        //hasAllowance();
        return resp;
      });
  }

  const handleSubmit = values => {
    if (screenIndex === SCREEN_INDEX.ACTIONS) {
      handleActionsInfoRequest(values);
    }
    else {
      console.log("values", values)
      const promise = handleApproval(values);
      promise.then(async resp => {
        if (resp) {
          submitDaoProposal(values);
        } else {
          console.log("err")
        }
      }) 
    }
  };

  const handleActionsInfoRequest = async values => {
    setProcessingBackgroundState('processing_proposal');
    const openView = 'proposals';
    displayPage(Manage, {openView});
    sendEmail('ACTIONS INFO REQUESTED', `user email: ${values.email}`, 'peepsbot@peepsdemocracy.com')
    .then(() => {
      setProcessingBackgroundState('modal_open');
      setModalTitle(`We'll be in touch`);
      setModalMessage(`We will contact you at ${values.email} with updates on actions proposals`);
    });
  }

  const getFlagNumber = screenIndex => {
    switch (screenIndex) {
      case SCREEN_INDEX.MEMBER:
        return 6;
      case SCREEN_INDEX.SPEND:
        return 5;
      case SCREEN_INDEX.SETTINGS:
        return 7;
      case SCREEN_INDEX.ACTIONS:
        return 99;
      default:
        return -1;
    }
  }

  const submitDaoProposal = async values => {
    const mcDao = new McDaoService(dao.contract_address, dao.depositToken.tokenAddress, userContext.id);

    const flag = getFlagNumber(screenIndex);

    const applicant = values.applicant || userContext.id;
    const sharesRequested = values.sharesRequested || 0;
    const lootRequested = values.lootRequested || 0;
    const tributeToken = values.tributeToken || dao.depositToken.tokenAddress;

    const tributeService = new TokenService(tributeToken, userContext.id);
    const tributeDecimals = await tributeService.getDecimals();
    const tributeOffered = values.tributeOffered ? (toBase(values.tributeOffered, tributeDecimals)) : "0";

    const paymentToken = values.paymentToken || dao.idleToken.tokenAddress;
    const paymentService = new TokenService(paymentToken);
    const paymentDecimals = await paymentService.getDecimals();
    const paymentRequested = values.paymentRequested ? toBase(values.paymentRequested, paymentDecimals) : "0";

    const rawDeets = values.details ? values.details.toString() : '';
    const cutDeets = rawDeets.slice(0, 32);
    // stored on chain
    const details = web3.utils.fromAscii(cutDeets);
    // XCJP TODO: If it's settings prop, make sure there is a change before trying to submit
    setProcessingBackgroundState('processing_proposal');
    const openView = 'proposals';
    displayPage(Manage, {openView});
    if (flag === 5 || flag === 6 || flag === 7) {
      console.log("submission", applicant, tributeOffered, sharesRequested, lootRequested, paymentRequested, flag, tributeToken, paymentToken);


      await mcDao.submitProposal(
        applicant,
        tributeOffered,
        sharesRequested,
        lootRequested,
        paymentRequested,
        flag,
        tributeToken,
        paymentToken,
        details
        ).then(async resp => {
          if (resp.events) {
            // creating a web2
            const res = await post("/api/proposal/create", {
              id:
                resp.events.SubmitProposal.address +
                "-" +
                resp.events.SubmitProposal.returnValues.proposalId,
              proposal_id: resp.events.SubmitProposal.returnValues.proposalId,
              contract_address: resp.events.SubmitProposal.address,
              creator_id: resp.events.SubmitProposal.returnValues.applicant,
              proposal_name: values.proposal_name,
              link: values.link,
              token: dao.tokenSymbol,
              status: "awaiting_sponsor",
            });

            await setProposalData([
              ...proposals,
              { ...res.data, ...resp.events.SubmitProposal.returnValues }
            ]);

            setProcessingBackgroundState('modal_open');
            setModalMessage(`You just submitted a new proposal: "${values.proposal_name}"`);
            setModalTitle(`Cannonball!`);
            fetchGraphData();
          } else {
            errorToast("There has been an error processing your request");
            setProcessingBackgroundState('');
          }
        });
    }
  };

  const handleCloseClick = () => {
    const openView = 'proposals';
    displayPage(Manage, {openView});
  }

  const [addMemberClasses, setAddMemberClasses] = useState([scss.SelectorButton, scss.ActiveSelectorButton]);
  const [spendClasses, setSpendClasses] = useState([scss.SelectorButton]);

  useEffect(() => {
    const addMemberClasses = [scss.SelectorButton];
    const spendClasses = [scss.SelectorButton];
    if (screenIndex === SCREEN_INDEX.CHOOSE_TYPE) addMemberClasses.push(scss.ActiveSelectorButton);
    else spendClasses.push(scss.ActiveSelectorButton);

    setAddMemberClasses(addMemberClasses);
    setSpendClasses(spendClasses);
  }, [screenIndex]);

  const handlePostClicked = () => {
    validationSchema
    .validate(formik.values)
    .then()
    .catch(e => {
      console.error(e);
      if (e.message) setErrorMessage(e.message);
    });
  }

  const modalForegroundClasses = [scss.ModalForeground];
  if (processingBackgroundState === 'awaiting_approve_transaction') {
    modalForegroundClasses.push(scss.ModalActive);
  }

  return (<>
    <div className={modalForegroundClasses.join(' ')}>
      <div className={scss.ModalHelpText}>Confirm transaction to continue</div>
    </div>
    <div className={`${scss.DAOCard} ${scss.CreateProposalWrapper}`}>
      <IconButton className={scss.CreateCloseButton} onClick={handleCloseClick}>X</IconButton>
      <h1 className={scss.CreateHeader}>Create a Proposal</h1>

      <form onSubmit={formik.handleSubmit}>
        {formPages[screenIndex]}

        <div className={scss.ErrorMessage}>{errorMessage}</div>

        {screenIndex !== SCREEN_INDEX.CHOOSE_TYPE ? <div>
          <button
            type='submit'
            className={scss.MainProposalButton}
            onClick={handlePostClicked}>
            Post
          </button>
        </div> : null}
      </form>
    </div>
  </>);
};


export default CreateProposal;