import React, { useContext, useEffect, useState, useCallback } from "react";
import scss from "../CreateDAO.module.scss";
import { UserContext } from "../../../contexts/UserStore";
import { FormContext } from "../CreateDAO";
import Input from "../../../components/shared/InputFields/Input";
import * as Yup from 'yup';
import web3 from "web3";
import CSVReader from 'react-csv-reader';


export default function InvitePage() {

  const { formik, setFormIndex, setValidationSchema } = useContext(FormContext);

  const [formSchema, setFormSchema] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [peepsList, setPeepsList] = useState([]);

  const updateInviteValidationSchema = useCallback(numberOfInvites => {
    const peepFieldList = {};
    for (let i = 1; i <= numberOfInvites; i++) {
      const peepFieldKey = `peep${i}`;
      peepFieldList[peepFieldKey] = Yup.string().test(
        'isEthAddress',
        'Invalid wallet address',
        function(value) {
          if (!value) return true;
          return isWalletAddress(value)
        }
      );
    }
    const updatedValidationSchema = Yup.object().shape({...peepFieldList});
    setValidationSchema(updatedValidationSchema);
    setFormSchema(updatedValidationSchema);
  }, [setValidationSchema, setFormSchema]);

  const isWalletAddress = address => web3.utils.isAddress(address.trim());

  useEffect(() => {
    updatePeepsListFields();
  }, []);

  const updatePeepsListFields = () => {
    const initListLength = Math.max(4, getNumberOfPeepsAdded());

    updateInviteValidationSchema(initListLength);
    const initPeepsList = [];
    for (let i = 1; i <= initListLength; i++) {
      initPeepsList.push(<Input
        key={`peep${i}`}
        type={"text"}
        handleChange={formik.handleChange}
        name={`peep${i}`}
        labelText={`Peep ${i}`}
        value={formik.values[`peep${i}`]}/>
      );
    }

    setPeepsList(initPeepsList);
  }

  const getNumberOfPeepsAdded = () => {
    const peepsArray = Object.keys(formik.values).filter(key => key.startsWith('peep'));
    return peepsArray.length;
  }

  const handleAddPeep = () => {
    const newPeepIndex = peepsList.length + 1;
    const updatedPeepsList = [...peepsList];
    updatedPeepsList.push(<Input
      key={`peep${newPeepIndex}`}
      type={"text"}
      handleChange={formik.handleChange}
      name={`peep${newPeepIndex}`}
      labelText={`Peep ${newPeepIndex}`}
      value={formik.values[`peep${newPeepIndex}`]}/>
    );
    setPeepsList(updatedPeepsList);

    updateInviteValidationSchema(newPeepIndex);
  }

  const handleCsvUpload = (data, fileInfo) => {
    setPeepsList([]);
    const peepsToAdd = [];
    data.forEach((line, index) => {
      line.forEach((item, index) => {
        if (isWalletAddress(item)) peepsToAdd.push(item.trim());
      });
    });

    if (peepsToAdd.length) {
      const currentPeepsIndex = getNumberOfPeepsAdded() + 1;
      let peepsToAddIndex = 0;
      for (let i = currentPeepsIndex; i < currentPeepsIndex + peepsToAdd.length; i++) {
        formik.values[`peep${i}`] = peepsToAdd[peepsToAddIndex];
        peepsToAddIndex++;
      }
      updatePeepsListFields();
    }
  }


  return (
    <React.Fragment>
      <h3 className={scss.PageSubHeader}>LVL 4. Invite Peeps (optional)</h3>

      <div className={scss.CreateInputFields}>
        {peepsList}
        <div className={scss.AddPeepsButtonsWrapper}>
          <button type="button" onClick={handleAddPeep} className={scss.CreateTextButton}>
            add another peep
          </button>
          <CSVReader
            parserOptions={{ header: false }}
            onFileLoaded={(data, fileInfo) => handleCsvUpload(data, fileInfo)}
            cssInputClass={scss.CsvReaderInput}
            cssLabelClass={scss.CreateTextButton}
            label={"upload CSV list"}
          />
        </div>

        <div className={scss.ErrorMessage}>{errorMessage}</div>

        <div className={scss.ButtonRow}>
          <button
            type='button'
            className={scss.SecondaryButton}
            onClick={() => setFormIndex(2)}>
            Back
          </button>
          <button
            type='submit'
            className={scss.MainButton}
            onClick={() => {
              formSchema
                .validate(formik.values)
                .then()
                .catch(e => {
                  let error = 'Something went wrong... Check your wallet addresses';
                  if (e && e.path) {
                    error = `Peep ${e.path.slice(-1)} has invalid wallet address`;
                  }
                  setErrorMessage(error);
                });
            }}>
            Build Pool
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
