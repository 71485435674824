import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { isWalletAddress } from 'utils/Helpers';
import Input from 'components/shared/InputFields/Input';
import scss from 'views/movement/components/Movement.module.scss';

const CreateSpendProposal = ({formik, tokenAddress, setValidationSchema, currencyOptions}) => {
  const [isHardMode, setIsHardMode] = useState(false);

  useEffect(() => {
    setValidationSchema(Yup.object().shape({
      proposal_name: Yup.string().required("Name is required"),
      paymentRequested: Yup.string().required("Amount Requested is required"),
      details: Yup.string().required("Details are required"),
      link: Yup.string(),
      applicant: Yup.string().test(
        'isEthAddress',
        'Invalid recipient wallet address',
        function(value) {
          if (!value) return true;
          return isWalletAddress(value)
        }
      ),
      lootRequested: Yup.string().matches(/^\d*$/, "Shares Requested must be a number"),
      paymentToken: Yup.string().test(
        'isEthAddress',
        'Invalid Payment Token address',
        function(value) {
          if (!value) return true;
          return isWalletAddress(value)
        }
      ),
      sharesRequested: Yup.string().matches(/^\d*$/, "Shares Requested must be a number")
    }));
  }, []);

  useEffect(() => {
    clearHardValues();
  }, [isHardMode]);

  const clearHardValues = () => {
    formik.setFieldValue('applicant', '');
    formik.setFieldValue('lootRequested', '');
    formik.setFieldValue('paymentToken', '');
    formik.setFieldValue('sharesRequested', '');
  }

  return (
    <>
      <Input
        type={"text"}
        handleChange={formik.handleChange}
        labelText={"Name"}
        name={"proposal_name"}
        value={formik.values.proposal_name}
        additionalClasses={scss.DarkLabel}
      />
      <Input
        type={"number"}
        handleChange={formik.handleChange}
        placeholder="How much do you need?"
        labelText={"Amount Requested"}
        name={"paymentRequested"}
        value={formik.values.paymentRequested}
        additionalClasses={scss.DarkLabel}
      />
      <Input
        type={"textarea"}
        name="details"
        labelText="Details"
        placeholder="Keep to 32 characters"
        value={formik.values.details}
        handleChange={formik.handleChange}
        additionalClasses={scss.DarkLabel}
      />
      <Input
        type={"text"}
        handleChange={formik.handleChange}
        labelText={"Link to Additional Info"}
        placeholder="Links to invoices, plans, etc..."
        name={"link"}
        value={formik.values.link}
        additionalClasses={scss.DarkLabel}
      />

      <button type="button"
        onClick={() => setIsHardMode(true)}
        className={isHardMode ? scss.HiddenFields : scss.TextButton}>
        hard mode
      </button>

      <div className={isHardMode ? '' : scss.HiddenFields}>
        <Input
          type={"text"}
          name={"applicant"}
          placeholder="Default: your address"
          labelText="Who's getting these funds?"
          value={formik.values.applicant}
          handleChange={formik.handleChange}
          additionalClasses={scss.DarkLabel}
        />
        <Input
          type={"number"}
          handleChange={formik.handleChange}
          labelText={"Non-Voting Shares Requested"}
          placeholder="Default: 0"
          name={"lootRequested"}
          value={formik.values.lootRequested}
          additionalClasses={scss.DarkLabel}
        />
        <Input
          type={"select"}
          options={currencyOptions}
          name={"paymentToken"}
          labelText="Payment Token (i.e. what currency do you want)"
          value={formik.values.paymentToken}
          handleChange={formik.handleChange}
          additionalClasses={scss.DarkLabel}
        />
        <Input
          type={"number"}
          handleChange={formik.handleChange}
          labelText={"Voting Shares Requested"}
          placeholder="Default: 0"
          name={"sharesRequested"}
          value={formik.values.sharesRequested}
          additionalClasses={scss.DarkLabel}
        />

        <button type="button"
          onClick={() => setIsHardMode(false)}
          className={scss.TextButton}>
          easy mode
        </button>
      </div>
    </>
  );
}

export default CreateSpendProposal;
