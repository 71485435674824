import React, { useEffect } from 'react';
import * as Yup from 'yup';
import Input from 'components/shared/InputFields/Input';
import scss from 'views/movement/components/Movement.module.scss';

const CreateActionsProposal = ({formik, setValidationSchema}) => {
  useEffect(() => {
    setValidationSchema(Yup.object().shape({
      email: Yup.string().email().required("Email Address is required")
    }));
  }, []);

  return (
    <>
      <div className={scss.ActionComingSoonText}>
        Actions Coming Soon
      </div>
      <Input
        type={"email"}
        handleChange={formik.handleChange}
        labelText={"Email Address"}
        name={"email"}
        placeholder="Update me when this feature is out"
        value={formik.values.email}
        additionalClasses={scss.DarkLabel}
      />
    </>
  );
}

export default CreateActionsProposal;
