import React, { useState, useEffect, createContext } from 'react';
import { getAccountInfo, getUserAccount } from '../utils/UserService';

import config from 'config';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Fortmatic from "fortmatic";
//import Portis from "@portis/web3";


const providerOptions = {
  fortmatic: {
    package: Fortmatic,
    options: {
      key: config.FORTMATIC_KEY
    }
  }
  , 
  walletconnect: {
    display: {
      name: "Wallet Connect"
    },
    package: WalletConnectProvider,
    options: {
      infuraId: config.INFURA_URI // required
    }
  }
};

const web3Modal = new Web3Modal({
  network: config.SDK_ENV.toLowerCase(),
  cacheProvider: true,
  providerOptions: providerOptions,
  theme: "dark"
});

export const UserContext = createContext(null);

const UserStore = ({children}) => {
  const [user, setUser] = useState({});

  const fetchUserData = async () => {
    const provider = await web3Modal.connect();
    const account = await getUserAccount(provider);
    localStorage.setItem('metamaskAccount', account);
    const userData = await getAccountInfo(provider); // TODO: This calls getUserAccount again unnecessarily; should resuse result instead
    setUser(userData ? {...userData} : undefined);
  }

  useEffect(() => {
    fetchUserData();
    return () => {

    };
  }, []);

  return (
    <UserContext.Provider value={{...user, fetchUserData}} >
      {children}
    </UserContext.Provider>
  );

}

export default UserStore;
