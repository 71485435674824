import axios from 'axios'
import { getAdmins } from './Helpers'

export const sendEmail = async (subject, content, destination) => {
  const res = await axios.post('/email/send',
    {
      "personalizations": [
        {
          "to": [
            {
              "email": `${destination}`
            }
          ],
          "subject": `${subject}`
        }
      ],
      "from": {
        "email": "peepsbot@peepsdemocracy.com"
      },
      "content": [
        {
          "type": "text/plain",
          "value": `${content}`
        }
      ]
    }

  )
  return res;
}
