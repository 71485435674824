import React from 'react';

import { Link } from 'react-router-dom';

const FourOhFour = () => (
  <div>
    Not all who wander are lost...but you are. <Link to="/">Click here to find your way home.</Link>
  </div>
);

export default FourOhFour;
