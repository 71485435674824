import React from "react";
import scss from "./Input.module.scss";


export default function Input({
  handleChange,
  name,
  value,
  labelText,
  placeholder,
  type,
  inputType,
  min,
  max,
  style,
  additionalClasses,
  options
})
{
  if(!type) type='text';

  let inputClasses = [scss.InputContainer];
  if (additionalClasses) {
    inputClasses.push(additionalClasses);
  }

  let inputField;
  if (type === 'textarea') {
  inputField = <textarea
    id={name}
    placeholder={placeholder}
    name={name}
    value={value}
    onChange={handleChange}
    className={`${scss.InputRounded} ${scss.InputLarge} ${scss.TextArea}`}
    style={style ? style : {}}
  />;
  }
  else if (type === 'select') {
    inputField = <select
      id={name}
      placeholder={placeholder}
      name={name}
      className={`${scss.InputRounded} ${scss.InputLarge}`}
      value={value}
      onChange={handleChange}
      style={style ? style : {}}
    >
      {options.map(option => (
        <option key={option.value} value={option.value}>{option.displayValue}</option>
      ))}
    </select>;
  }
  else {
    inputField = <input
    id={name}
    type={type}
    placeholder={placeholder}
    name={name}
    value={value}
    onChange={handleChange}
    className={`${scss.InputRounded} ${scss.InputLarge}`}
    style={style ? style : {}}
    />;
  }

  return (
    <div className={inputClasses.join(' ')}>
      <label htmlFor={name} className={scss.InputLabel}>{labelText}</label>
      {inputField}
    </div>
  );
}
