import Web3Service from '../utils/Web3Service';
import IInterfaceAbi from '../contracts/idleInterface.json';
import web3 from 'web3'


export default class IdleTokenService {
  contractAddr;
  web3Service;
  contract;
  abi;

  constructor(idleToken, userAccount) {
    this.web3Service = new Web3Service();
    this.abi = IInterfaceAbi;
    this.contractAddr = idleToken;
    this.account = userAccount

    this.initContract();
  }

  async initContract() {
    this.contract = await this.web3Service.initContract(
      this.abi,
      this.contractAddr,
    );
    return this.contract;
  }

  async getAvgAPR() {
    if (!this.contract) {
      await this.initContract();
    }

    const avgApr = await this.contract.methods.getAvgAPR().call();
    return avgApr;
  }

  async getIdlePrice() {
    if (!this.contract) {
      await this.initContract();
    }

    const itokenPrice = await this.contract.methods.tokenPrice().call();
    return itokenPrice;
  } 

  async getGovTokens() {
    if (!this.contract) {
      await this.initContract();
    }

    const govTokens = await this.contract.methods.govTokens().call();
    return govTokens;
  } 

  async getGovTokenAmt(addr) {
    if (!this.contract) {
      await this.initContract();
    }
    
    const govTokenAmt = await this.contract.methods.getGovTokensAmounts(addr).call();
    return govTokenAmt;
  } 

} 