import axios from "axios";

export const BaseUrl = () => {
  return process.env.REACT_APP_API_HOST;
};

export const getById = async (endpoint, id) => {
  try {
    const response = await axios.get(endpoint, {
      params: {
        id: id
      }
    });

    return response;
  } catch (e) {
    console.log(e);
  }
};

export const getWithParams = async (endpoint, params)=>{
  try {
    return axios.get(endpoint, {params})
  }
  catch(e) {
    throw e
  }
}



export const get = async endpoint => {
  try {
    return await axios({
      method: "get",
      url: endpoint,
      headers: { "Content-Type": "application/json" }
    });
  } catch (err) {
    throw new Error(err);
  }
};

export const post = async (endpoint, payload) => {
  const data = JSON.stringify(payload);
  try {
    return axios({
      method: "post",
      url: `${endpoint}`,
      headers: { "Content-Type": "application/json" },
      data: data
    });
  } catch (err) {
    return err.response;
  }
};

export const put = async (endpoint, payload) => {
  try {
    return axios({
      method: "put",
      url: `${endpoint}`,
      headers: { "Content-Type": "application/json" },
      data: payload ? payload.data : null,
      params: payload ? payload.params : null
    });
  } catch (err) {
    throw new Error(err);
  }
};

export const remove = async (endpoint, id) => {
  try {
    return axios({
      method: "delete",
      url: `${endpoint}`,
      params: {id: id}
    });  } catch (err) {
    throw new Error(err);
  }
};
