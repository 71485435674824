import React, { useEffect, useState, useContext, useRef } from "react";
import { useApolloClient } from "@apollo/client";
import { useParams } from "react-router-dom";

import { GET_MOVEMENTDATA } from "utils/Queries";
import McDaoService from "utils/McDaoService";

import scss from "views/movement/components/Movement.module.scss";
import MovementHome from "views/movement/components/MovementHome";
import axios from "axios";
import { UserContext } from "contexts/UserStore";
import { getById } from "utils/Requests";
import useMergeData from "hooks/useMergeData";
import { getDaoDataFromChain } from "utils/Helpers";
import { LoadingContext } from 'App';
import Loading from "components/shared/Loading";
import { errorToast } from "utils/NotificationService";

export const MovementContext = React.createContext({ members: [] });


const Movement = (props) => {
  const { setProcessingBackgroundState, processingBackgroundState, setModalMessage, setModalTitle } = useContext(LoadingContext);
  const userContext = useContext(UserContext);
  const { contract_address } = useParams();
  let client = useApolloClient();

  const [CurrentPage, setCurrentPage] = useState(() => Loading);
  const [members, setMembers] = useState([]);
  const [memberResponse, setMemberResponse] = useState({});
  const [graphRes, setGraphRes] = useState({});
  const [checkedIsMember, setCheckedIsMember] = useState(false);
  const [loading, setLoading] = useState(true);
  const mcDao = new McDaoService(contract_address);
  const [user, setUser] = useState(userContext);
  const [descExpanded, setDescExpanded] = useState(false);
  const descRef = useRef(null);
  const [proposalData, setProposalData, mergeProposalData] = useMergeData(
    (a, b) => {
      return parseInt(a.proposal_id) === parseInt(b.proposalId);
    }
  );


  const [daoData, setDaoData] = useState({});
  const displayPage = (Component, props) => {
    setCurrentPage(<Component {...props} context={MovementContext} />);
  };

  const descWrapperClasses = [scss.DescriptionTextWrapper];

  const isOverflowing = ({ clientWidth, clientHeight, scrollWidth, scrollHeight }) => {
    return scrollHeight > clientHeight || scrollWidth > clientWidth;
  }

  const fetchGraphData = async (waitForChange = true) => {
    let newGraphRes;
    if (waitForChange) {
      newGraphRes = await client.query({
        query: GET_MOVEMENTDATA,
        variables: {contract_address: contract_address},
        fetchPolicy: 'no-cache'
      });
    }
    else {
      newGraphRes = await client.query({
        query: GET_MOVEMENTDATA,
        variables: {contract_address: contract_address}
      });
    }
    if (waitForChange) {
      let graphHasChanged = molochIsDiffernt(graphRes, newGraphRes, contract_address);
      if (!graphHasChanged) graphHasChanged = proposalsAreDifferent(graphRes, newGraphRes, contract_address);
      if (!graphHasChanged) setTimeout(() => fetchGraphData(), 5000);
    }
    setGraphRes(newGraphRes);
  }

  const molochIsDiffernt = (oldGraph, newGraph, contract_address) => {
    let oldGraphInfo, newGraphInfo;
    if (oldGraph.data && oldGraph.data.moloches) {
      oldGraphInfo = oldGraph.data.moloches.find(
        item => item.partyAddress.toLowerCase() === contract_address.toLowerCase()
      );
    }
    if (newGraph.data && newGraph.data.moloches) {
      newGraphInfo = newGraph.data.moloches.find(
        item => item.partyAddress.toLowerCase() === contract_address.toLowerCase()
      );
    }

    let hasChanged = false;
    if (oldGraphInfo && newGraphInfo) {
      hasChanged = graphInfoHasChanged(oldGraphInfo, newGraphInfo);
    }
    return hasChanged;
  }

  const graphInfoHasChanged = (oldGraphInfo, newGraphInfo) => {
    for (let key in oldGraphInfo) {
      if (key === 'members' || key === 'tokenBalances') {
        // a more thorough comparison would do another shallow compare here; but this should work
        if (oldGraphInfo[key].length !== newGraphInfo[key].length) return true;
      }
      else if (key !== 'depositToken' && key !== 'idleToken') {
        if (oldGraphInfo[key] !== newGraphInfo[key]) return true;
      }
    }

    return false;
  }

  const proposalsAreDifferent = (oldGraph, newGraph, contract_address) => {
    let oldGraphProposals, newGraphProposals;

    if (oldGraph.data && oldGraph.data.proposals) {
      oldGraphProposals = oldGraph.data.proposals.filter(
        item => item.molochAddress.toLowerCase() === contract_address.toLowerCase()
      );
    }
    if (newGraph.data && newGraph.data.proposals) {
      console.log("old graph proposals", newGraph.data.proposals)
      newGraphProposals = newGraph.data.proposals.filter(
        item => item.molochAddress.toLowerCase() === contract_address.toLowerCase()
      );
    }

    let hasChanged = false;
    if (oldGraphProposals && newGraphProposals) {
      hasChanged = proposalsHaveChanged(oldGraphProposals, newGraphProposals);
    }
    return hasChanged;
  }

  const proposalsHaveChanged = (oldGraphProposals, newGraphProposals) => {
    let hasChanged = false;
    if (oldGraphProposals.length !== newGraphProposals.length) hasChanged = true;
    else {
      oldGraphProposals.forEach(oldProposal => {
        if (hasChanged) return;
        const newProposal = newGraphProposals.find(proposal => proposal.proposalId === oldProposal.proposalId);
        for (let key in oldProposal) {
          if (hasChanged) break;
          if (oldProposal[key] !== newProposal[key]) hasChanged = true;
        }
      });
    }

    return hasChanged;
  }

  useEffect(() => {
    setLoading(true);
    setTimeout(() => setLoading((curr) => {
      if(curr===true){
        props.history.push('/discover')
      }
    }), 15000);

    displayPage(MovementHome, {});

    fetchGraphData(false)
    .then(async (data) => {
      setLoading(false);
    })
    .catch( e => {
      console.error('dao error', e)
      errorToast("Opps...try refreshing your browser and make sure you're connected to MetaMask on the right network.")
      props.history.push('/discover')
      setLoading(false)
    })

    return () => {
      setLoading(false)
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchMembers = () => {
    const memberPromise = axios.get("/api/member/", {
      params: {
        contract_address: contract_address
      }
    })
    .then(memberResponse => {
      setMemberResponse(memberResponse);
    });
  }

  const updateDaoData = () => {
    const daoPromise = getById("/api/dao/", contract_address).then(async dao => {
      const chainRes = await getDaoDataFromChain(dao.data.contract_address);
      axios.put(`/api/dao/${contract_address}`, {tokens: [chainRes.tokenSymbol]});
      return { ...dao.data, ...chainRes };
    })
    .then(async daoWeb2 => {
      setDaoData({
        ...daoWeb2,
        ...graphRes.data.moloches.find(
          item =>
            item.partyAddress.toLowerCase() ===
            daoWeb2.contract_address.toLowerCase()
        )
      });
    });
  }

  useEffect(() => {
    if (graphRes && graphRes.data) {
      updateDaoData();
    }
  }, [graphRes]);

  const fetchProposals = () => {
    const proposalPromise = axios.get(
      `/api/proposal/?dao_address=${contract_address}`
    ).then(async web2ProposalResponse => {
      if (web2ProposalResponse.data.length > 0) {
        mergeProposalData(web2ProposalResponse.data, graphRes.data.proposals);
      }
    });
    setProposalData(graphRes.data.proposals);
  }

  useEffect(() => {
    if (graphRes && graphRes.data) {
      fetchProposals();
    }
  }, [graphRes]);

  useEffect(() => {
    if (graphRes && graphRes.data && memberResponse && memberResponse.data) {
      const memberList = graphRes.data.members.map(member => {
        const mongoEntry = memberResponse.data.find(mongoMember => {
          return mongoMember.id.toLowerCase() === member.memberAddress.toLowerCase();
        });
        if (mongoEntry) return { ...member, ...mongoEntry, id: mongoEntry.id, isMember: true };
        else return {...member, id: member.memberAddress, isMember: true };
        return {...member, id: member.memberAddress, isMember: true };
      });
      setMembers(memberList);
    }
  }, [graphRes, memberResponse]);

  useEffect(() => {
    const fetchData = async () => {
      if (userContext.id && members.length > 0 && !checkedIsMember) {
        const userMember = members.find(a => a.id.toLowerCase() === userContext.id.toLowerCase());
        if (userMember) {
          const userData = {...userMember, member_id: userMember._id, ...userContext};
          setUser(userData);
        }
        setCheckedIsMember(true);
      }
    };
    fetchData();
  }, [userContext, members]);

  const showMoreDescKeyPress = (event) => {
    if (event.key === "Enter") showMoreDesc();
  }

  const showMoreDesc = () => {
    setDescExpanded(true);
  }

  if (loading) return <Loading />;

  if (descExpanded) descWrapperClasses.push(scss.Expanded);

  if (!loading && descRef.current && !isOverflowing(descRef.current)) {
    descWrapperClasses.push(scss.Expanded);
  }

  return (
    <MovementContext.Provider
      value={{
        displayPage,
        setProposalData,
        setMembers,
        setProcessingBackgroundState,
        processingBackgroundState,
        setModalMessage,
        setModalTitle,
        fetchGraphData,
        fetchMembers,
        members: members,
        user: user,
        dao: daoData,
        proposals: proposalData,
      }}
    >
      <div className={scss.Movement}>
        <h1 className={scss.Title}>{daoData.name}</h1>
        <div className={descWrapperClasses.join(' ')}>
          <div className={scss.DescriptionText} ref={descRef}>{daoData.desc}</div>
          {!loading && descRef.current ?
            <div
              className={scss.MoreButton}
              onClick={showMoreDesc}
              onKeyPress={showMoreDescKeyPress}
              tabIndex='0'>
              &nbsp;more
            </div>
          : null}
        </div>

        {CurrentPage}
      </div>
    </MovementContext.Provider>
  );
};

export default Movement;
