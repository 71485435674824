import React, { useEffect, useState, createRef } from "react";
import { Link } from "react-router-dom";
import DAOCard from "../../components/shared/DAOCard";
import Loading from "components/shared/Loading";
import scss from "./Discover.module.scss";
import { get } from "../../utils/Requests";
import useMergeData from "../../hooks/useMergeData";
import { getDaoDataFromChain, fromBase } from "../../utils/Helpers";
import { useQuery } from '@apollo/client';
import { GET_DAOS } from "../../utils/Queries";
import { clearAllToasts } from "../../utils/NotificationService";


export default function Discover() {
  const [daoCards, setDaoCards] = useState();
  const [daoData, , mergeDaoData] = useMergeData((a,b) =>
    {return a.contract_address.toLowerCase() === b.id.toLowerCase()});

  const [daos, setDaos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const {error, data} = useQuery(GET_DAOS);
  console.log('dao data', daos);
  console.log('graphDAOs', data);


  useEffect(() => {
    const fetchData = async () => {
      const web2Response = await get('/api/dao/all');
      const daoList = web2Response.data.map(async (dao, index)=> {
        try {
          const daoData = await getDaoDataFromChain(dao.contract_address);
          return {...daoData, ...dao};
        }
        catch (e) {
          console.log(e);
        }
      })

      return Promise.all(daoList);
  }

  fetchData()
  .then(daoList=>{
    setIsLoading(false);
    setDaos(daoList);
  })
  return ()=>{
    clearAllToasts();
  }


  }, [])

  useEffect(() => {
    let cardList;

    if (isLoading) cardList = <Loading />;
    else if (!daoData || !daoData.length) cardList = <h3 className={scss.PageSubHeader}>No Parties Started Yet </h3>;
    else {
      console.log("Combo Data", daoData)
      cardList = daoData.map(item => {
        return (
          <div key={item.contract_address}>
            <Link className={scss.DAOLink} to={{ pathname: `/movement/${item.contract_address}` }}>
              <DAOCard
                title={item.name}
                description={item.desc}
                goal={Number(item.goal).toFixed(3)}
                members={item.members.length}
                totalCurrency={item.guildValue || 0}
                currencyType={item.tokenSymbol || 'no token'}
                yield={item.avgApr || 0}
              />
            </Link>
          </div>
        );
      });
    }
    setDaoCards(cardList);
    return () => {
    };

  }, [daoData]);

  useEffect(() => {
    if(daos && data) {
      mergeDaoData(daos, data.moloches);
    }

    return () => {

    };
  // eslint-disable-next-line
  }, [data, daos]);

  const ref = createRef();

  return (
    <div className={scss.MainContent}>
      <div className={scss.HeaderSection}>
        <h1 className={scss.PageHeader}>Find your party, start saving together</h1>
      </div>
      <div className={scss.DAOsContainer} ref={ref}>
        {error ? error : null}
        {daoCards}
      </div>
    </div>
  );
}
