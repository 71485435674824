import React from "react";
import scss from "./DAOCard.module.scss";

export default function DAOCard(props) {
 
  return (
    <div className={scss.MainCard}>
      <h2 className={scss.TitleText}>{props.title} {props.savings === 0 ? <div className={scss.NewTag}>new</div> : <div></div>}</h2>
      <p className={scss.DescriptionText}>{props.description}</p>
      <div className={scss.CardStats}>
        <p className={scss.StatText}>{props.goal}<br />Goal</p>
        <p className={scss.StatText}>{props.members}<br />Members</p>
        <p className={scss.StatText}>{props.totalCurrency}<br />Pool Size</p>
        <p className={scss.StatText}>{props.currencyType}<br />Currency</p>
        <p className={scss.StatText}>{Number(props.yield).toFixed(3)}%<br />Avg Yield</p>
      </div>
    </div>
  );
}
