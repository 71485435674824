import React, { useContext, useState } from 'react';
import { UserContext } from 'contexts/UserStore';
import McDaoService from 'utils/McDaoService';
import TokenService from 'utils/TokenService';
import { filterProposals } from 'utils/ProposalHelper';
import { errorToast, loginMetamaskError } from 'utils/NotificationService';
import Input from 'components/shared/InputFields/Input';
import ProposalSummaryCard from 'views/movement/components/ProposalSummaryCard';
import CreateProposal from 'views/movement/components/CreateProposal';
import Manage from 'views/movement/components/Manage';
import scss from 'views/movement/components/Movement.module.scss';
import web3 from "web3";

const ProposalsView = ({context}) => {
  const {proposals, dao, displayPage, setProcessingBackgroundState, processingBackgroundState} = useContext(context);
  const userContext = useContext(UserContext);
  const { awaitingSponsor, gracePeriod, complete, awaitingProcessing, votingPeriod } = filterProposals(proposals, dao);
  const [selectedProposals, setSelectedProposals] = useState('votingPeriod');

  console.log("proposals", proposals)

  const handleNewProposal = async e => {
    if (!userContext.id) {
      loginMetamaskError('create a proposal');
      return;
    }

    e.preventDefault();
    const mcDao = new McDaoService(
      dao.contract_address,
      dao.depositToken.tokenAddress,
      userContext.id
    );

    const spender = dao.contract_address;

    const tokenService = new TokenService(dao.depositToken.tokenAddress);
    const allowance = await tokenService.userHasAllowance(userContext.id, dao.contract_address);

    if (!allowance) {
      const depositAmount = web3.utils.toWei("10", "ether");
      const promise = mcDao.approveDeposit(spender, depositAmount);
      setProcessingBackgroundState('awaiting_approve_transaction');
      displayPage(CreateProposal, {});
      promise.then(resp => {
        return resp;
      })
      .then(resp => {
        if (resp.events) {
          setProcessingBackgroundState('');
        }
        if (resp.error) {
          errorToast('You must approve this transaction to create a proposal');
          const openView = 'proposals';
          displayPage(Manage, {openView});
        }
        setProcessingBackgroundState('');
      });
    } else {
      displayPage(CreateProposal, {});
    }
  }

  const getProposalsList = selectedProposals => {
    let orderedProposals;
    if (selectedProposals) orderedProposals = [...eval(selectedProposals)];
    else orderedProposals = [
      ...votingPeriod,
      ...awaitingSponsor,
      ...awaitingProcessing,
      ...gracePeriod,
      ...complete
    ];
    if (orderedProposals.length > 0) {
      return orderedProposals.map((proposal, index) => {
        return <ProposalSummaryCard key={index} proposal={proposal} dao={dao} displayPage={displayPage} />;
      });
    }
    else {
      return <h2>No proposals yet</h2>;
    }
  }

  const handleSelectedProposalsChange = event => {
    const newSelectedProposals = event.target.value;
    setSelectedProposals(newSelectedProposals);
  }

  const filterProposalsOptions = [
    {displayValue: 'Voting Period', value: 'votingPeriod'},
    {displayValue: 'Needs Sponsor', value: 'awaitingSponsor'},
    {displayValue: 'Needs Processing', value: 'awaitingProcessing'},
    {displayValue: 'Grace Period', value: 'gracePeriod'},
    {displayValue: 'Complete', value: 'complete'},
    {displayValue: 'Show All', value: ''}
  ];

  return (<>
    <div className={scss.FilterProposalsWrapper}>
      <Input
        type={'select'}
        options={filterProposalsOptions}
        handleChange={handleSelectedProposalsChange}
        name={'filterProposals'}
        labelText={'Filter Proposals:'}
        value={selectedProposals}
        additionalClasses={scss.FilterProposals}
      />
    </div>
    {getProposalsList(selectedProposals)}
    <button onClick={handleNewProposal} className={scss.MainButton}>New Proposal</button>
  </>);
}

export default ProposalsView;
