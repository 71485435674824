import React, { useContext, useEffect, useState } from 'react';
import web3 from 'web3';
import { useApolloClient } from '@apollo/client';
import { GET_USER_VOTE } from 'utils/Queries';
import McDaoService from 'utils/McDaoService';
import TokenService from 'utils/TokenService';
import { UserContext } from "contexts/UserStore";

import useSetProposalStatus from 'hooks/inputHooks/useSetProposalStatus';
import { updateProposals } from 'hooks/updateProposals';
import { put } from "../../../utils/Requests";
import { fromBase } from "../../../utils/Helpers";


import Manage from 'views/movement/components/Manage';
import IconButton from 'components/shared/IconButton';
import ThumbsUp from 'assets/thumbsup.svg';
import ThumbsDown from 'assets/thumbsdown.svg';
import VotingBar from 'components/shared/VotingBar';
import scss from 'views/movement/components/Movement.module.scss';
import { getProposalStatus } from 'utils/ProposalHelper';

const ProposalDetails = ({context, proposal, dao, displayPage}) => {
  const client = useApolloClient();
  const {user, fetchGraphData, setProcessingBackgroundState, members, proposals, setProposalData} = useContext(context);
  const userContext = useContext(UserContext);
  const [payDecimals, setPayDecimals] = useState({});
  const [tribDecimals, setTribDecimals] = useState({});
  const [disabled, setDisabled] = useState(false);
  const mcDao = new McDaoService(dao.contract_address, dao.depositToken.tokenAddress, user.id);
  //const member = members.find(i => i.memberAddress.toLowerCase() === user.id.toLowerCase() | null);
  const {proposalStatus, updateProposalStatus, proposalStatusMessage} = useSetProposalStatus(proposal, dao);
  const name = proposal.proposal_name;
  const description = web3.utils.toAscii(proposal.details);
  const moreInfo = proposal.link;
  let moreInfoLink = moreInfo;
  if (moreInfo && !moreInfo.startsWith('http://') && !moreInfo.startsWith('https://')) {
    moreInfoLink = `https://${moreInfo}`;
  }

  let member;
  console.log(members, "members");
  console.log(userContext.id, "user");
  if (members.find(i => i.memberAddress.toLowerCase() === userContext.id.toLowerCase())) {
    member = members.find(i => i.memberAddress.toLowerCase() === userContext.id.toLowerCase())
  }

  useEffect(() => {
    if (proposal) {
      getDecimals();
    }
    return () => {
    };
  }, [])

  const getDecimals = async () => {
    const tribService = new TokenService(proposal.tributeToken);
    const tribDecimals = await tribService.getDecimals();
    setTribDecimals(tribDecimals);
    console.log("trib decimals", tribDecimals)

    const payService = new TokenService(proposal.paymentToken);
    const payDecimals = await payService.getDecimals();
    setPayDecimals(payDecimals);
  }

  const proposer = proposal.proposer;
  const sponsor = proposal.sponsor || 'None';
  const applicant = proposal.applicant;

  const reqAmount = fromBase(proposal.paymentRequested, payDecimals);
  const tribAmount = fromBase(proposal.tributeOffered, tribDecimals);
  console.log("tributeOffered", proposal.tributeOffered)
  console.log("tribAmount", tribAmount)
  const sharesReq = proposal.sharesRequested;
  const lootReq = proposal.lootRequested;
  const currency = proposal.token;
  console.log("member", member)


  const handleCloseClick = () => {
    const openView = 'proposals';
    displayPage(Manage, {openView});
  }

  const getUserVote = async () => {
    const userVoteData = await client.query({
      query: GET_USER_VOTE,
      variables: {contract_address: dao.contract_address, proposalIndex: proposal.proposalIndex, memberAddress: user.id},
      fetchPolicy: 'no-cache'
    });
    return userVoteData;
  }

  const disableForm = disableMessage => {
    setDisabled(true);
    setProcessingBackgroundState(disableMessage);
  }

  const enableForm = () => {
    setDisabled(false);
    setProcessingBackgroundState('');
  }

  const vote = async vote => {
    disableForm('awaitng_vote_processing');
    const userVoteData = await getUserVote();

   // @Dev need to switch this to a find to see if voted on this particular proposal, prob do this via proposal query
    if (userVoteData.data.proposals[0].votes.length  > 0 ) {
      return alert('You have already voted on this proposal');
    }
    const promise = mcDao.submitVote(proposal.proposalIndex, vote)
    promise.then(async resp => {
      let update = {};
      if (resp.events) {
        if (vote === 1) {
          // await setYesShares(yesShares + parseInt(member.shares));
          const shares = parseInt(proposal.yesShares) ? parseInt(proposal.yesShares) + parseInt(member.shares) : parseInt(member.shares) + 0
          update = {...proposal, yesShares: shares}
          console.log('update',update)
        } else if (vote === 2) {
          const shares = proposal.noShares ? parseInt(proposal.noShares) + parseInt(member.shares) : parseInt(member.shares) + 0

          update = {...proposal, noShares: shares}
          console.log('update',update)
        }
        console.log('vote update',update)
        const updatedProposals = updateProposals(proposals, update);
        enableForm();
        await setProposalData(updatedProposals);
        fetchGraphData();
        const openView = 'proposals';
        displayPage(Manage, {openView});
      }
      else if(resp.error) {
        enableForm();
        if(resp.error === 'rejected transaction') {
        }
      } else {
        enableForm();
      }
    });
  };

  const sponsorProposal = async () => {
    disableForm('awaiting_sponsor_processing');
    const proposalId = parseInt(proposal.proposalId)

    const promise = mcDao.sponsorProposal(proposalId)

        promise.then(resp => {
          if (resp.events) {
            console.log("proposal_id", proposal._id)
            put(`/api/proposal/sponsor/${proposal._id}`)
              .then(async res => {
                const update = {
                  ...proposal,
                  startingPeriod: resp.events.SponsorProposal.returnValues.startingPeriod,
                  proposalIndex: resp.events.SponsorProposal.returnValues.proposalIndex,
                  status: true,
                  sponsored: true,
                  processed: false
                };
                const updatedProposals = updateProposals(proposals, update);
                await setProposalData(updatedProposals);
                enableForm();
                const openView = 'proposals';
                displayPage(Manage, {openView});
              })
              .catch(e => {
                enableForm();
                const openView = 'proposals';
                displayPage(Manage, {openView});
                console.log(e);
              });
          }
          else if(resp.error) {
            enableForm();
            const openView = 'proposals';
            displayPage(Manage, {openView});
            if(resp.error === 'rejected transaction') {
            }
          }
          else {
            const openView = 'proposals';
            displayPage(Manage, {openView});
            enableForm();
          }
        });
    };

  const processProposal = async () => {
    const proposalIndex = parseInt(proposal.proposalIndex);
    console.log("proposal type", proposal.governance, proposal.guildkick, proposal.addMember)

    if (proposal.governance === true ) {
      console.log("processGovernance")
      const promise = mcDao.processAmendGovernance(proposalIndex)
      disableForm('awaiting_governance_processing');
        promise.then(resp => {
          if (resp.events) {
            const grant = fromBase(
              proposal.paymentRequested, payDecimals)
            put(`/api/proposal/process/${proposal._id}/${proposal.paymentToken}/${grant}`)
              .then(async res => {
                console.log(res)
                const update = {
                  ...proposal,
                  status: true,
                  processed: true,
                  processDate: res.data.processDate,
                  amtInUSD: res.data.amtInUSD,
                  currencyUSDExchangeRate:res.data.currencyUSDExchangeRate
                };
                const updatedProposals = updateProposals(proposals, update);
                await setProposalData(updatedProposals);
                enableForm();
                fetchGraphData();
                const openView = 'proposals';
                displayPage(Manage, {openView});
              })
              .catch(e => {
                enableForm();
                const openView = 'proposals';
                displayPage(Manage, {openView});
                console.log(e);
              });
          } else if (resp.error) {
            const openView = 'proposals';
            displayPage(Manage, {openView});
            enableForm();
          } else {
            const openView = 'proposals';
            displayPage(Manage, {openView});
            enableForm();
          }
      });
    } else if (proposal.guildkick) {
      console.log("processGuildKick")
      const promise = mcDao.processGuildKickProposal(proposalIndex)
      disableForm('awaiting_guild_kick');
        promise.then(resp => {
          if (resp.events) {
            const grant = fromBase(
              proposal.paymentRequested, payDecimals)
            put(`/api/proposal/process/${proposal._id}/${proposal.paymentToken}/${grant}`)
              .then(async res => {
                console.log(res)
                const update = {
                  ...proposal,
                  status: true,
                  processed: true,
                  processDate: res.data.processDate,
                  amtInUSD: res.data.amtInUSD,
                  currencyUSDExchangeRate:res.data.currencyUSDExchangeRate
                };
                const updatedProposals = updateProposals(proposals, update);
                await setProposalData(updatedProposals);
                enableForm();
                fetchGraphData();
                const openView = 'proposals';
                displayPage(Manage, {openView});
              })
              .catch(e => {
                enableForm();
                const openView = 'proposals';
                displayPage(Manage, {openView});
                console.log(e);
              });
          } else if (resp.error) {
            const openView = 'proposals';
            displayPage(Manage, {openView});
            enableForm();
          } else {
            const openView = 'proposals';
            displayPage(Manage, {openView});
            enableForm();
          }
      });
    } else if (!proposal.governance && !proposal.guildkick) {
      const promise = mcDao.processProposal(proposalIndex)
      disableForm('awaiting_proposal_processing');
        promise.then(resp => {
          if (resp.events) {
            const grant = fromBase(
              proposal.paymentRequested, payDecimals)
            put(`/api/proposal/process/${proposal._id}/${proposal.paymentToken}/${grant}`)
              .then(async res => {
                console.log(res)
                const update = {
                  ...proposal,
                  status: true,
                  processed: true,
                  processDate: res.data.processDate,
                  amtInUSD: res.data.amtInUSD,
                  currencyUSDExchangeRate:res.data.currencyUSDExchangeRate
                };
                const updatedProposals = updateProposals(proposals, update);
                await setProposalData(updatedProposals);
                enableForm();
                fetchGraphData();
                const openView = 'proposals';
                displayPage(Manage, {openView});
              })
              .catch(e => {
                enableForm();
                const openView = 'proposals';
                displayPage(Manage, {openView});
                console.log(e);
              });
          } else if (resp.error) {
            enableForm();
            const openView = 'proposals';
            displayPage(Manage, {openView});
          } else {
            enableForm();
            const openView = 'proposals';
            displayPage(Manage, {openView});
          }
      });
    }
  };

  const withdrawProposalBalance = async () => {

    const withdrawAmt = await mcDao.getUserTokenBalance(user.id, proposal.paymentToken);
    console.log("user token balance", withdrawAmt)


    const promise = mcDao.withdrawBalance(proposal.paymentToken, withdrawAmt)
      disableForm('awaiting_withdraw_balance');
        promise.then(resp => {
          if (resp.events) {
            const grant = fromBase(
              proposal.paymentRequested, payDecimals)
            put(`/api/proposal/withdraw/${proposal._id}/${proposal.token}/${grant}`)
              .then(async res => {
                console.log(res)
                const update = {
                  ...proposal,
                  status: true,
                  amtInUSD: res.data.amtInUSD,
                  currencyUSDExchangeRate:res.data.currencyUSDExchangeRate
                };
                const updatedProposals = updateProposals(proposals, update);
                await setProposalData(updatedProposals);
                enableForm();
                fetchGraphData();
              })
              .catch(e => {
                enableForm();

                console.log(e);
              });
          } else if (resp.error) {
            enableForm();
          } else {
            enableForm();
          }
      });
  };


  return (
    <section className={`${scss.DAOCard} ${scss.DetailsCard}`}>
      <IconButton className={scss.CloseButton} onClick={handleCloseClick}>X</IconButton>
      <div className={scss.HeaderRow}>
        <h5>{!proposal.processed ? (<p>{proposalStatusMessage}</p>) : (<p>Passed: {proposal.didPass.toString()}</p>)}</h5>
      </div>
      <div className={scss.HeaderRowTwo}>
        <h2>{name}</h2>
      </div>
      <p className={scss.ProposalInfoText}>{description}</p>
      {moreInfo && moreInfo !== "none" ? <p className={scss.ProposalInfoText}>
        More info: <a className={scss.ProposalMoreInfo} href={moreInfoLink}
          target='_blank' rel='noopener noreferrer'>
          {moreInfo}
        </a>
      </p> : null}
      <p className={scss.ProposalInfoText}>Proposer: {proposer}</p>
      <p className={scss.ProposalInfoText}>Applicant: {applicant}</p>
      <p className={scss.ProposalInfoText}>Sponsor: {sponsor}</p>
      <p className={scss.ProposalInfoText}>Funds Req: {reqAmount} {currency}</p>
      <p className={scss.ProposalInfoText}>Funds Offerred: {tribAmount} {currency}</p>
      <p className={scss.ProposalInfoText}>Shares Req: {sharesReq} Shares</p>
      <p className={scss.ProposalInfoText}>Loot Req: {lootReq} Loot</p>
      <div
          style={{
            margin: "5.1rem 0",
            width: "100%",
            display: "flex",
            justifyContent: "center"
          }}
        >
      <div
        style={{
          display: proposalStatus === "awaiting_sponsor" && !proposal.sponsored ? "flex" : "none",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column"
        }}
          >
            <button
              disabled={!user.isMember || disabled}
              onClick={async () => {
                sponsorProposal();
              }}
              className={scss.MainButton}
            >
              Sponsor
            </button>
            {!user.isMember ? (
              <p className={scss.ButtonWarning}>*Gotta be a partygoer to sponsor proposals</p>
            ) : null}
          </div>

    <div
      style={{
          display:
            proposalStatus === "awaiting_processing" ? "flex" : "none",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column"
        }}
      >
        <button
          disabled={!proposal.canProcess || disabled}
          onClick={processProposal}
          className={scss.MainButton}
        >
          Process
        </button>
        {!proposal.canProcess ? (
          <p className={scss.ButtonWarning}>Earlier Proposals Need to Be Processed First</p>
        ) : null}
        {!user.isMember ? (
          <p className={scss.ButtonWarning}>Must be a partygoer to process this</p>
        ) : null}
      </div>
      <div
        style={{
          display: proposalStatusMessage === "funded" ? "flex" : "none",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column"
        }}
      >
        <button
          onClick={withdrawProposalBalance}
          disabled={!user.isMember || disabled}
          className={scss.MainButton}
        >
          Withdraw Funds
        </button>
      </div>
      <div
        className={scss.VoteContainer}
        style={{
          display: proposalStatus === "voting_period" ? "" : "none"
        }}
      >
        <div className={scss.VoteButtonWrapper}>
          {proposal.yesShares || 0}
          <button disabled={disabled} className={scss.VoteButton} onClick={() => vote(1)}>
            <img src={ThumbsUp} alt="Yes" />
          </button>
        </div>

        <VotingBar
          yesVotes={proposal.yesShares}
          noVotes={proposal.noShares} />

        <div className={scss.VoteButtonWrapper}>
          {proposal.noShares || 0}
          <button disabled={disabled} className={`${scss.VoteButton} ${scss.DownVote}`} onClick={() => vote(2)}>
            <img src={ThumbsDown} alt="No" />
          </button>
        </div>
      </div>
      </div>
    </section>
  );
}

export default ProposalDetails;
