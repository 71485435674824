import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import FourOhFour from "./views/404/404";
import Movement from "./views/movement/Movement";
import Discover from "./views/discover/Discover";
import CreateDAO from "./views/create-DAO/CreateDAO";
import Faq from "./components/header/Faq";
import MyProfile from "./views/myProfile/MyProfile";
import Terms from "./components/footer/legal/Terms";
import Privacy from "./components/footer/legal/Privacy";

const Routes = () => (
  <Switch>
    <Route path="/movement/:contract_address" exact component={Movement} />
    <Route path="/discover" exact component={Discover} />
    <Route path="/create-dao" exact component={CreateDAO} />
    <Route path="/faq" exact component={Faq} />
    <Route path="/profile" exact component={MyProfile} />
    <Route path="/privacy" exact component={Privacy} />
    <Route path="/terms" exact component={Terms} />
    <Redirect from="/" to="/discover" />
    <Route path="*" component={FourOhFour} />
  </Switch>
);

export default Routes;
