import React from 'react';
import scss from './Faq.module.scss';
import Emoji from 'components/shared/Emoji';

const Faq = () => {
  return (
  <div className={scss.Faq}>
    <div className={scss.ContainerMain}>
      <h2 className={scss.CardTitle}>POOL-PARTY <Emoji className={scss.HeaderEmojiButton} symbol=" 🏊" label="menu" /> FAQ

      </h2>
      <div className={scss.SubHeader}> 1) What does combining DeFi and DAOs mean?</div>
        <div className={scss.Text}>First some definitions:</div>
          <ul>DeFi (Decentralized Finance): financial infrastructure that functions by code, not by banks...and generally allows people to lend or borrow crypto.</ul>
          <ul>DAO (Decentralized Autonomous Organization): a club that is structured and governed by its Members through code.</ul>

        <div className={scss.Text}>We love DAOs. We think the future is DAO. We’re ready to #JustDAOit. We think all organizations that have control over funds should have decentralized governance, from Product Teams to Nonprofits to Political Parties. And now, with the help of Pool-Party, you and your circle of friends (and friends of friends) can pool money together for whatever you want to do with it.</div>

        <div className={scss.Text}>We also love DeFi. We wake up before the sun every day to tend to our yield farming harvests. Interest rates should be controlled by supply and demand, not a giant bank. And money shouldn’t stop moving because the sun is down or the day of the week starts with “S.” Moving money internationally shouldn’t take three plus days and cost $50.</div>

        <div className={scss.Text}>Pool-Party makes it so that the investment club, startup project, or timeshare plan you’ve been talking about with friends is easy to start and manage. We added DeFi into the back-end so your pooled funds are earning significantly more interest than they would in a boring bank. You can watch your Pool grow without giving up control of your assets (your friend Chad couldn’t liquidate the pool even if he tried), plus you can gamify saving up large sums of money in a group.</div>

      <div className={scss.SubHeader}> 2) Cool, so what actually is Pool-Party? </div>
        <div className={scss.Text}>Pool-Party is a DAO-launcher that enables groups of friends, clubs, and organizations to save crypto together for larger purchases, in a trustless and transparent way. Pool-Party pools harness the governance prowess of DAOs (decentralized autonomous organizations) and the versatility of DeFi (decentralized finance) to shepherd in a new era of "savings." By creating savings pools within your circles, you're applying both peer pressure (friends who save together...) and smart contract assistance in decreasing your time-preference. Set financial goals, team up with friends to achieve them, pool your money for longer spans of time, earn higher interest rates, and create the generational wealth your grandparents could only dream of building.</div>

      <div className={scss.SubHeader}> 3) How do I create a Pool-Party Pool?</div>
        <div className={scss.Text}>It only takes a couple of minutes to create a Pool-Party Pool (assuming you’ve got a name and your co-founders’ ETH addresses handy). After three quick and easy steps, you’ll click “Build Pool,” sign a transaction, and your Pool is officially launched on the blockchain. You and your co-founders can immediately start buying shares of the DAO you created.</div>

      <div className={scss.SubHeader}> 4) What do I need to build a Pool?</div>
        <div className={scss.Text}>Internet access, some gas money (ETH), MetaMask, some crypto like USDC or DAI to deposit, and the wallet addresses of your co-founders. If you’re a crypto native, you’ve got this...if you’re not, we’ll help you figure it out in our Discord.</div>

      <div className={scss.SubHeader}> 5) Can I add new people to my pool later?</div>
        <div className={scss.Text}>You sure can, but it is much cheaper/easier to have the core members of the Party ready before creation. People that come late to the party can create a proposal to join the Pool. If they receive a positive vote, they will be issued shares in return for throwing some DAI, USDC, or some other token into the Pool. It is important to note that after the pool is created, the earliest a new member can contribute to the pool is ~96 hours, because there is a 72-hour voting period followed by a 36-hour grace period. It is recommended that you put in the time before summoning the pool to gather the ETH addresses of your co-founders to avoid the proposal process.</div>

      <div className={scss.SubHeader}> 6) Can I prevent someone I don’t know/like from jumping into the pool?</div>
        <div className={scss.Text}>Absolutely. After the creation of a Pool, new members can only join by being voted in by the current members. You can even boot someone if you have a falling out. And when they leave, they leave with their share of the money. So no hard feelings. And if you’re in a pool that does vote to add someone against your wishes, you have a 36 hour grace period to safely Ragequit.</div>

      <div className={scss.SubHeader}> 7) Nice, I’ve found a Pool-Party Pool…how do I join?</div>
        <div className={scss.Text}>You can apply to join the existing pool, but the swimmers in that pool must vote affirmatively for you to be able to dive in. Click on the pool, then click “Join,” which should take you to a brief form where you can submit some information, how much you’d like to pledge, and a brief description of who you are, why you want to join, and what value you bring to the pool. This description is even more important when trying to join a pool of swimmers you don’t know.</div>

        <div className={scss.Text}>After you submit your request to join, the existing pool members will be able to vote yay or nay on your admission. You don’t need 51%, just more yay votes than nay votes.</div>

      <div className={scss.SubHeader}> 8) Can someone take over a Pool against my will?</div>
        <div className={scss.Text}>Probably not. The smart contracts are smart enough to prevent someone from gaining more than 50% of the Pool, unless you allow them to via a vote. You’re also protected by the RageQuit and RageKick functions, both of which allow you to protect yourself and your principal from financial decisions with which you disagree.</div>

      <div className={scss.SubHeader}> 9) What coins can I throw into the pool?</div>
        <div className={scss.Text}>Any ERC-20 token can be tossed in the pool to contribute toward your savings goal. For now, only tokens that are on Idle.Finance will earn interest (currently, those include DAI, USDC, USDT, SUSD, TUSD, and WBTC). Pool-Party has received a grant from Aave to build integration for aTokens. When that update is pushed, you’ll also be able to earn interest on aTokens.</div>

      <div className={scss.SubHeader}> 10) I submitted a proposal, am I a member yet?</div>
        <div className={scss.Text}>After you’ve pledged some crypto and entered your information, a Pool Swimmer must sponsor your membership proposal, and then your membership proposal is put to a vote. They’re able to admit you with just one click, at which point you’ll be given a number of voting shares in proportion to your contribution. As a security measure, all proposals have a 72-hour voting period followed by a 36-hour grace period.</div>

      <div className={scss.SubHeader}> 11) Why is there a 72-hour voting period and what is a 36-hour grace period?</div>
        <div className={scss.Text}>These security measures are to protect the existing members of the Pool. Members have 72 hours to vote on a proposal. After a proposal passes, the existing pool swimmers have a 36-hour grace period to RageQuit if they are fundamentally opposed to what the other swimmers voted to pass.</div>

      <div className={scss.SubHeader}> 12) Now, I’ve joined a Pool, what’s next? </div>
        <div className={scss.Text}>Good question. Save. Save. Save. The purpose of a Pool-Party pool is to save up large sums of interest-bearing crypto with your friends over longer periods of time (think 12+ months). We built Pool-Party because we wanted a bankless way to save up to buy a large piece of land together. So, once your swim-team has saved up enough money to reach the pool’s savings goal, one of the members can create a proposal to spend some or all of the funds. We imagine some pools will be small groups of friends, whereas other pools will be larger communities competing against other communities. Head to your pool’s discord channel and find out how you can help your co-founders.</div>

      <div className={scss.SubHeader}> 13) How long can I keep my Pool-Party Pool going?</div>
        <div className={scss.Text}>There ain’t no party like a Pool-Party $POOL Party, ‘cause a Pool-Party Pool Party Pool don’t stop. In plain English, once your Pool achieves the set monetary fundraising goal, proposals can be created to spend, disburse, rebalance, invest, etc the Pool funds. But there is nothing in the contracts that says the Party must end. You can spend all of the money and close the pool, spend some of it on what you wanted to buy, or spend none of it and keep saving. It’s entirely up to you (and your fellow pool-swimmers).</div>

      <div className={scss.SubHeader}> 14) We have reached our fundraising goal, how do we spend the funds?</div>
        <div className={scss.Text}>Any pool member can draft a proposal on how to spend the money. After the vote passes, there is a 36-hour grace period for swimmers to wish to RageQuit. The funds are released to the sponsor’s address (or another smart contract) to carry out the wishes of the Pool. Ideally you are only in pools with people you know and trust, but it is inevitable that pools may grow beyond your personal circles, so make sure you only release funds to someone you trust. We are working on developing partnerships with protocols that will expand the on-chain options you’ll have to manipulate the funds.</div>

      <div className={scss.SubHeader}> 15) What happens to a proposal that passes if someone RageQuits?</div>
        <div className={scss.Text}>So, you met the spending goal and passed a proposal, but one/some of your pool doesn’t agree with the spending proposal. Anyone has the option of leaving during the Grace Period. If the pool maintains at least 80% of its sponsored spending amount, the proposal will still succeed. If more than 20% of the funds leave with the RageQuitter, the proposal will fail and the funds will remain in the Pool.</div>

      <div className={scss.SubHeader}> 16) Does Pool-Party have a token?</div>
        <div className={scss.Text}>A Pool-Party token is being designed and is already being earned by swimmers using Pool-Party. All rewards will be retroactive to Swim Season 0 Period 0.</div>

      <div className={scss.SubHeader}> 17) What are Swim Seasons/Periods?</div>
        <div className={scss.Text}>Pool-Party was designed to encourage swimmers to squirrel away savings towards some shared goal, nut by nut. To try to mitigate Whale dominance and improve the time-preference of Ethereans, we are contemplating a 10-year distribution of rewards tokens. To make swimming fun, we expect to break those annual Seasons up into 10-day swim periods.</div>

        <div className={scss.Text}>There will be a snapshot at the beginning and end of each period to determine any reward distribution for that period. Likewise, there will be a snapshot at the beginning and end of each season to determine any reward distributions for that season. We also like the idea of rewarding grand prizes at the end of the 10th Season. More details will be forthcoming (and we'd love to hear your ideas as well).</div>

      <div className={scss.SubHeader}> 18) What kind of cliffhanger was that? C’mon, give us something about the token!</div>
        <div className={scss.Text}>Fine. The unique aspect of the Pool-Party token is that it will be distributed based on potential factors like the savings earned by you, and your pool. So it's likely, the more interest your pool earns, the higher percentage of overall distribution of rewards tokens it will receive. </div>

        <div className={scss.SubHeader}> 19) Are the contracts audited? </div>
        <div className={scss.Text}> Our smart contracts are a fork of Moloch v2. We have not audited our upgrades to the Moloch v2 code yet, which is why you are using our platform at your own risk. We do plan on getting a contract audit in the future. And, if you're interested in our code, the contracts are verified on etherscan and in our Github.</div>
    </div>
  </div>
  );
};
export default Faq;