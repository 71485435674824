import React, { useState } from 'react';
import scss from 'views/movement/components/Movement.module.scss';

const ChooseTypeOfProposal = ({setScreenIndex, SCREEN_INDEX}) => {
  const [selectedProposalIndex, setSelectedProposalIndex] = useState(0); // for toggling the selected button
  const memberClasses = [scss.ProposalTypeButton];
  const spendClasses = [scss.ProposalTypeButton];
  const settingsClasses = [scss.ProposalTypeButton];
  const actionsClasses = [scss.ProposalTypeButton];

  if (selectedProposalIndex === SCREEN_INDEX.MEMBER)
    memberClasses.push(scss.SelectedProposalType);
  if (selectedProposalIndex === SCREEN_INDEX.SPEND)
    spendClasses.push(scss.SelectedProposalType);
  if (selectedProposalIndex === SCREEN_INDEX.SETTINGS)
    settingsClasses.push(scss.SelectedProposalType);
  if (selectedProposalIndex === SCREEN_INDEX.ACTIONS)
    actionsClasses.push(scss.SelectedProposalType);

  return (
    <>
      <div className={scss.ProposalTypeWrapper}>
        <button type='button'
          className={memberClasses.join(' ')}
          onClick={() => setSelectedProposalIndex(SCREEN_INDEX.MEMBER)}>
          Add Member
        </button>
        <button type='button'
          className={spendClasses.join(' ')}
          onClick={() => setSelectedProposalIndex(SCREEN_INDEX.SPEND)}>
          Spend $
        </button>
        <button type='button'
          className={settingsClasses.join(' ')}
          onClick={() => setSelectedProposalIndex(SCREEN_INDEX.SETTINGS)}>
          Update Settings
        </button>
        <button type='button'
          className={actionsClasses.join(' ')}
          onClick={() => setSelectedProposalIndex(SCREEN_INDEX.ACTIONS)}>
          Actions
        </button>
      </div>

      <button
        type='button'
        className={scss.MainProposalButton}
        onClick={() => setScreenIndex(selectedProposalIndex)}>
        Next
      </button>
    </>
  );
}

export default ChooseTypeOfProposal;
