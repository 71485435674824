import React from 'react';
import scss from 'components/shared/Processing.module.scss';
import Hand from 'assets/hand.gif';

const Processing = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

  return (
    <div className={scss.LoaderBackground}>
      <div className={scss.Loader}>
        <img src={Hand} alt="loading..." />
        <h2 className={scss.LoaderText}>hodl on...<br/>starting your party</h2>
      </div>
    </div>
  );
}

export default Processing;
