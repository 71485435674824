import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import Routes from "./Routes";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Loading from "./components/shared/Loading";
import "styles/App.scss";
import Processing from "./components/shared/Processing";


export const LoadingContext = React.createContext()


const App = (props) => {
  const [loading, setloading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [processingBackgroundState, setProcessingBackgroundState] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');


  useEffect(() => {
    const fetchData = async () => {
      setloading(false);
    };
    fetchData();

  }, []);

  return (
    <CookiesProvider>
      <LoadingContext.Provider value={{
        loading,
        setloading,
        processing,
        setProcessing,
        processingBackgroundState,
        setProcessingBackgroundState,
        modalMessage,
        setModalMessage,
        modalTitle,
        setModalTitle
      }}>
        <div className="App">
          {processing ? <Processing/> : null}
          {loading ?
            <Loading />
          :
            <Router>
              <Header />
              <Routes />
              <Footer />
            </Router>
          }
        </div>
      </LoadingContext.Provider>
    </CookiesProvider>
  );
};

export default App;
