import poolPartyAbi from '../contracts/PoolParty.json';
import WETHPartyAbi from '../contracts/WETHParty.json';
import NOFIPartyAbi from '../contracts/NOFIParty.json';

import ERC20Abi from '../contracts/erc20a.json';
import { getTokenList } from '../utils/TokenHelper.js';

import Web3Service from '../utils/Web3Service';
import Web3 from 'web3';
import config from '../config';

export default class McDaoService {
  web3Service;
  contractAddr;
  contract;
  poolPartyAbi;
  coinContract;
  coinAbi;


  constructor(contractAddr, coinAddr = '', userAccount) {
    const tokenList = getTokenList();
    switch (coinAddr.toLowerCase()) {
      case tokenList['WETH_ADDRESS'].toLowerCase():
        // TODO FOR BILL: set this.factoryAddr and this.FactoryAbi to point to new address/ABI here
        this.daoAbi = WETHPartyAbi;
        break;
      case tokenList['PAN_ADDRESS'].toLowerCase():
        // TODO FOR BILL: set this.factoryAddr and this.FactoryAbi to point to new address/ABI here
        this.daoAbi = NOFIPartyAbi;

        break;  
      // case 'AAVE_A': // list all of the aave tokens like this (just replace these 'AAVE_A', etc strings with the TOKEN name and a line for each token)
      // case 'AAVE_B':
      // case 'AAVE_C, ETC...':
      //   // TODO FOR BILL: set this.factoryAddr and this.FactoryAbi to point to new address/ABI here
      //   // this.factoryAddr = config.AAVE_FACTORY_ADDRESS;
      //   // this.FactoryAbi = AaveFactoryAbi;
      //   break;
      default:
        this.daoAbi = poolPartyAbi;
    }

    //TODO: make this programmatic based on address, though ABI should all be the same, via movement context.
    this.contractAddr = contractAddr;
    //TODO: coinAddr should match the depositToken address for whatever DAO the user is in --> by calling approvedToken().
    this.coinAddr = coinAddr
    this.account = userAccount

    this.web3Service = new Web3Service();

    //coin ABI should just be a generic ERC-20 token abi (so basically MetaCoin Abi, but we should change file names)
    this.coinAbi = ERC20Abi;

    if(window.ethereum){
      window.ethereum.enable()

      this.web3 = new Web3(window.ethereum);

      this.initContract();

      this.initCoinContract();
    }
    else {
      this.web3 = new Web3(new Web3.providers.HttpProvider(config.INFURA_URI));
    }
  }

  async initContract() {
    this.contract = new this.web3.eth.Contract(this.daoAbi, this.contractAddr)
  }

  async initCoinContract() {
    this.coinContract = new this.web3.eth.Contract(this.coinAbi, this.coinAddr)
  }
/**********************************GETTERS**********************************/
  async getAllEvents() {
    if (!this.contract) {
      await this.initContract();
    }
    let events = await this.contract.getPastEvents('allEvents', {
      fromBlock: 0,
      toBlock: 'latest',
    });
    return events;
  }

  async getCurrentPeriod() {
    if (!this.contract) {
      await this.initContract();
    }
    let currentPeriod = await this.contract.methods.getCurrentPeriod().call();
    return currentPeriod;
  }

  async getTotalShares(atBlock = 'latest') {
    if (!this.contract) {
      await this.initContract();
    }
    let totalShares = await this.contract.methods.totalShares().call({}, atBlock);
    return totalShares;
  }

  async getGracePeriodLength() {
    if (!this.contract) {
      await this.initContract();
    }
    let gracePeriod = await this.contract.methods.gracePeriodLength().call();
    return gracePeriod;
  }

  async getVotingPeriodLength() {
    if (!this.contract) {
      await this.initContract();
    }
    let votingPeriod = await this.contract.methods.votingPeriodLength().call();
    return votingPeriod;
  }

  async hasVotingPeriodExpired(startingPeriod) {
    if (!this.contract) {
      await this.initContract();
    }
    let votingPeriodExpired = await this.contract.methods.hasVotingPeriodExpired(startingPeriod).call();
    return votingPeriodExpired;
  }

  async getPeriodDuration() {
    if (!this.contract) {
      await this.initContract();
    }
    let periodDuration = await this.contract.methods.periodDuration().call();
    return periodDuration;
  }

  async getDepositReward() {
    if (!this.contract) {
      await this.initContract();
    }
    let proposalDepositReward = await this.contract.methods.proposalDepositReward().call();
    return proposalDepositReward;
  }

  async getTotal() {
    if (!this.contract) {
      await this.initContract();
    }
    let total = await this.contract.methods.TOTAL().call();
    return total;
  }

  async getTotalDeposits() {
    if (!this.contract) {
      await this.initContract();
    }
    let totalDeposits = await this.contract.methods.totalDeposits().call();
    return totalDeposits;
  }

  async getGuild() {
    if (!this.contract) {
      await this.initContract();
    }
    let guild = await this.contract.methods.GUILD().call();
    return guild;
  }

  async getEscrow() {
    if (!this.contract) {
      await this.initContract();
    }
    let escrow = await this.contract.methods.ESCROW().call();
    return escrow;
  }

  async getDAOFeeAddress() {
    if (!this.contract) {
      await this.initContract();
    }
    let DAOFeeAddress = await this.contract.methods.daoFee().call();
    return DAOFeeAddress;
  }

  async getDepositRate() {
    if (!this.contract) {
      await this.initContract();
    }
    let depositRate = await this.contract.methods.depositRate().call();
    return depositRate;
  }

  async depositToken() {
    if (!this.contract) {
      await this.initContract();
    }

    let depositTokenAddress = await this.contract.methods.depositToken().call();
    return depositTokenAddress;
  }

  // Only idleTokens
  async idleToken() {
    if (!this.contract) {
      await this.initContract();
    }

    let idleTokenAddress = await this.contract.methods.idleToken().call();
    return idleTokenAddress;
  }

  // Only idleTokens
  async getIdleValue(amount) {
    if (!this.contract) {
      await this.initContract();
    }

    let idleValue = await this.contract.methods.getIdleValue(amount).call();
    return idleValue;
  }

  // Only idleTokens
  async getIdleAvgCost(amount) {
    if (!this.contract) {
      await this.initContract();
    }

    let idleAvgCost = await this.contract.methods.idleAvgCost().call();
    return idleAvgCost;
  }


  async getApprovedTokens(index) {
    if (!this.contract) {
      await this.initContract();
    }

    let approvedTokens = await this.contract.methods.approvedTokens(index).call();
    return approvedTokens;
  }

  async getUserTokenBalance(user, token, atBlock = 'latest') {
    if (!this.contract) {
      await this.initContract();
    }

    let userTokenBalances = await this.contract.methods.userTokenBalances(user, token).call({}, atBlock);

    return userTokenBalances;
  }

  //getter function for member info.
  async members(account) {
    if (!this.contract) {
      await this.initContract();
    }
    let members = await this.contract.methods.members(account).call();
    return members;
  }

  async getMemberAccts() {
    if (!this.contract) {
      await this.initContract();
    }
    let members = await this.contract.methods.memberAccts().call();
    return members;
  }

  async canRagequit(highestIndexYesVote) {
    if (!this.contract) {
      await this.initContract();
    }
    let canRage = await this.contract.methods.canRagequit(highestIndexYesVote).call();
    return canRage;
  }

  async getProposalQueue() {
    if (!this.contract) {
      await this.initContract();
    }
    let queueLength = await this.contract.methods.getProposalQueue().call();
    return queueLength;
  }

  async getProposalCount() {
    if (!this.contract) {
      await this.initContract();
    }
    let proposalCount = await this.contract.methods.getProposalCount().call();
    return proposalCount;
  }

  async proposals(id) {
    if (!this.contract) {
      await this.initContract();
    }
    let proposalInfo = await this.contract.methods.proposals(id).call();
    return proposalInfo;
  }

  async getProposalFlags(id) {
    if (!this.contract) {
      await this.initContract();
    }
    let proposalFlag = await this.contract.methods.getProposalFlags(id).call();
    return proposalFlag;
  }

  async getGoal() {
    if (!this.contract) {
      await this.initContract();
    }
    let partyGoal = await this.contract.methods.partyGoal().call();
    return partyGoal;
  }

  async getGoalHit() {
    if (!this.contract) {
      await this.initContract();
    }
    let goalHit = await this.contract.methods.goalHit().call();
    if(goalHit === 1) {
      return true
    } else {
      return false
    }
  }

  async getUserEarnings(amt) {
    if (!this.contract) {
      await this.initContract();
    }
    let userEarnings = await this.contract.methods.getUserEarnings(amt).call();
    return userEarnings;
  }

/**********************************SETTERS**********************************/

//@Dev trying to bundle approval and makeDeposit in same call
  async approveDeposit(spender, amount, encodedPayload = false) {

    if (!this.coinContract) {
      await this.initCoinContract();
    }

      let approveDeposit = await this.coinContract.methods.approve(spender, amount)
      .send({ from: this.account })
      .once('transactionHash', (txHash) => {
        console.log('transactionhash', txHash)
      })
      .then((resp) => {
        console.log(resp)
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return { error: 'rejected transaction' };
      });
      return approveDeposit;
  }


  async makeDeposit(amount, encodedPayload = false) {

    if (!this.contract) {
      await this.initContract();
    }

    if (this.daoAbi == WETHPartyAbi) {
      let makeDeposit = await this.contract.methods.makeDeposit(amount)
      .send({ from: this.account, value: amount })
      .once('transactionHash', (txHash) => {
        console.log('transactionhash', txHash)
      })
      .then((resp) => {
        console.log(resp)
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return { error: 'rejected transaction' };
      });
      return makeDeposit;
    } else {
      let makeDeposit = await this.contract.methods.makeDeposit(amount)
      .send({ from: this.account })
      .once('transactionHash', (txHash) => {
        console.log('transactionhash', txHash)
      })
      .then((resp) => {
        console.log(resp)
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return { error: 'rejected transaction' };
      });
      return makeDeposit;
    }
  }

  async rageQuit() {
    if (!this.contract) {
      await this.initContract();
    }

    let rage = this.contract.methods
      .ragequit()
      .send({ from: this.account })
      .once('transactionHash', (txHash) => {
        console.log("txt hash", txHash)
      })
      .then((resp) => {
        console.log(resp)
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return { error: 'rejected transaction' };
      });
    return rage;
  }

  async submitProposal(
    applicant,
    tributeOffered,
    sharesRequested,
    lootRequested,
    paymentRequested,
    flagNumber,
    tributeToken,
    paymentToken,
    details,
    encodedPayload = false,
  ) {
    if (!this.contract) {
      await this.initContract();
    }
    console.log("submitted values", applicant, tributeOffered, sharesRequested)
    if (encodedPayload) {
      const data = this.contract.methods
        .submitProposal(
           applicant,
           tributeOffered,
           sharesRequested,
           lootRequested,
           paymentRequested,
           flagNumber,
           tributeToken,
           paymentToken,
           details)
        .encodeABI();
      return data;
    }

    if (this.daoAbi == WETHPartyAbi) {
      let proposal = this.contract.methods
        .submitProposal(
          applicant,
          tributeOffered,
          sharesRequested,
          lootRequested,
          paymentRequested,
          flagNumber,
          tributeToken,
          paymentToken,
          details)
        .send({from: this.account, value: tributeOffered})
        .once('transactionHash', (txHash) => {
          console.log('transaction hash',txHash)
        })
        .then((resp) => {
          console.log(resp)
          return resp;
        })
        .catch((err) => {
          console.log('from account', this.account);
          console.log(err);
          return { error: 'rejected transaction' };
        });

        return proposal;
    } else {
      let proposal = this.contract.methods
        .submitProposal(
          applicant,
          tributeOffered,
          sharesRequested,
          lootRequested,
          paymentRequested,
          flagNumber,
          tributeToken,
          paymentToken,
          details)
        .send({from: this.account})
        .once('transactionHash', (txHash) => {
          console.log('transaction hash',txHash)
        })
        .then((resp) => {
          console.log(resp)
          return resp;
        })
        .catch((err) => {
          console.log('from account', this.account);
          console.log(err);
          return { error: 'rejected transaction' };
        });
        return proposal;
    }


  }

    async submitGovProposal(tributeOffered, paymentRequested, tributeToken, paymentToken, details, encodedPayload = false) {
      if (!this.contract) {
        await this.initContract();
      }
      let govUpdate = this.contract.methods
        .submitProposal(
          this.account,
          0,
          0,
          tributeOffered, //updates PartyGoal
          tributeToken, // adds new whiteList token
          7,
          paymentRequested, //updates depositRate
          paymentToken, // updates idleToken
          details)
        .send({ from: this.account })
        .once('transactionHash', (txHash) => {})
        .then((resp) => {
          return resp;
        })
        .catch((err) => {
          console.log(err);
          return { error: 'rejected transaction' };
        });

      return govUpdate;
      }

  // must be member to sponsor proposal
  async sponsorProposal(proposalId, encodedPayload = false) {
    if (!this.contract) {
      await this.initContract();
    }
    let sponsorProposal = this.contract.methods
      .sponsorProposal(proposalId)
      .send({ from: this.account })
      .once('transactionHash', (txHash) => {
        console.log('transaction hash', txHash)
      })
      .then((resp) => {
        console.log(resp)
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return { error: 'rejected transaction' };
      });

    return sponsorProposal;
    }

  async processProposal(proposalIndex, encodedPayload = false) {
    if (!this.contract) {
      await this.initContract();
    }
    let processProposal = this.contract.methods
      .processProposal(proposalIndex)
      .send({ from: this.account })
      .once('transactionHash', (txHash) => {})
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return { error: 'rejected transaction' };
      });

    return processProposal;
    }

  async processGuildKickProposal(proposalIndex, encodedPayload = false) {
    if (!this.contract) {
      await this.initContract();
    }
    let processGKProposal = this.contract.methods
      .processGuildKickProposal(proposalIndex)
      .send({ from: this.account })
      .once('transactionHash', (txHash) => {})
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return { error: 'rejected transaction' };
      });

    return processGKProposal;
  }

  async processAmendGovernance(proposalIndex, encodedPayload = false) {
    if (!this.contract) {
      await this.initContract();
    }
    let processAGProposal = this.contract.methods
      .processAmendGovernance(proposalIndex)
      .send({ from: this.account })
      .once('transactionHash', (txHash) => {})
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return { error: 'rejected transaction' };
      });

    return processAGProposal;
  }

  //@Dev proposal can only be canceled by proposal submitter prior to the proposal being sponsored
  async cancelProposal(proposalId, encodedPayload = false) {
    if (!this.contract) {
      await this.initContract();
    }
    let cancelProposal = this.contract.methods
      .cancelProposal(proposalId)
      .send({ from: this.account })
      .once('transactionHash', (txHash) => {})
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return { error: 'rejected transaction' };
      });

    return cancelProposal;
    }

    // proposal index of vote, uintVote can take args 0=null, 1=yes, 2 = no
    // it will vote all your shares yes or no
    // yesVotes and noVotes parameters are number of shares voted by a user, handled on chain

  async submitVote(proposalIndex, uintVote, encodedPayload = false) {
    if (!this.contract) {
      await this.initContract();
    }

    if (encodedPayload) {
      const data = this.contract.methods
        .submitVote(proposalIndex, uintVote)
        .encodeABI();
      return data;
    }

    //uintVote must be 0-2 with 0 = null, 1= yes, 2= no
    let vote = this.contract.methods
      .submitVote(proposalIndex, uintVote)
      .send({ from: this.account })
      .once('transactionHash', (txHash) => {
        console.log('vote txhash', txHash)
      })
      .then((resp) => {
        console.log('vote resp', resp)

        return resp;
      })
      .catch((err) => {
        console.log(err);
        return { error: 'rejected transaction' };
      });
    return vote;
  }

  async withdrawEarnings(memberAddress, amount, encodedPayload = false) {
    if (!this.contract) {
      await this.initContract();
    }

    if (encodedPayload) {
      const data = this.contract.methods
        .withdrawEarnings(memberAddress, amount)
        .encodeABI();
      return data;
    }

    let withdrawEarnings = this.contract.methods
      .withdrawEarnings(memberAddress, amount)
      .send({ from: this.account })
      .once('transactionHash', (txHash) => {
        console.log('withdraw balance', txHash)
      })
      .then((resp) => {
        console.log('withdraw resp', resp)

        return resp;
      })
      .catch((err) => {
        console.log(err);
        return { error: 'rejected transaction' };
      });
    return withdrawEarnings;
  }

  //@Dev used to withdraw an amount of a particular token held in the userTokenBalance
  async withdrawBalance(token, amount, encodedPayload = false) {
    if (!this.contract) {
      await this.initContract();
    }

    if (encodedPayload) {
      const data = this.contract.methods
        .withdrawBalance(token, amount)
        .encodeABI();
      return data;
    }

    let withdrawBalance = this.contract.methods
      .withdrawBalance(token, amount)
      .send({ from: this.account })
      .once('transactionHash', (txHash) => {
        console.log('withdraw balance', txHash)
      })
      .then((resp) => {
        console.log('withdraw resp', resp)

        return resp;
      })
      .catch((err) => {
        console.log(err);
        return { error: 'rejected transaction' };
      });
    return withdrawBalance;
  }

  async withdrawBalances(token, amount, max, encodedPayload = false) {
    if (!this.contract) {
      await this.initContract();
    }

    if (encodedPayload) {
      const data = this.contract.methods
        .withdrawBalances(token, amount, max)
        .encodeABI();
      return data;
    }

    let withdrawBalances = this.contract.methods
      .withdrawBalances(token, amount, max)
      .send({ from: this.account })
      .once('transactionHash', (txHash) => {
        console.log('withdraw Balance txhash', txHash)
      })
      .then((resp) => {
        console.log('withdraw resp', resp)

        return resp;
      })
      .catch((err) => {
        console.log(err);
        return { error: 'rejected transaction' };
      });
    return withdrawBalances;
  }

  async collectTokens(token, encodedPayload = false) {
    if (!this.contract) {
      await this.initContract();
    }

    if (encodedPayload) {
      const data = this.contract.methods
        .collectTokens(token)
        .encodeABI();
      return data;
    }

    let collectTokens = this.contract.methods
      .collectTokens(token)
      .send({ from: this.account })
      .once('transactionHash', (txHash) => {
        console.log('withdraw balance', txHash)
      })
      .then((resp) => {
        console.log('withdraw resp', resp)

        return resp;
      })
      .catch((err) => {
        console.log(err);
        return { error: 'rejected transaction' };
      });
    return collectTokens;
  }
}
