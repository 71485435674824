import { gql } from "apollo-boost";

export const GET_METADATA = gql`
  query Metadata {
    currentPeriod @client
    totalShares @client
    minDonation @client
    guildBankAddr @client
    gracePeriodLength @client
    votingPeriodLength @client
    periodDuration @client
    processingReward @client
    proposalDeposit @client
    guildBankValue @client
    shareValue @client
    approvedToken @client
    tokenSymbol @client
  }
`;

export const EXAMPLE = gql`
  query {
    moloches(first: 5) {
      id
      founders{
        id
      }
      newPeeps
    }
    members(first: 5) {
      id
    }
  }
`;

export const GET_DAOS = gql`
  query {
    moloches {
      id
      version
      members{
        memberAddress
        iTB
        iTW
        iVal
      }
      periodDuration
      votingPeriodLength
      gracePeriodLength
      proposalDepositReward
      depositToken {
        id
        decimals
        whitelisted
        tokenAddress
      }
      idleToken {
        id
        whitelisted
        tokenAddress
      }
      guildTokenBalance {
        token{
          tokenAddress
          symbol
        }
        tokenBalance
      }
      depositRate
      partyGoal
      totalDeposits
      goalHit
    }
  }
`;


export const GET_MOVEMENTDATA = gql`
  query members($contract_address: String!) {
    members(where: {molochAddress: $contract_address}){
      exists
      memberAddress
      molochAddress
      shares
      loot
      iTB
      iTW
      iVal
      tokenTribute
      didRagequit
      proposedToKick
      kicked
      jailed
      tokenBalances {
        token {
          symbol 
          tokenAddress
          decimals
        }
        tokenBalance
      }
  }
  moloches(where: {partyAddress: $contract_address}) {
      partyAddress
      periodDuration
      votingPeriodLength
      gracePeriodLength
      proposalDepositReward
      members {
        memberAddress
        iTB
        iTW
        iVal
        tokenTribute
        shares
        loot
        tokenBalances {
          token {
            symbol
            tokenAddress
            decimals
          }
          tokenBalance
          memberBank
        }
      }
      depositToken {
        tokenAddress
        decimals
      }
      idleToken {
        tokenAddress
        decimals
      }
      tokenBalances {
        token {
          symbol
          tokenAddress
          decimals
        }
        tokenBalance
        guildBank
        memberBank
      }
      depositRate
      partyGoal
      totalDeposits
      totalShares
      totalLoot
      goalHit
      version
    }
  proposals(where: {molochAddress: $contract_address}){
    molochAddress
    proposalIndex
    proposalId
    proposer
    applicant
    sponsor
    tributeOffered
    tributeToken
    sharesRequested
    lootRequested
    paymentRequested
    paymentToken
    yesShares
    noShares
    yesVotes
    noVotes
    processed
    sponsored
    startingPeriod
    didPass
    cancelled
    aborted
    guildkick
    addMember
    spending
    trade
    governance
    details
    votingPeriodStarts
    votingPeriodEnds
    gracePeriodEnds
    maxTotalSharesAndLootAtYesVote
  }
}
`;

export const GET_USER_VOTE = gql`
  query votes ($contract_address: String!, $proposalIndex: String!, $memberAddress: String!) {
    proposals(where: {molochAddress:$contract_address, proposalIndex:$proposalIndex}) {
    id
    proposalId
    proposalIndex
    molochAddress
    createdAt
    votes (where: {memberAddress:$memberAddress}) {
      uintVote
      memberAddress
    }
  }
}
`;

export const GET_MEMBER_MOLOCHES_PROFILE = gql`
  query members($memberAddress: String!) {
    members(where: { memberAddress: $memberAddress, exists: true }) {
      id
      memberAddress
      iTB
      iTW
      iVal
      moloch {
        id
        members{
          id
        }
      }
    }
  }
`;
