import { useState } from 'react'

const useMergeData = (matchFunc) => {

  const [data, setData] = useState()


    const mergeData = (arr1, arr2)=>{
    const combinedData = arr1.map(item => {

      if(!item) {
        return null
      }

      const match = arr2.find(i => {
        // const val = matchCriteria(item, i)

        const val = matchFunc(item, i);
        return val
        // return matchCriteria(item, i);
      });
      if (match) return { ...item, ...match };
      else return null

    });
    const filteredList =  combinedData.filter((item)=>item !== null)

    setData(filteredList)
    return filteredList
  }


  return [data, setData, mergeData]
}


export default useMergeData
