import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from "@apollo/client";

import Web3Provider from 'web3-react';
import Web3 from 'web3';
import { connectors } from 'web3-react';

import config from './config';
import Store from './contexts/Store';

import 'styles/index.css';
import UserStore from './contexts/UserStore';


const client = new ApolloClient({
  uri: config.GRAPH_NODE_URI,
});

const Index = () => (
  <ApolloProvider client={client} >
  <Web3Provider
    connectors={connectors}
    libraryName='web3.js'
    web3Api={Web3}
    >
    <Store >
    <UserStore>
    <App/>
    </UserStore>
    </Store>
</Web3Provider>
</ApolloProvider>
);
ReactDOM.render(<Index />, document.getElementById('root'));

serviceWorker.unregister();
