import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { isWalletAddress } from 'utils/Helpers';
import Input from 'components/shared/InputFields/Input';
import scss from 'views/movement/components/Movement.module.scss';

const CreateMemberProposal = ({formik, tokenAddress, setValidationSchema, currencyOptions}) => {
  const [isHardMode, setIsHardMode] = useState(false);

  useEffect(() => {
    setValidationSchema(Yup.object().shape({
      proposal_name: Yup.string().required("Name is required"),
      tributeOffered: Yup.number().required("Buy-in amount is required"),
      sharesRequested: Yup.number().required("Voting Shares Requested is required"),
      details: Yup.string().required("Details are required"),
      link: Yup.string(),
      applicant: Yup.string().test(
        'isEthAddress',
        'Invalid applicant wallet address',
        function(value) {
          if (!value) return true;
          return isWalletAddress(value)
        }
      ),
      lootRequested: Yup.string().matches(/^\d*$/, "Shares Requested must be a number"),
      tributeToken: Yup.string().test(
        'isEthAddress',
        'Invalid Buy-in Token address',
        function(value) {
          if (!value) return true;
          return isWalletAddress(value)
        }
      )
    }));
  }, []);

  useEffect(() => {
    clearHardValues();
  }, [isHardMode]);

  const clearHardValues = () => {
    formik.setFieldValue('applicant', '');
    formik.setFieldValue('lootRequested', '');
    formik.setFieldValue('tributeToken', '');
  }

  return (
    <>
      <Input
        type={"text"}
        handleChange={formik.handleChange}
        labelText={"Proposal Name"}
        placeholder="Party Admittance"
        name={"proposal_name"}
        value={formik.values.proposal_name}
        additionalClasses={scss.DarkLabel}
      />
      <Input
        type={"number"}
        handleChange={formik.handleChange}
        labelText={"Your Party Buy-in (e.g. Funds Offerred)"}
        placeholder="Will be held in escrow until approval"
        name={"tributeOffered"}
        value={formik.values.tributeOffered}
        additionalClasses={scss.DarkLabel}
      />
      <Input
        type={"number"}
        handleChange={formik.handleChange}
        labelText={"Voting Shares Requested"}
        placeholder="How many voting shares do you want for your buy-in?"
        name={"sharesRequested"}
        value={formik.values.sharesRequested}
        additionalClasses={scss.DarkLabel}
      />
      <Input
        type={"text"}
        name="details"
        placeholder="Keep to 32 characters"
        labelText="Details"
        value={formik.values.details}
        handleChange={formik.handleChange}
        additionalClasses={scss.DarkLabel}
      />
      <Input
        type={"text"}
        handleChange={formik.handleChange}
        placeholder="eg - what else are you bringin' to this party"
        labelText={"Link to Additional Info"}
        name={"link"}
        value={formik.values.link}
        additionalClasses={scss.DarkLabel}
      />

      <button type="button"
        onClick={() => setIsHardMode(true)}
        className={isHardMode ? scss.HiddenFields : scss.TextButton}>
        hard mode
      </button>

      <div className={isHardMode ? '' : scss.HiddenFields}>
        <Input
          type={"text"}
          name={"applicant"}
          placeholder="Default: your address"
          labelText="Who wants to join?"
          value={formik.values.applicant}
          handleChange={formik.handleChange}
          additionalClasses={scss.DarkLabel}
        />
        <Input
          type={"number"}
          handleChange={formik.handleChange}
          labelText={"Non-Voting Shares"}
          placeholder="Default: 0"
          name={"lootRequested"}
          value={formik.values.lootRequested}
          additionalClasses={scss.DarkLabel}
        />
        <Input
          type={"select"}
          options={currencyOptions}
          name={"tributeToken"}
          labelText="Token for Buy-in"
          value={formik.values.tributeToken}
          handleChange={formik.handleChange}
          additionalClasses={scss.DarkLabel}
        />

        <button type="button"
          onClick={() => setIsHardMode(false)}
          className={scss.TextButton}>
          easy mode
        </button>
      </div>
    </>
  );
}

export default CreateMemberProposal;