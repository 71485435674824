import React from 'react';
import scss from 'components/shared/IconButton.module.scss';


const IconButton = ({onClick, className, children}) => {
  let buttonClasses = [scss.IconButton];
  if (className) buttonClasses.push(className);

  return (
    <button className={buttonClasses.join(' ')} onClick={onClick}>
      {children}
    </button>
  );
}

export default IconButton;
